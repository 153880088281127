import styled, { css } from "styled-components/macro";
// import { useResponsive } from "@canei/app-components";

const Wrapper = styled.div(({ theme }) => {
  const { _005: ArrColor } = theme.values.colors.global.secondary;

  return css`
    width: 100%;
    max-width: 100%;
    position: relative;

    .carousel.carousel-slider .control-arrow {
      box-shadow: 1px 1px 5px rgb(0 0 0 / 60%);
      top: calc(50% - ${theme.rem(2.3 / 2)});
      width: ${theme.rem(2.3)};
      height: ${theme.rem(2.3)};
      border-radius: 50%;
      margin: 0 ${theme.rem(1 / 3)};
      border: 1px solid ${ArrColor};
      color: ${ArrColor};
    }
    .slider-wrapper {
      border-radius: ${theme.rem(1.7)};
    }
    .control-prev,
    .control-next {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `;
});

export const StyledCarousel = { Wrapper };
