/**
 * @fileOverview Configuration
 *
 * @namespace configuration
 *
 */
const config = {
  /**
   * @memberof configuration
   * @property {object}webapp - Webapp defaults.
   */
  webapp: {
    MAX_ATTACHMENT_SIZE: 5000000,
    kpi: "KPIB12",
    revenue_curve: "OF006",
    revenue_growth: 0,
    poster: "/assets/video_preview.png",
  },
  /**
   * @memberof configuration
   * @property {object}shared_links - Shared links.
   */
  shared_links: {
    imprint: "https://canei.ag/impressum/",
    terms_url: "https://canei.ag/allgemeine-geschaeftsbedingungen/",
    privacy_url: "https://canei.ag/datenschutzerklaerung/",
    plan_meeting: "https://canei.ag/#contact",
  },
  /**
   * @memberof configuration
   * @property {object}product - product settings.
   */
  product_key: "quick",
  product_id: "canei_quick",
  pricing_id: "canei_quick_01",
  mock_customer_id: "9a443fa4-f853-4b9a-a99b-e57cb244f86e",
  liquidity_plan_type: {
    weekly: "weekly",
    monthly: "monthly",
  },
  app: {
    liquidity_weekly_detail: "liquidity-weekly-detail",
    liquidity_monthly_detail: "liquidity-monthly-detail",
    liquidity_weekly_save: "liquidity-weekly-save",
    liquidity_monthly_save: "liquidity-monthly-save",
    profitloss_detail: "profitloss-detail",
    profitloss_save: "profitloss-save",
    simulation: "simulation-",
    muster_SuSa: "upload-muster-susa",
    app_store: "app-store",
    guv: "guv-details-page",
    balance: "balance-details-page",
  },
  simulations: {
    RECEIVABLES_MANAGEMENT: "ada4bd0b-d1f5-466e-8618-bb078ecf5bdd",
    FACTORING: "c6d8efd2-43f2-4668-88c3-444c1b9a9e8d",
    FINANCE: "6f81cc30-b31e-46b2-afd5-00a30d737031",
  },
  assetType: {
    ASSETS: "assets",
    OFFERS: "offers",
  },
  comparisonData: [
    "overview",
    "overview_cards",
    "kpis",
    "abnormalities",
    "success",
    "assets",
    "bankers",
    "grade",
    "costs",
    "profitloss",
    "liquidity",
    "user",
    "reports",
    "circyoular",
    "canei_pro",
  ],
};

export default config;
