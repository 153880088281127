import { gql, useLazyQuery } from "@apollo/client";
import { AlertTypeEnums, IFormState, ReactStripe } from "@canei/app-components";
import { useMemo, useState } from "react";
import { initialPaymentFormData, IPaymentFormProps } from "../../../components/PaymentForm";
import { ETriState } from "../../../quick/Private/Setup";
import { useTranslation } from "react-i18next";

const initialBillingData: IBillingDetails = {
  name: "",
  email: "",
  address: { line1: "", line2: "", city: "", postal_code: "" },
};

const { useElements, useStripe, CardNumberElement, CardCvcElement, IbanElement } = ReactStripe;

const GET_STRIPE_INTENT = gql`
  query getIntent($customer_id: ID!, $type: PaymentMethodTypes, $covId: ID!) {
    result: getIntent(customer_id: $customer_id, type: $type, covId: $covId) {
      client_secret
      type
    }
  }
`;

export interface IGetIntentResult {
  result: IGetIntentData;
}

export interface IGetIntentData {
  client_secret: string;
  type: string;
}
export interface IGetIntentVars {
  customer_id: string;
  type?: EPaymentMethods;
  covId?: string;
}

export enum EPaymentMethods {
  card = "card",
  sepa = "sepa_debit",
}

export interface IUseMakePaymentRetVal {
  makePayment: (variables: IGetIntentVars) => void;
  updateBillingDetails: (form: IFormState) => void;
  canBeSubmitted: boolean;
  paymentFormData: IPaymentFormProps;
  billingDetails: IBillingDetails;
  isReady: boolean;
  isLoading: boolean;
  updateBilling: boolean;
  callUpdateBilling: (state: boolean) => void;
}
export interface IBillingDetails {
  name: string;
  email: string;
  address: IBillingAddress;
}

export interface IBillingAddress {
  line1?: string;
  line2?: string;
  city?: string;
  postal_code?: string;
}
export const useMakePayment = (): IUseMakePaymentRetVal => {
  const { t } = useTranslation(["quick/common"]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<EPaymentMethods | undefined>(undefined);
  const [billingDetails, setBillingDetails] = useState<IBillingDetails>(initialBillingData);
  const [paymentFormData, setPaymentFormData] = useState<IPaymentFormProps>(initialPaymentFormData);
  const [updateBilling, setUpdateBilling] = useState<boolean>(false);

  const elements = useElements();
  const stripe = useStripe();

  const callUpdateBilling = (state: boolean): void => {
    setUpdateBilling(state);
  };

  const isReady = useMemo((): boolean => {
    return elements !== null && stripe !== null;
  }, [elements, stripe]);

  const intentReady = ({ result }: IGetIntentResult): void => {
    if (!stripe || !elements || !paymentMethod || !billingDetails) return;
    const card = elements.getElement(CardNumberElement);
    const cvc = elements.getElement(CardCvcElement);
    const iban = elements.getElement(IbanElement);

    if (result.type === "payment_intent") {
      if (paymentMethod === "card" && card !== null && cvc !== null) {
        stripe
          .confirmCardPayment(
            result.client_secret,
            {
              payment_method: {
                card,
                billing_details: {
                  ...billingDetails,
                },
              },
              setup_future_usage: "off_session",
              return_url: window.location.href,
            },
            { handleActions: false }
          )
          .then(({ error, paymentIntent }) => {
            setIsLoading(false);
            // TODO: if(error) show error dialog.
            // TODO: if paymentIntent succeeded - check refreshClient ready state. To redirect to dashboard
            if (error?.message) {
              setPaymentFormData({
                ...paymentFormData,
                state: { type: AlertTypeEnums.ERROR, message: error.message },
              });
            }
            if (paymentIntent?.status === "succeeded") {
              setPaymentFormData({
                ...paymentFormData,
                state: {
                  type: AlertTypeEnums.SUCCESS,
                  message: t("payment.update_succeeded"),
                },
              });
              setUpdateBilling(true);
            }
            if (paymentIntent?.status === "requires_action") {
              window.location = paymentIntent.next_action?.redirect_to_url
                ?.url as unknown as Location;
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }

      if (paymentMethod === "sepa_debit" && iban !== null) {
        stripe
          .confirmSepaDebitPayment(result.client_secret, {
            payment_method: {
              sepa_debit: iban,
              billing_details: {
                ...billingDetails,
              },
            },
            setup_future_usage: "off_session",
            return_url: window.location.href,
          })
          .then(({ error, paymentIntent }) => {
            setIsLoading(false);
            // TODO: if(error) show error dialog.
            // TODO: if paymentIntent succeeded - check refreshClient ready state. To redirect to dashboard
            if (error?.message) {
              setPaymentFormData({
                ...paymentFormData,
                state: { type: AlertTypeEnums.ERROR, message: error.message },
              });
            }
            if (paymentIntent?.status === "succeeded") {
              setPaymentFormData({
                ...paymentFormData,
                state: {
                  type: AlertTypeEnums.SUCCESS,
                  message: t("payment.update_succeeded"),
                },
              });
              setUpdateBilling(true);
            }
            if (paymentIntent?.status === "requires_action") {
              window.location = paymentIntent.next_action?.redirect_to_url
                ?.url as unknown as Location;
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    } else if (result.type === "setup_intent") {
      if (paymentMethod === "card" && card !== null && cvc !== null) {
        stripe
          .confirmCardSetup(
            result.client_secret,
            {
              payment_method: {
                card,
                billing_details: {
                  ...billingDetails,
                },
              },
              return_url: window.location.href,
            },
            { handleActions: false }
          )
          .then(({ error, setupIntent }) => {
            setIsLoading(false);
            // TODO: if(error) show error dialog.
            // TODO: if setupIntent succeeded - check refreshClient ready state. To redirect to dashboard
            if (error?.message) {
              setPaymentFormData({
                ...paymentFormData,
                state: { type: AlertTypeEnums.ERROR, message: error.message },
              });
            }
            if (setupIntent?.status === "succeeded") {
              setPaymentFormData({
                ...paymentFormData,
                state: {
                  type: AlertTypeEnums.SUCCESS,
                  message: t("payment.update_succeeded"),
                },
              });
              setUpdateBilling(true);
            }
            if (setupIntent?.status === "requires_action") {
              window.location = setupIntent.next_action?.redirect_to_url
                ?.url as unknown as Location;
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }

      if (paymentMethod === "sepa_debit" && iban !== null) {
        stripe
          .confirmSepaDebitSetup(result.client_secret, {
            payment_method: {
              sepa_debit: iban,
              billing_details: {
                ...billingDetails,
              },
            },
            return_url: window.location.href,
          })
          .then(({ error, setupIntent }) => {
            setIsLoading(false);
            // TODO: if(error) show error dialog.
            // TODO: if setupIntent succeeded - check refreshClient ready state. To redirect to dashboard
            if (error?.message) {
              setPaymentFormData({
                ...paymentFormData,
                state: { type: AlertTypeEnums.ERROR, message: error.message },
              });
            }
            if (setupIntent?.status === "succeeded") {
              setPaymentFormData({
                ...paymentFormData,
                state: {
                  type: AlertTypeEnums.SUCCESS,
                  message: t("payment.update_succeeded"),
                },
              });
              setUpdateBilling(true);
            }
            if (setupIntent?.status === "requires_action") {
              window.location = setupIntent.next_action?.redirect_to_url
                ?.url as unknown as Location;
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    }
  };

  const [getIntent] = useLazyQuery<IGetIntentResult, IGetIntentVars>(GET_STRIPE_INTENT, {
    onCompleted: intentReady,
    fetchPolicy: "network-only",
  });
  const canBeSubmitted = useMemo((): boolean => {
    return (
      billingDetails.address.line1 !== "" &&
      billingDetails.address.line2 !== "" &&
      billingDetails.address.postal_code !== "" &&
      billingDetails.address.city !== "" &&
      billingDetails.name !== "" &&
      billingDetails.email !== ""
    );
  }, [
    billingDetails.address.city,
    billingDetails.address.line1,
    billingDetails.address.line2,
    billingDetails.address.postal_code,
    billingDetails.email,
    billingDetails.name,
  ]);

  const makePayment = (variables: IGetIntentVars): void => {
    if (!isReady) return;
    if (!canBeSubmitted) {
      setPaymentFormData({
        ...paymentFormData,
        valid: {
          ...paymentFormData.valid,
          line1: billingDetails.address.line1 === "" ? ETriState.FALSE : ETriState.TRUE,
          line2: billingDetails.address.line2 === "" ? ETriState.FALSE : ETriState.TRUE,
          name: billingDetails.name === "" ? ETriState.FALSE : ETriState.TRUE,
          email: billingDetails.email === "" ? ETriState.FALSE : ETriState.TRUE,
          postal_code: billingDetails.address.postal_code === "" ? ETriState.FALSE : ETriState.TRUE,
          city: billingDetails.address.city === "" ? ETriState.FALSE : ETriState.TRUE,
        },
      });
      return;
    }
    setIsLoading(true);
    setPaymentMethod(variables.type);
    getIntent({ variables });
  };

  const updateBillingDetails = (form: IFormState): void => {
    const isBillingDetailsChanged =
      form.line1 !== billingDetails.address?.line1 ||
      form.line2 !== billingDetails.address?.line2 ||
      form.postal_code !== billingDetails.address?.postal_code ||
      form.city !== billingDetails.address?.city ||
      form.email !== billingDetails.email ||
      form.name !== billingDetails.name;
    if (!isBillingDetailsChanged) return;

    setBillingDetails({
      ...billingDetails,
      name: form.name,
      email: form.email,
      address: {
        ...billingDetails.address,
        line1: form.line1,
        line2: form.line2,
        postal_code: form.postal_code,
        city: form.city,
      },
    });
  };

  return {
    makePayment,
    updateBillingDetails,
    billingDetails,
    paymentFormData,
    canBeSubmitted,
    isReady,
    isLoading,
    updateBilling,
    callUpdateBilling,
  };
};
