import { PreviewData } from "../index";
interface IPreviewExcelData {
  rowTitles: string[];
  table: PreviewData[];
}
/**
 * fill the preview tableData array to have a fixed matrix tableData,
 * Where each row has same number of columns
 * @param data
 */
export const convertToTableData = (data: PreviewData[]): IPreviewExcelData => {
  const rowTitlesData = data.reduceRight((prev, cur): PreviewData => ({ ...prev, ...cur }));
  for (const key in rowTitlesData) {
    if (rowTitlesData.hasOwnProperty(key)) {
      rowTitlesData[key] = "";
    }
  }
  const rowTitles = Object.keys(rowTitlesData).sort();
  const orderedData: PreviewData = {};
  rowTitles.forEach((key: string) => {
    orderedData[key] = rowTitlesData[key];
  });
  const table = data.map((item) => ({ ...orderedData, ...item }));

  return { rowTitles, table };
};
