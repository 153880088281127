/**
 * @file Context for Theme
 */

import { FC, useEffect } from "react";
import ThemeCompiler from "./themeCompiler";
import defaultThemeValues from "./defaultThemeValues";
import webFontLoader from "webfontloader";
import GlobalStyle from "../GlobalStyle";
import { ThemeProvider } from "styled-components/macro";
import { useDevice } from "@canei/app-components";
import { useSelector } from "react-redux";
import { ILocalState } from "../../@types/index.d";

export const Theme: FC = ({ children }) => {
  const device = useDevice();
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions);

  const theme = new ThemeCompiler(defaultThemeValues, device, {
    ...themeOptions,
  });
  useEffect(() => {
    webFontLoader.load(theme.values.webFontsConfig);
  }, [theme.values.webFontsConfig]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};
