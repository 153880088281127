import { FC, useState } from "react";

import { Select, SelectItem, ISelectValue, SelectionTypes } from "@canei/app-components";

import { useTranslation } from "react-i18next";
export interface PlanPeriodProps {
  disabled: boolean;
}
export const PlanPeriod: FC<PlanPeriodProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const planPeriodOptions: string[] = t("setup.upload_form_2.datev.plan_period_options", {
    returnObjects: true,
  });
  const [planPeriod, setPlanPeriod] = useState(0);
  const toMonthsCount = (i: number): string => ((i + 3) * 12).toString();
  const handlePlanPeriodSelections = (e: ISelectValue<string>): void => {
    if (isNaN(parseInt(e.value))) {
      return;
    }
    setPlanPeriod(parseInt(e.value));
  };
  return (
    <Select
      text={planPeriodOptions[planPeriod]}
      type={SelectionTypes.LIST}
      name={"plan_period"}
      value={toMonthsCount(0)}
      label={t("setup.upload_form_2.datev.label.plan_period")}
      placeholder={t("setup.upload_form_2.datev.placeholder.plan_period")}
      disabled={disabled}
      onChange={handlePlanPeriodSelections}
    >
      {planPeriodOptions.map((opt: string, key: number) => {
        return <SelectItem value={toMonthsCount(key)} key={key} text={opt} selected={false} />;
      })}
    </Select>
  );
};
