import { getLocaleFromLanguage } from "./getLocaleFromLanguage";

export enum EFormatCurrencyDivision {
  NONE = 1,
  THOUSAND = 1000,
  MILLION = 1000000,
}
export interface IFormatNumberOptions {
  maximumFractionDigits?: number;
  style?: EFormatNumberStyle;
  divideTo?: EFormatCurrencyDivision;
  absolute?: boolean;
}
export type TFormatNumber = (value: string | number, options?: IFormatNumberOptions) => string;

export enum EFormatNumberStyle {
  decimal = "decimal",
  percent = "percent",
  unit = "unit",
  currency = "currency",
}
export const formatNumber: TFormatNumber = (
  value,
  {
    maximumFractionDigits = 2,
    style = EFormatNumberStyle.decimal,
    divideTo = EFormatCurrencyDivision.NONE,
    absolute = false,
  } = {}
): string => {
  if (value === null) return "0";
  const strValue = value.toString();
  const result = parseFloat(strValue) / divideTo;
  const locale = getLocaleFromLanguage();
  const isAbsoluteValue = absolute ? Math.abs(result || 0) : result;
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits,
    minimumFractionDigits: maximumFractionDigits,
    style,
  }).format(isAbsoluteValue);
};
