import { IFinancialGradeData } from "@canei/app-components";
import {
  IGradeEntry,
  IGradesTree,
} from "../../views/Private/Dashboard/FinancialGrade/FinancialGradeReport";
import { EEvaluationTrend } from "../../@types/index.d";

export const optimisticChartData: IFinancialGradeData[] = new Array(1).fill({
  month: "",
  grade: 0,
  label: "",
});
export const optimisticGradeEntry: IGradeEntry = {
  value: "___",
  note: 0,
  trend: EEvaluationTrend.unchanged,
};
export const optimisticGradesTree: IGradesTree = {
  value: "GRD000",
  all_relevant: false,
  tree: [
    {
      value: "GRD001",
      weight: 50,
      tree: [
        {
          value: "GRD002",
          weight: 50,
          tree: new Array(6).fill(optimisticGradeEntry),
        },
        {
          value: "GRD003",
          weight: 50,
          tree: new Array(4).fill(optimisticGradeEntry),
        },
      ],
    },
    {
      value: "GRD004",
      weight: 50,
      tree: [
        {
          value: "GRD006",
          weight: 50,
          tree: new Array(3).fill(optimisticGradeEntry),
        },
        {
          value: "GRD007",
          weight: 50,
          tree: new Array(6).fill(optimisticGradeEntry),
        },
      ],
    },
  ],
};
