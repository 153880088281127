import { FC, ReactElement } from "react";
import { StyledTable, THead, TBody, TFoot, TH, TR, TD } from "./styles/styled.table";

export interface TableProps {
  width?: string;
  fontSize?: number;
  isLoading?: boolean;
}

export interface CellProps {
  colSpan?: number;
  alignText?: "left" | "center" | "right";
  alignH?: "left" | "center" | "right";
  alignV?: "top" | "center" | "end";
  highlight?: boolean;
  level?: "title" | "upper" | "subTitle" | "subSubTitle" | "normal";
  animate?: boolean;
  width?: string;
  hasBorder?: boolean;
  height?: number;
}

const Table: FC<TableProps> & {
  Head: FC;
  Body: FC;
  Foot: FC;
  TH: FC<CellProps>;
  TR: FC;
  TD: FC<CellProps>;
} = ({ children, ...rest }) => {
  return <StyledTable {...rest}>{children}</StyledTable>;
};

Table.Head = ({ children, ...rest }): ReactElement => {
  return <THead {...rest}>{children}</THead>;
};

Table.Body = ({ children, ...rest }): ReactElement => {
  return <TBody {...rest}>{children}</TBody>;
};

Table.Foot = ({ children, ...rest }): ReactElement => {
  return <TFoot {...rest}>{children}</TFoot>;
};

Table.TH = ({ children, ...rest }): ReactElement => {
  return <TH {...rest}>{children}</TH>;
};

Table.TR = ({ children, ...rest }): ReactElement => {
  return <TR {...rest}>{children}</TR>;
};

Table.TD = ({ children, ...rest }): ReactElement => {
  return <TD {...rest}>{children}</TD>;
};

export default Table;
