import styled, { css } from "styled-components/macro";
import { IRevenueChartProps, ERevenueZoom } from "@canei/app-components";

const ZoomList = styled.ul(({ theme }) => {
  return css`
    list-style: none;
    padding: 0;
    margin: 0;
    align-self: center;
    display: inline-flex;
    justify-items: center;
    justify-content: space-evenly;
  `;
});
const ZoomListItem = styled.li<Partial<Pick<IRevenueChartProps, "zoom">> & { selected?: boolean }>(
  ({ theme, zoom = ERevenueZoom.MIN, selected = false }) => {
    const fontSize = {
      [ERevenueZoom.MIN]: theme.rem(1),
      [ERevenueZoom.MED]: theme.rem(1.25),
      [ERevenueZoom.MAX]: theme.rem(1.5),
    };
    const selectedCss = css`
      border-bottom-color: inherit;
      font-weight: ${theme.values.fontWeight.bolder};
      cursor: default;
    `;
    return css`
      list-style: none;
      border-bottom: 2px solid transparent;
      font-size: ${fontSize[zoom]};
      align-self: flex-end;
      line-height: ${fontSize[ERevenueZoom.MAX]};
      padding: ${theme.rem(0.25)};
      cursor: pointer;
      transition: all 300ms;
      ${selected && selectedCss}
    `;
  }
);

const ManualEditListContainer = styled.div(({ theme }) => {
  return css`
    max-width: ${theme.rem(48)};
  `;
});
const ManualEditList = styled.div(({ theme }) => {
  return css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${theme.rem(1)};
    width: 100%;
  `;
});
const ManualEditItem = styled.div(({ theme }) => {
  return css`
    display: flex;
    flex-wrap: wrap;
    width: clamp(${theme.rem(8)}, calc(100% / 6 - ${theme.rem(1)}), 10vw);
    padding: ${theme.rem(0.25)};
  `;
});
const ManualEditItemMonth = styled.div(({ theme }) => {
  return css`
    flex-basis: 100%;
    text-align: center;
    margin-bottom: ${theme.rem(0.25)};
    font-weight: ${theme.values.fontWeight.bold};
  `;
});
const ManualEditItemRatio = styled.div(({ theme }) => {
  return css`
    flex-basis: 100%;
  `;
});
export const StyledRevenueRatios = {
  ZoomList,
  ZoomListItem,
  ManualEditListContainer,
  ManualEditItem,
  ManualEditList,
  ManualEditItemMonth,
  ManualEditItemRatio,
};
