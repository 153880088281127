import { gql } from "@apollo/client";

export const GET_ACTUAL_ACTUALS = gql`
  query getActualActuals($params: ActualsInput!) {
    getActualActuals(params: $params) {
      actuals {
        data {
          balance
          balance_cumulated
          global_code_id
          ratio
          starting_balance
          sum
        }
        date
        calculated
      }
      format
    }
  }
`;
export const GET_ACTUALS_SUSA_BY_ID = gql`
  query getSusa($susa_id: ID!) {
    getActualSuSaById(susa_id: $susa_id) {
      client_id
      date
      format
      susa_id
      calculated_at
      data {
        global_code_id
        starting_balance
        balance_cumulated
        balance
        sum
        ratio
      }
      links {
        href
        rel
      }
    }
  }
`;
