import { gql } from "@apollo/client/core";
import { MutationFunctionOptions, useApolloClient, useMutation } from "@apollo/client";
import { IUploadSettings } from "@canei/app-components";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState, UserStateActionTypes } from "../../@types/index.d";
import { ICurrentUserAction } from "../store/reducers";
import {
  ISetConversionMetadataRetVal,
  ISetConversionMetadataVars,
} from "../../containers/Uploaders";

export const SET_CONVERSION_METADATA = gql`
  mutation setConversionMetaData(
    $account_id: ID!
    $format: FileUploadFormat
    $variant_type: FileUploadVariant
    $mapping: FileFieldsMapping
  ) {
    setConversionMetaData(
      account_id: $account_id
      format: $format
      variant_type: $variant_type
      mapping: $mapping
    ) {
      format
      variant_type
      mapping {
        description
        account_code
        balance
        balance_s
        balance_h
        balance_sh
        starting_balance
        starting_balance_s
        starting_balance_h
        starting_balance_sh
      }
    }
  }
`;

export const GET_CONVERSION_METADATA = gql`
  query getConversionMetaData($account_id: ID!) {
    getConversionMetaData(account_id: $account_id) {
      format
      variant_type
      mapping {
        description
        account_code
        balance
        balance_s
        balance_h
        balance_sh
        starting_balance
        starting_balance_s
        starting_balance_h
        starting_balance_sh
      }
    }
  }
`;

type TMutationFns<R, V> = (options?: MutationFunctionOptions<R, V>) => void;

interface IUseGetSetConversionMeaDataRetVal {
  setConversionMetadata: TMutationFns<ISetConversionMetadataRetVal, ISetConversionMetadataVars>;
}

type TUseGetSetConversionMetaData = () => IUseGetSetConversionMeaDataRetVal;

export const useGetConversionMetaData: TUseGetSetConversionMetaData = () => {
  const { currentUser } = useSelector<ILocalState, ILocalState>((state) => state);
  const [result, setResult] = useState<IUploadSettings | undefined>(undefined);
  const dispatchCurrentUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const apolloClient = useApolloClient();

  const [setConversionMetadata] = useMutation<
    ISetConversionMetadataRetVal,
    ISetConversionMetadataVars
  >(SET_CONVERSION_METADATA, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (currentUser?.selectedClient?.metaData?.conversion) return;

    if (result === undefined) {
      apolloClient
        .query({
          query: GET_CONVERSION_METADATA,
          variables: { account_id: currentUser.selectedClient.client_id },
          fetchPolicy: "no-cache",
        })
        .then(({ data }) => {
          setResult(data.getConversionMetaData as IUploadSettings);
          // if (
          //   JSON.stringify(data.getConversionMetaData) !==
          //   JSON.stringify(currentUser?.selectedClient?.metaData?.conversion)
          // ) {
          //   dispatchCurrentUser({
          //     type: UserStateActionTypes.SET_CONVERSION_METADATA,
          //     payload: {
          //       selectedClient: { metaData: { conversion: data.getConversionMetaData } },
          //     },
          //   });
          // }
          dispatchCurrentUser({
            type: UserStateActionTypes.SET_CONVERSION_METADATA,
            payload: {
              selectedClient: { metaData: { conversion: data.getConversionMetaData } },
            },
          });
        });
    }
  }, [
    apolloClient,
    result,
    currentUser.selectedClient.client_id,
    dispatchCurrentUser,
    currentUser?.selectedClient?.metaData?.conversion,
  ]);

  return { setConversionMetadata };
};
