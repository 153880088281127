import { FC } from "react";

import config from "../../config";
export const Footer: FC = () => {
  return (
    <>
      <a href={config.shared_links.imprint} target="_blank" rel="noreferrer">
        Impressum
      </a>
      {"\u00A0\u25CF\u00A0"}
      <a href={config.shared_links.privacy_url} target="_blank" rel="noreferrer">
        Datenschutz
      </a>
      {"\u00A0\u25CF\u00A0"}
      <a href={config.shared_links.terms_url} target="_blank" rel="noreferrer">
        AGB
      </a>
    </>
  );
};
