import { Dispatch, FC, useEffect, useMemo, useState } from "react";

import {
  Alert,
  AlertTypeEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  CardCvc,
  CardExpiry,
  CardNumber,
  Country,
  EventErrorsCodes,
  Form,
  IDialogKeyEnums,
  Input,
  InputTypes,
  ISelectValue,
  LoaderTypeEnums,
  LoadingIndicator,
  Select,
  SelectionTypes,
  SelectItem,
  SepaDebitIBAN,
  useDialogContext,
  useDialogs,
} from "@canei/app-components";
import { Trans, useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { EAppStoreActionType, IAppStoreAction } from "../../../_lib/store/reducers";
import { EFlexDirection, StyledAppStore } from "../styled.appStore-page";
import { v4 as uuidv4 } from "uuid";
import { EAppStoreMenuItem } from "../useAppStore";
import { IBillingDetailedAddress } from "../../../_lib/hooks/appStore/appStoreTypes";
import validator from "validator";
import { useAppActivity } from "../../../_lib/hooks/appActivity";
import { useAppStoreHelper } from "../useAppStoreHelper";
import {
  EAppStoreConfirmType,
  IAppStoreContext,
  IUpdateFeaturesVariables,
} from "../appStoreTypes.d";
import config from "../../../config";
import { useMutation } from "@apollo/client";
import { UPDATE_FEATURES } from "../useConfirmDialog";
import { useHistory } from "react-router-dom";
import {
  EDataSyncLevel,
  EDataSyncScope,
  EDataSyncTypes,
  EQPrivatePaths,
  ILocalState,
} from "../../../@types/index.d";
import {
  EPaymentMethods,
  useAppStoreInfo,
  useMakePayment,
  useTransactionEventListener,
} from "../../../_lib/hooks";
import { ETriState } from "../../../quick/Private/NewSetupFlow/newSetupFlowContext";

interface IPaymentPageProps {
  storeState?: AlertTypeEnums;
}

const AppStorePaymentPage: FC<IPaymentPageProps> = ({ storeState }) => {
  const { t } = useTranslation(["quick/common"]);
  const { appStore, currentUser } = useSelector<ILocalState, ILocalState>((state) => state);
  const [method, setMethod] = useState("card");
  const dispatchAppStore = useDispatch<Dispatch<IAppStoreAction>>();
  const dialogs = useDialogs();
  const history = useHistory();
  const { isEmail } = validator;
  const { userPurchaseOrCancel } = useAppActivity();
  const [transactionEvent, errorState, transaction] = useTransactionEventListener();
  const { handleLocation } = useAppStoreHelper();
  const dialogContext = useDialogContext<IAppStoreContext | undefined>(
    IDialogKeyEnums.APP_STORE,
    undefined
  );
  const {
    getCustomerStatus,
    loading: customerDataLoading,
    updateBillingAddress,
  } = useAppStoreInfo();

  const {
    makePayment,
    updateBillingDetails,
    paymentFormData,
    isReady,
    isLoading,
    canBeSubmitted,
    billingDetails,
    updateBilling,
    callUpdateBilling,
  } = useMakePayment();
  const [covId, setCovId] = useState<string | undefined>(undefined);
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);
  const [callMakePayment, setCallMakePayment] = useState<boolean>(false);
  const [transactionLoading, setTransactionLoading] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>("");
  const { form, valid, state } = paymentFormData;
  const sessionLoc = sessionStorage.getItem("location");
  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    placeholderCountry: "DE",
  };

  // send a mutation to add or remove a feature
  const [updateFeatures] = useMutation(UPDATE_FEATURES);
  const handleSelectPaymentType = (e: ISelectValue<string>): void => {
    if (e.value === "") return;
    setMethod(e.value);
  };

  // to pre-fill input field with the data in redux
  const expiryDate = useMemo(() => {
    if (!appStore.customerData || !appStore.customerData.payment_method) return;
    const { exp_month, exp_year } = appStore.customerData.payment_method;
    if (exp_month && exp_year) {
      return `${exp_month} / ${exp_year.toString().slice(-2)}`;
    }
  }, [appStore.customerData]);

  // to pre-fill input field with the data in redux
  const billingAddress = useMemo<IBillingDetailedAddress | undefined>(() => {
    if (!appStore.billingAddress) return;
    return appStore.billingAddress;
  }, [appStore.billingAddress]);

  const [email, setEmail] = useState<string>(
    billingAddress?.email || currentUser?.appUser?.email || ""
  );

  // to pre-fill input field with the data in redux
  const lastFour = useMemo(() => {
    if (!appStore.customerData || !appStore.customerData.payment_method) return;
    return appStore.customerData.payment_method.last_four;
  }, [appStore.customerData]);

  // to delete the account only in payment wall
  const deleteAccount = (): void => {
    const variables = {
      type: EAppStoreConfirmType.CANCEL,
      customer_id: currentUser.appUser.customer_id,
      product_id: config.product_id,
      pricing_id: config.pricing_id,
    } as IUpdateFeaturesVariables;

    // patch admin
    updateFeatures({
      variables: variables,
    });

    // send AppStore event
    userPurchaseOrCancel(false);
    dialogs.close(IDialogKeyEnums.APP_STORE);
    history.push(EQPrivatePaths.LOGOUT);
  };

  // only to initially fill companyName info from redux
  useEffect(() => {
    if (!appStore || !appStore.billingAddress) return;
    if (appStore.billingAddress?.name && companyName === "") {
      setCompanyName(appStore.billingAddress?.name);
    }
  }, [appStore, companyName]);

  // to save the fields to LS to be able to use as the params of "updateBillingAddress" fn
  const params = useMemo(() => {
    if (!covId || !billingDetails) return;
    return {
      city: billingDetails.address.city,
      email: billingDetails.email,
      line_1: billingDetails.address.line1,
      line_2: billingDetails.address.line2,
      name: companyName,
      postal: billingDetails.address.postal_code,
      telephone: null,
    };
  }, [covId, billingDetails, companyName]);

  useEffect(() => {
    if (!covId || callMakePayment) return;
    // to save the data and use for updateBillingAddress fn (only for the cards that require 3DS2 Authentication)
    localStorage.setItem("updateBillingParams", JSON.stringify(params));

    setCallMakePayment(true);
    makePayment({
      type: appStore.paymentType as EPaymentMethods,
      customer_id: currentUser.appUser.customer_id,
      covId: covId,
    });
  }, [
    currentUser.appUser.customer_id,
    makePayment,
    covId,
    appStore.paymentType,
    callMakePayment,
    params,
  ]);

  useEffect(() => {
    dispatchAppStore({
      type: EAppStoreActionType.SET_PAYMENT_TYPE,
      payload: { paymentType: method },
    });
  }, [method, dispatchAppStore]);

  // to send transaction
  useEffect(() => {
    if (dataUpdated) return;
    if (!covId) return;
    if ((state || dialogContext?.state) && !customerDataLoading && !dataUpdated && covId) {
      setTransactionLoading(true);
      transactionEvent({
        transactionId: covId,
        accountId: currentUser.appUser.customer_id,
        level: EDataSyncLevel.CLIENT,
        scope: EDataSyncScope.WALLET,
        type: EDataSyncTypes.UPSERT,
      });
      setDataUpdated(true);
    }
  }, [
    covId,
    currentUser.appUser.customer_id,
    customerDataLoading,
    dataUpdated,
    state,
    dialogContext?.state,
    transactionEvent,
  ]);

  // to send patch request to billing, updating billing address
  useEffect(() => {
    if (!billingDetails) return;
    if (!billingDetails.address) return;
    if (!currentUser.appUser.customer_id) return;
    if (
      (state?.type === AlertTypeEnums.SUCCESS || dialogContext?.state === AlertTypeEnums.SUCCESS) &&
      updateBilling
    ) {
      if (
        billingDetails.address.line1 &&
        billingDetails.address.city &&
        billingDetails.address.postal_code &&
        billingDetails.name &&
        billingDetails.email &&
        companyName
      ) {
        updateBillingAddress({
          variables: {
            params: {
              line_1: billingDetails.address.line1,
              line_2: billingDetails.address.line2,
              city: billingDetails.address.city,
              postal: billingDetails.address.postal_code,
              country: Country.DEU,
              email: billingDetails.email,
              name: companyName,
            },
            customer_id: currentUser.appUser.customer_id,
          },
        });
        callUpdateBilling(false);
        localStorage.removeItem("updateBillingParams");
      }
    }
  }, [
    state,
    dialogContext?.state,
    updateBillingAddress,
    currentUser.appUser.customer_id,
    companyName,
    billingDetails,
    callUpdateBilling,
    updateBilling,
  ]);

  // to send patch request to billing, to update billing address (only for the cards that require 3DS2 Authentication)
  useEffect(() => {
    if (!dialogContext?.state) return;
    if (!appStore?.billingAddress) return;
    if (!currentUser.appUser.customer_id) return;
    if (dialogContext?.state === AlertTypeEnums.SUCCESS && !state) {
      const savedBillingAddressToRedux = appStore.billingAddress;
      const savedBillingAddressToLS = localStorage.getItem("updateBillingParams");

      if (
        savedBillingAddressToLS &&
        savedBillingAddressToRedux &&
        JSON.stringify(savedBillingAddressToRedux) !== JSON.stringify(savedBillingAddressToLS)
      ) {
        const params = {
          ...JSON.parse(savedBillingAddressToLS),
          country: Country.DEU,
        };

        // telephone key in the object is currently null, that is why I remove it here not to have an error with backend requirements
        delete params.telephone;
        params &&
          updateBillingAddress({
            variables: {
              params,
              customer_id: currentUser.appUser.customer_id,
            },
          });

        localStorage.removeItem("updateBillingParams");
      }
    }
  }, [
    dialogContext?.state,
    currentUser.appUser.customer_id,
    updateBillingAddress,
    appStore?.billingAddress,
    state,
    userPurchaseOrCancel,
  ]);

  useEffect(() => {
    if (!transactionLoading) return;
    if (transaction?.transactionId && errorState) {
      const process = errorState[transaction.transactionId];
      if (process === EventErrorsCodes.TRANSACTION_OK || process === EventErrorsCodes.TIME_OUT) {
        setTransactionLoading(false);
        getCustomerStatus(currentUser.appUser.customer_id);
      }
    }
  }, [
    currentUser.appUser.customer_id,
    errorState,
    getCustomerStatus,
    transaction?.transactionId,
    transactionLoading,
    dialogs,
  ]);
  return (
    <StyledAppStore.SectionWrapper>
      <StyledAppStore.SectionWrapper>
        <StyledAppStore.SectionTextWrapper>
          <Trans i18nKey="quick/common:payment.end_of_trial.message">
            <a rel="noreferrer" href="https://stripe.com/" target="_blank">
              stripe.com
            </a>

            <a
              rel="noreferrer"
              href="https://stripe.com/de/guides/general-data-protection-regulation"
              target="_blank"
            >
              privacy
            </a>
            <a rel="noreferrer" href="https://stripe.com/de/legal" target="_blank">
              terms
            </a>
          </Trans>
        </StyledAppStore.SectionTextWrapper>
      </StyledAppStore.SectionWrapper>
      <Form subscribe={updateBillingDetails}>
        <StyledAppStore.PaymentWrapper>
          <StyledAppStore.Row columns={2}>
            <StyledAppStore.Entry>
              <StyledAppStore.SubTitle>
                {t("quick/common:payment.invoice.caption")}
              </StyledAppStore.SubTitle>
            </StyledAppStore.Entry>
            <StyledAppStore.Entry>
              <StyledAppStore.SubTitle>
                {t("quick/common:payment.headline")}
              </StyledAppStore.SubTitle>
            </StyledAppStore.Entry>
          </StyledAppStore.Row>

          <StyledAppStore.Row columns={2}>
            <StyledAppStore.Entry>
              <Input
                name={"company_name"}
                label={t("quick/common:payment.invoice.labels.company")}
                valid
                value={billingAddress?.name || companyName || ""}
                invalidWarning={t("misc.required_field")}
                branded={true}
                onChange={(e): void => setCompanyName(e.target.value)}
              />
            </StyledAppStore.Entry>
            <StyledAppStore.Entry>
              <Select
                name="format"
                text={method === "sepa_debit" ? "SEPA-Lastschrift" : "Kreditkarte"}
                label={t("quick/common:payment.card.labels.method")}
                type={SelectionTypes.LIST}
                value={method}
                valid
                invalidWarning={t("misc.required_field")}
                branded={true}
                onChange={handleSelectPaymentType}
              >
                <SelectItem
                  value="card"
                  text={t("quick/common:payment.payment_options.card")}
                  selected={method === "sepa_debit" ? false : true}
                />
                <SelectItem
                  value="sepa_debit"
                  text={t("quick/common:payment.payment_options.sepa_debit")}
                  selected={method === "sepa_debit" ? true : false}
                />
              </Select>
            </StyledAppStore.Entry>
          </StyledAppStore.Row>

          <StyledAppStore.Row columns={2}>
            <StyledAppStore.Entry>
              <Input
                name={"line1"}
                label={t("quick/common:payment.invoice.labels.street")}
                valid={valid.line1 !== ETriState.FALSE}
                value={billingAddress?.line_1 || form.line1 || ""}
                invalidWarning={t("misc.required_field")}
                branded={true}
              />
            </StyledAppStore.Entry>

            <StyledAppStore.Entry>
              <Input
                name={"name"}
                label={
                  method !== "sepa_debit"
                    ? t("quick/common:payment.card.labels.owners_name")
                    : t("quick/common:payment.sepa_debit.owners_name")
                }
                valid={valid.name !== ETriState.FALSE}
                value={form.name || ""}
                invalidWarning={t("misc.required_field")}
                branded={true}
              />
            </StyledAppStore.Entry>
          </StyledAppStore.Row>

          <StyledAppStore.Row columns={2}>
            <StyledAppStore.Row columns={2}>
              <StyledAppStore.Entry>
                <Input
                  name={"postal_code"}
                  label={t("quick/common:payment.invoice.labels.zip")}
                  valid={valid.postal_code !== ETriState.FALSE}
                  value={billingAddress?.postal || form.postal_code || ""}
                  invalidWarning={t("misc.required_field")}
                  branded={true}
                />
              </StyledAppStore.Entry>
              <StyledAppStore.Entry>
                <Input
                  name={"city"}
                  label={t("quick/common:payment.invoice.labels.city")}
                  valid={valid.city !== ETriState.FALSE}
                  value={billingAddress?.city || form.city || ""}
                  invalidWarning={t("misc.required_field")}
                  branded={true}
                />
              </StyledAppStore.Entry>
            </StyledAppStore.Row>
            {method === "card" ? (
              <StyledAppStore.Entry>
                <CardNumber
                  label={t("quick/common:payment.card.labels.card_number")}
                  branded
                  valid={false}
                  invalidWarning={t("misc.required_field")}
                  options={{
                    placeholder: lastFour ? `**** **** **** ${lastFour}` : "1234 1234 1234 1234",
                  }}
                />
              </StyledAppStore.Entry>
            ) : (
              <StyledAppStore.Entry>
                <SepaDebitIBAN
                  label={t("quick/common:payment.sepa_debit.label")}
                  branded
                  valid={false}
                  options={IBAN_ELEMENT_OPTIONS}
                  invalidWarning={t("misc.required_field")}
                />
              </StyledAppStore.Entry>
            )}
          </StyledAppStore.Row>

          <StyledAppStore.Row columns={2}>
            <StyledAppStore.Entry>
              <Input
                name={"email"}
                type={InputTypes.EMAIL}
                label={"E-mail"}
                valid={isEmail(email)}
                value={billingAddress?.email || email}
                invalidWarning={t("misc.required_field")}
                branded={true}
                onChange={(e): void => setEmail(e.target.value)}
              />
            </StyledAppStore.Entry>
            {method === "card" && (
              <StyledAppStore.Row columns={2}>
                <StyledAppStore.Entry>
                  <CardCvc
                    name="cvc"
                    label={t("quick/common:payment.card.labels.cvc")}
                    options={{ placeholder: "" }}
                    branded
                    valid={false}
                    invalidWarning={t("misc.required_field")}
                  />
                </StyledAppStore.Entry>
                <StyledAppStore.Entry>
                  <CardExpiry
                    label={t("quick/common:payment.card.labels.valid_until")}
                    valid={false}
                    invalidWarning={t("misc.required_field")}
                    branded
                    options={{ placeholder: expiryDate ? expiryDate : "MM / JJ" }}
                  />
                </StyledAppStore.Entry>
              </StyledAppStore.Row>
            )}
          </StyledAppStore.Row>
          {method === "sepa_debit" ? (
            <StyledAppStore.SectionTextWrapper>
              <StyledAppStore.Row height={7}>
                <StyledAppStore.Entry sepa>
                  {t("quick/common:payment.sepa_debit.explanation_text")}
                </StyledAppStore.Entry>
              </StyledAppStore.Row>
            </StyledAppStore.SectionTextWrapper>
          ) : (
            <StyledAppStore.Row height={6.1} />
          )}
        </StyledAppStore.PaymentWrapper>
      </Form>
      <StyledAppStore.ContentFooter>
        <StyledAppStore.SectionWrapper flex={EFlexDirection.ROW} width={43}>
          {!transactionLoading && !dialogContext?.state && state && (
            <Alert type={state.type}>{state.message}</Alert>
          )}
          {!state && dialogContext?.state && (
            <Alert type={AlertTypeEnums[dialogContext?.state as AlertTypeEnums]}>
              {t(`payment.${dialogContext?.state}`)}
            </Alert>
          )}
        </StyledAppStore.SectionWrapper>
        {sessionLoc && (dialogContext?.state || state) && (
          <ButtonBranded
            onClick={(): void => {
              sessionStorage.clear();
              handleLocation(sessionLoc as EAppStoreMenuItem);
            }}
            variant={ButtonBrandedVariantEnums.PRIMARY}
            inverted={false}
            type={"button"}
            inline
            disabled={!isReady || !isEmail(email)}
          >
            {t(`quick/app_store:buttons.back_to_booking`)}
          </ButtonBranded>
        )}
        {/* next btn styling will be taken to app-components */}
        {!dialogContext?.closable && (
          <StyledAppStore.DeleteAccountBtn onClick={deleteAccount}>
            {t(`quick/app_store:buttons.delete_account`)}
          </StyledAppStore.DeleteAccountBtn>
        )}
        <ButtonBranded
          onClick={(): void => {
            setCovId(uuidv4());
          }}
          variant={ButtonBrandedVariantEnums.PRIMARY}
          inverted={false}
          type={"button"}
          inline
          disabled={!isReady || !canBeSubmitted || !isEmail(email)}
        >
          {t(`quick/app_store:buttons.save`)}
        </ButtonBranded>
      </StyledAppStore.ContentFooter>
      {(customerDataLoading || isLoading || transactionLoading) && (
        <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />
      )}
    </StyledAppStore.SectionWrapper>
  );
};

export default AppStorePaymentPage;
