import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";

interface IWrapperProps {
  numColumns: number;
}

const Wrapper = styled.div<IWrapperProps>(({ theme, numColumns }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        display: none;
      `,
    },
    LARGE: {
      style: css`
        display: flex;
        width: ${numColumns * 18}vw;
        padding: ${theme.rem(1)} 0;
      `,
    },
    XLARGE: {
      style: css`
        width: ${numColumns * 18}vw;
      `,
    },
  });
});

interface ITableWrapperProps {
  product?: boolean;
  width?: string;
}

const TableWrapper = styled.div<ITableWrapperProps>(({ theme, product, width }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        position: relative;
        display: flex;
        justify-content: center;
        margin: 0 ${theme.rem(0.5)};
        padding: 0 0 ${theme.rem(0)} 0;
        width: ${width ? width : "unset"};
        box-shadow: ${product
          ? `${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
          ${theme.values.colors.global.greys._025}`
          : "none"};
        border-radius: ${product ? theme.rem(1.75) : "unset"};
        transition: transform 0.3s ease;
        will-change: transform;
        &:hover {
          transform: ${product ? "scale(1.02) translateZ(0)" : "unset"};
        }
      `,
    },
    LARGE: {
      style: css``,
    },
  });
});

const Logo = styled.img(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        height: ${theme.rem(2)};
      `,
    },
    LARGE: {
      style: css``,
    },
  });
});

const Price = styled.p(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        font-size: ${theme.rem(1.5)};
        & > sup,
        & > span {
          font-size: ${theme.rem(1)};
        }
      `,
    },
    LARGE: {
      style: css``,
    },
  });
});

const Ribbon = styled.div(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css``,
    },
    LARGE: {
      style: css`
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 2;
        & > p {
          position: absolute;
          top: ${theme.rem(3)};
          right: ${theme.rem(-0.5)};
          text-align: left;
          width: 80%;
          padding: ${theme.rem(0.6)} ${theme.rem(1)} ${theme.rem(0.5)} ${theme.rem(1)};
          background-color: ${theme.values.colors.global.secondary._085};
          box-shadow: ${`${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
            ${theme.values.colors.global.greys._025}`};
          color: ${theme.values.colors.global.greys._000};
          font-size: ${theme.rem(1)};
          font-weight: 600;
          border-radius: ${theme.rem(0.75)} ${theme.rem(0)} ${theme.rem(0)} ${theme.rem(0.75)};
        }
      `,
    },
    XLARGE: {
      style: css`
        & > p {
          top: ${theme.rem(3)};
          right: ${theme.rem(-0.5)};
          font-size: ${theme.rem(1.2)};
          padding: ${theme.rem(0.7)} ${theme.rem(1)} ${theme.rem(0.5)} ${theme.rem(1)};
        }
      `,
    },
  });
});
export const StyledComparison = {
  Wrapper,
  TableWrapper,
  Logo,
  Price,
  Ribbon,
};
