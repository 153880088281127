import { IBrandedValues, IColors, IColorStates, IThemeValues } from "../../@types/index.d";
import { rgba } from "polished";
export const colors: IColors = {
  primary: {
    first: "#00ABF7",
    second: "#075BFA",
    third: "#003F71",
    _010: "#FFEDF0",
    _025: "#FFD2D9",
    _040: "#FFB7C3",
    _055: "#FF9DAD",
    _070: "#FF8196",
    _085: "#FF677F",
    _100: "#FF4C69",
  },
  secondary: {
    first: "#A60142",
    second: "#ED1E79",
    third: "#FE85A4",
    fourth: "#008288",
    fifth: "#03B9B6",
    sixth: "#00E8E0",
    _005: "#F5F5F6",
    _010: "#EAEBED",
    _025: "#CCCDD3",
    _040: "#ADAFB9",
    _055: "#8F919F",
    _070: "#707285",
    _085: "#51546B",
    _100: "#333651",
  },
  greys: {
    first: "#FFFFFF",
    second: "#F7F7F8",
    third: "#E7E7E8",
    fourth: "#D2D4D6",
    fifth: "#8F9299",
    sixth: "#636871",
    seventh: "#232A36",
    _000: "#FFFFFF",
    _010: "#EAEAEA",
    _025: "#33333325",
    _040: "#ADADAD",
    _055: "#8F8F8F",
    _070: "#707070",
    _085: "#515151",
    _100: "#333333",
  },
  validation: {
    first: "#E81546",
    second: "#318551",
    third: "#2CD96F",
    fourth: "#F4A730",
    fifth: "#FFC263",
    success: "#28B73A",
    error: "#A8001B",
    info: "#FF7600",
    graph: "#56AAA0",
  },
  diagram: { _055: "#88BFB9", _070: "#67AEA5", _085: "#479C92", _100: "#278B7F" },
  overviewCharts: { positive: "#486E57", neutral: "#323852", negative: "#FB4B67" },
};

interface IGetButtonColorOptions {
  baseColor: string;
  baseBackground: string;
  hoverColor: string;
  hoverBackground: string;
}
const getButtonColorSet = ({
  baseColor,
  baseBackground,
  hoverColor,
  hoverBackground,
}: IGetButtonColorOptions): IColorStates => {
  return {
    base: {
      color: baseColor,
      background: baseBackground,
      inverted: {
        color: baseBackground,
        background: baseColor,
      },
    },
    hover: {
      color: hoverColor,
      background: hoverBackground,
      inverted: {
        color: hoverBackground,
        background: hoverColor,
      },
    },
    disabled: {
      color: rgba(baseColor, 0.75),
      background: rgba(baseBackground, 0.75),
      inverted: {
        color: rgba(baseBackground, 0.75),
        background: rgba(baseColor, 0.75),
      },
    },
  };
};
export const brandedValues: IBrandedValues = {
  //__typename: TypeNameEnums.THEME_OPTIONS_BRANDED,
  logo: {
    //__typename: TypeNameEnums.THEME_OPTIONS_LOGO,
    QUICK: {
      light: "/assets/logo-quick-light.svg",
      dark: "/assets/logo-quick-dark.svg",
    },
    GOLD: {
      light: "/assets/logo-gold-light.svg",
      dark: "/assets/logo-gold-dark.svg",
    },
    LITE: {
      light: "/assets/logo-lite-light.svg",
      dark: "/assets/logo-lite-dark.svg",
    },
    PROTECT: {
      light: "/assets/logo-protect-light.svg",
      dark: "/assets/logo-protect-dark.svg",
    },
    CONSULT: {
      light: "/assets/logo-consult-light.svg",
      dark: "/assets/logo-consult-dark.svg",
    },
    CONTROL: {
      light: "/assets/logo-control-light.svg",
      dark: "/assets/logo-control-dark.svg",
    },
    PRO: {
      light: "/assets/logo-pro-light.svg",
      dark: "/assets/logo-pro-dark.svg",
    },
  },
  assets: {
    //__typename: TypeNameEnums.THEME_OPTIONS_ASSETS,
    background: "",
  },
  buttons: {
    //__typename: TypeNameEnums.THEME_OPTIONS_BUTTONS,
    default: {
      color: {
        base: {
          color: colors.secondary._005,
          background: colors.secondary._085,
          inverted: { color: colors.secondary._100, background: colors.greys._000 },
        },
        hover: {
          color: colors.secondary._005,
          background: colors.secondary._085,
          inverted: { color: colors.secondary._085, background: colors.greys._000 },
        },
        disabled: {
          color: colors.secondary._100,
          background: colors.secondary._085,
          inverted: { color: colors.secondary._005, background: colors.secondary._085 },
        },
      },
      radius: "10px",
    },
    primary: {
      //__typename: TypeNameEnums.THEME_OPTIONS_PRIMARY_BUTTON,
      color: {
        base: {
          color: colors.greys._000,
          background: colors.primary._100,
          inverted: {
            color: colors.greys._000,
            background: colors.primary._085,
          },
        },
        hover: {
          color: colors.greys._000,
          background: colors.primary._085,
          inverted: {
            color: colors.primary._100,
            background: colors.greys._000,
          },
        },
        disabled: {
          color: colors.greys._000,
          background: colors.primary._055,
          inverted: {
            color: colors.primary._100,
            background: colors.greys._000,
          },
        },
      },
      radius: "10px",
    },
    secondary: {
      //__typename: TypeNameEnums.THEME_OPTIONS_SECONDARY_BUTTON,
      color: getButtonColorSet({
        baseBackground: colors.secondary._100,
        baseColor: colors.greys._000,
        hoverBackground: colors.secondary._085,
        hoverColor: colors.greys._000,
      }),
      radius: "0",
    },
  },
  inputs: {
    //__typename: TypeNameEnums.THEME_OPTIONS_INPUT,
    default: {
      //__typename: TypeNameEnums.THEME_OPTIONS_INPUT_DEFAULT,
      borderColor: colors.greys._040,
      radius: "15px",
      invalidText: colors.validation.error,
      backgroundColor: colors.secondary._005,
    },
  },
};

/**
 * Static Theme values as Default.
 */

const defaultThemeValues: IThemeValues = {
  branded: {
    ...brandedValues,
  },
  baseFontSize: 15,
  fontWeight: { normal: 400, bold: 700, bolder: 700 },
  fontFamily: {
    headline: "Lato,sans-serif",
    copy: {
      primary: "Lato,sans-serif",
      secondary: "Lato,sans-serif",
    },
  },

  webFontsConfig: {
    custom: {
      families: ["Lato:n4,n7,i4,i7"],
      urls: ["/fonts.css"],
    },
    classes: false,
  },
  colors: {
    global: colors,
  },
  buttons: {
    default: {
      radius: "10px",
      color: getButtonColorSet({
        baseBackground: colors.secondary._100,
        baseColor: colors.greys._000,
        hoverBackground: colors.secondary._085,
        hoverColor: colors.greys._000,
      }),
    },
    primary: {
      radius: "10px",
      color: getButtonColorSet({
        baseBackground: colors.primary._100,
        baseColor: colors.greys._000,
        hoverBackground: colors.primary._185,
        hoverColor: colors.greys._000,
      }),
    },
    secondary: {
      radius: "10px",
      color: getButtonColorSet({
        baseBackground: colors.secondary._085,
        baseColor: colors.greys._000,
        hoverBackground: colors.secondary._040,
        hoverColor: colors.greys._000,
      }),
    },
    terminate: {
      radius: "10px",
      color: getButtonColorSet({
        baseBackground: colors.greys._070,
        baseColor: colors.greys._040,
        hoverBackground: colors.greys._085,
        hoverColor: colors.greys._010,
      }),
    },
    warning: {
      radius: "10px",
      color: getButtonColorSet({
        baseBackground: colors.validation.first,
        baseColor: colors.greys._000,
        hoverBackground: colors.validation.fourth,
        hoverColor: colors.greys._010,
      }),
    },
  },
  inputs: {
    //__typename: TypeNameEnums.THEME_OPTIONS_INPUT,
    default: {
      //__typename: TypeNameEnums.THEME_OPTIONS_INPUT_DEFAULT,
      borderColor: colors.greys._040,
      radius: "15px",
      invalidText: colors.validation.error,
      backgroundColor: colors.secondary._005,
    },
  },
  component: {
    color: colors.greys._100,
    backgroundColor: colors.greys._040,
    header: {
      color: colors.primary._100,
      backgroundColor: colors.greys._010,
    },
    title: {
      color: colors.primary.second,
      backgroundColor: colors.greys._000,
      fontWeight: 400,
    },
    subTitle: {
      color: colors.primary._100,
      backgroundColor: colors.greys._000,
      fontWeight: 400,
    },
    content: {
      color: colors.greys._100,
      backgroundColor: colors.greys._000,
    },
    borderColors: {
      basic: colors.greys._040,
    },
  },
};
export default defaultThemeValues;
