import { FC, useEffect } from "react";
import { StyledSimpleContent, StyledSimpleFooter, StyledSimplePage } from "./styled.simple";
import { Footer } from "./Footer";
import {
  ILocalState,
  IThemeOptions,
  LayoutEnums,
  ThemeStateActionTypes,
} from "../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { IThemeOptionsAction } from "../store/reducers/themeOptions";
import { Watermark } from "./WaterMark";

export const SimpleLayout: FC = ({ children }) => {
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions);
  const dispatch = useDispatch<Dispatch<IThemeOptionsAction>>();
  useEffect(() => {
    if (themeOptions.layout !== LayoutEnums.SIMPLE) {
      dispatch({
        type: ThemeStateActionTypes.SET_LAYOUT,
        payload: { layout: LayoutEnums.SIMPLE } as Partial<IThemeOptions>,
      });
    }
  }, [dispatch, themeOptions.layout]);

  return (
    <>
      <Watermark type={"secondary"} />
      <StyledSimplePage>
        <StyledSimpleContent>{children}</StyledSimpleContent>
        <StyledSimpleFooter>
          <Footer />
        </StyledSimpleFooter>
      </StyledSimplePage>
    </>
  );
};
