import { useSelector } from "react-redux";
import { ILocalState } from "../../@types/index.d";
import { Client, Relation, RelationLink } from "@canei/app-components";
interface IUseRelationLinks {
  getRelationLinks: (clientId: string | null) => RelationLink[];
  relationLinks: RelationLink[];
}
type TUseRelationLinks = () => IUseRelationLinks;
/**
 * Returns Relation links array for current selected client and a
 * function that calculates RelationLink array for a given client id of customer
 */
export const useRelationLinks: TUseRelationLinks = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser);

  const relationLinks = (clientId: string | null): RelationLink[] => {
    if (!clientId) {
      return [];
    }
    const links = currentUser.clients.find(
      (client: Client) => client.client_id === clientId
    )?.links;

    if (!links) {
      throw new Error(
        "hooks:useRelationLinks - clientId not found in currentUser" +
          currentUser.clients.join(", ")
      );
    }

    return links.map((link: RelationLink) => {
      return { href: link.href, rel: link.rel === Relation.self ? Relation.client : link.rel };
    });
  };
  return {
    relationLinks: relationLinks(currentUser.selectedClient.client_id),
    getRelationLinks: relationLinks,
  };
};
