import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";

const GET_DEFAULT_PLACEMENT = gql`
  query getDefaultPlacement($assetType: String!) {
    getDefaultPlacement(assetType: $assetType)
  }
`;

type TUseGetDefaultPlacement = () => {
  getPlacement: TGetPlacement;
};

interface IDefaultPlacement {
  getDefaultPlacement: string;
}

type TGetPlacement = (type: string) => Promise<ApolloQueryResult<IDefaultPlacement>>;

export const useGetDefaultPlacement: TUseGetDefaultPlacement = () => {
  const client = useApolloClient();

  const getPlacement: TGetPlacement = async (assetType: string) => {
    const { data } = await client.query({
      query: GET_DEFAULT_PLACEMENT,
      variables: {
        assetType,
      },
      fetchPolicy: "network-only",
    });
    return data.getDefaultPlacement;
  };

  return {
    getPlacement,
  };
};
