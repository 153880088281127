import styled, { css } from "styled-components/macro";
import { IPreviewVariant } from "./FilePreview/PreviewVariations";

export const StyledPreviewTable = styled.div(({ theme }) => {
  return css`
    max-width: 100%;
    overflow: scroll;
    height: ${theme.rem(20)};
    position: relative;
    margin-bottom: ${theme.rem(1)};
    > table {
      position: absolute;
      left: 0;
      top: 0;
    }
  `;
});
export const StyledPreviewVariations = styled.section(({ theme }) => {
  return css`
    display: grid;
    margin-bottom: ${theme.rem(1)};
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${theme.rem(1)};
  `;
});
export const StyledPreviewVariant = styled.div<IPreviewVariant>(({ theme, selected }) => {
  const selectedStyle = css`
    background-color: ${theme.values.colors.global.greys.third};
  `;
  return css`
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
    padding: ${theme.rem(1)};
    ${selected && selectedStyle}
  `;
});
export const StyledPreviewVariantSample = styled.div(({ theme }) => {
  return css`
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.rem(1)};
  `;
});
export const StyledPreviewVariantInfo = styled.div(({ theme }) => {
  return css`
    font-size: 10px;
    flex-basis: 100%;
    height: ${theme.rem(3)};
  `;
});
export const StyledTablePreviewCell = styled.div(({ theme }) => {
  return css`
    width: 130px;
    position: relative;
    > div {
      float: left;
      width: 100%;
      height: ${theme.rem(1)};
      overflow: hidden;

      &:active {
        float: none;
        overflow: visible;
        height: auto;
      }
    }
  `;
});
