import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";

interface IFormWrapperProps {
  columnCount: 1 | 2;
}
const Wrapper = styled.div(({ theme }) => {
  return css`
    display: grid;
    grid-template-areas: "progress" "caption" "content" "footer";
    grid-auto-rows: min-content min-content auto min-content;
    height: 100%;
    flex-basis: 100%;
  `;
});
const Content = styled.div(({ theme }) => {
  return css`
    grid-area: content;
    margin-bottom: ${theme.rem(2)};
  `;
});

const FormWrapper = styled.div<IFormWrapperProps>(({ theme, columnCount }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: grid;
        column-gap: ${theme.rem(2)};
        align-content: baseline;
        width: 100%;
      `,
    },
    MEDIUM: {
      style: css`
        grid-template-columns: repeat(${columnCount}, 1fr);
      `,
    },
  });
});

const SuccessWrapper = styled.div(({ theme }) => {
  return css`
    padding: ${theme.rem(1.4)};
    text-align: center;
    font-size: ${theme.rem(1.4)};
    & > p > span {
      color: ${theme.values.colors.global.primary._100};
    }
  `;
});

const FormCaption = styled.h2(({ theme }) => {
  return css`
    grid-area: caption;
    font-size: ${theme.rem(2.2)};
    line-height: ${theme.rem(2.8)};
    font-weight: ${theme.values.fontWeight.bold};
    text-align: center;
  `;
});

const Entry = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        margin-bottom: ${theme.rem(2)};
        display: flex;
      `,
    },
    LARGE: {
      style: css`
        margin-bottom: ${theme.rem(2)};
      `,
    },
  });
});

const Footer = styled.div(({ theme }) => {
  return css`
    display: flex;
    justify-content: space-between;
    grid-area: footer;
  `;
});

const ReCaptcha = styled.div(() => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
  `;
});
export const StyledQWizardForm = {
  Wrapper,
  Content,
  Footer,
  FormWrapper,
  SuccessWrapper,
  FormCaption,
  Entry,
  ReCaptcha,
};
