import { FC, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

import { StyledDatevInterface } from "./../styled.uploaders";
import { UploadersContext } from "../index";
import {
  Panel,
  PanelBody,
  PanelFooter,
  PanelTitle,
  AlignmentEnums,
  Button,
  ButtonVariantEnums,
  FormRow,
} from "@canei/app-components";

export const DatevInterface: FC = () => {
  const { t } = useTranslation();
  const { isInitial } = useContext(UploadersContext);
  return (
    <StyledDatevInterface isLoading={false}>
      <Panel>
        <PanelTitle
          headline={t("setup.upload_form_2.datev.interface.headline")}
          subline={t("setup.upload_form_2.datev.interface.subline")}
          help={isInitial ? "0" : null}
        />
        <PanelBody>
          <Trans i18nKey={"setup.upload_form_2.datev.interface.copy_text"} />
        </PanelBody>
        <PanelFooter>
          <FormRow align={AlignmentEnums.RIGHT}>
            <Button
              variant={ButtonVariantEnums.PRIMARY}
              inverted={false}
              type={"button"}
              inline={true}
            >
              {t("setup.upload_form_2.datev.interface.button_download")}
            </Button>
            <Button
              variant={ButtonVariantEnums.SECONDARY}
              inverted={false}
              type={"button"}
              inline={true}
            >
              {t("setup.upload_form_2.datev.interface.button_howto_video")}
            </Button>
          </FormRow>
        </PanelFooter>
      </Panel>
    </StyledDatevInterface>
  );
};
