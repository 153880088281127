/* eslint no-template-curly-in-string: 0*/
const awsConfig = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://uh13rxsfm4.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  gqlGateway: {
    REGION: "eu-central-1",
    URL: "https://uh13rxsfm4.execute-api.eu-central-1.amazonaws.com/prod/graphql",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_nTNh40b2z",
    APP_CLIENT_ID: "2s7o0vdart226d8cketrcpht2f",
    IDENTITY_POOL_ID: "eu-central-1:3d4f493f-50ff-4214-90a9-3e9ef94a54c2",
  },
  i18n: {
    host: "locales.digital.prod.canei.io",
  },
  assets: {
    host: "assets.digital.prod.canei.io",
  },
  registration: {
    FORM_POST: "https://support.digital.prod.canei.io/v1/forms",
    NEXT_PAGE: "https://app.quick.prod.canei.io",
    TO_REFERRER: "https://canei.ag/register-success/",
  },
  payment: {
    publicKey: "pk_live_51Kb2CFJafEP8n6QGCsyRTLS2FsUkg2gdeLl5xjN4NI4la61T2lKAoFVYw2vLCAHISyxcMn7hG23aEXF1PwUuNIFu00jnz4w2D7",
  },
  defaults: {
    SAMPLE_SUSA_URL: "https://datastore.digital.prod.canei.io/records/files/Muster-SuSa.pdf",
    SAMPLE_SUSA_S3: "s3://datastore.digital.prod.canei.io/records/files/Muster-SuSa.pdf",
  },
};

export default awsConfig;
