import { FC, useContext } from "react";

import { Select, SelectItem, ISelectValue, SelectionTypes } from "@canei/app-components";

import { useIsMultipleUploadInProgress } from "./sharedHooks";
import { EUploadersActionTypes, UploadersDispatch } from "..";

export const MultiSuSasYear: FC = () => {
  const uploadersDispatch = useContext(UploadersDispatch);

  const currentYear = new Date().getFullYear();
  const yearsList = new Array(15).fill("").map((_, index) => (currentYear - index).toString());
  const inProgress = useIsMultipleUploadInProgress();
  const handleYearChange = (select: ISelectValue<string>): void => {
    uploadersDispatch({
      type: EUploadersActionTypes.SET_FILE_DATE,
      payload: {
        year: select.value,
      },
    });
  };
  return (
    <Select
      text={currentYear.toString()}
      type={SelectionTypes.LIST}
      name={"multi_year"}
      value={currentYear.toString()}
      onChange={handleYearChange}
      disabled={inProgress}
    >
      {yearsList.map((year) => (
        <SelectItem value={year} text={year} key={year} />
      ))}
    </Select>
  );
};
