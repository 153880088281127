import { gql, useMutation, useQuery } from "@apollo/client";
import { ILocalState } from "../../@types/index.d";

import { useSelector } from "react-redux";

const GET_PRODUCT_METADATA = gql`
  query getProductMetadata($account_id: ID!) {
    getProductMetadata(account_id: $account_id) {
      setup_status
      asset_placements {
        canei_quick_video_top_head
      }
      offer_placements {
        canei_quick_banner_middle
      }
    }
  }
`;

const MUT_PRODUCT_METADATA = gql`
  mutation setProductMetadata($account_id: ID!, $status: ProductMetadataInput) {
    setProductMetadata(account_id: $account_id, status: $status) {
      setup_status
      asset_placements {
        canei_quick_video_top_head
      }
      offer_placements {
        canei_quick_banner_middle
      }
    }
  }
`;

export enum EStatusOptions {
  INITIAL = "INITIAL",
  COMPANY_DATA = "COMPANY_DATA",
  FINANCIAL_PLANNING = "FINANCIAL_PLANNING",
  DONE = "DONE",
}
interface ISetSetupStatusVars {
  account_id: string;
  status: ProductMetadata;
}
interface ISetSetupStatus {
  setProductMetadata: ProductMetadata;
}

interface IGetSetupStatus {
  getProductMetadata: ProductMetadata;
}
interface ProductMetadata {
  setup_status: string;
  asset_placements?: AssetsPlacementsType | undefined;
  offer_placements?: OffersPlacementsType | undefined;
}
interface AssetsPlacementsType {
  canei_quick_video_top_head: string[];
}

interface OffersPlacementsType {
  canei_quick_banner_middle: string[];
}

type TUseSetSetupStatus = () => {
  setStatus: (status: ProductMetadata) => void;
  getStatus: IGetStatusRet;
  setStatusLoading: boolean;
};

interface IGetStatusRet {
  data: IGetSetupStatus | undefined;
  loading: boolean;
}
export const useSetSetupStatus: TUseSetSetupStatus = () => {
  const { currentUser } = useSelector((state: ILocalState) => state);

  const [setSetupStatus, { loading: setStatusLoading }] = useMutation<
    ISetSetupStatus,
    ISetSetupStatusVars
  >(MUT_PRODUCT_METADATA);

  const { data, loading } = useQuery<IGetSetupStatus>(GET_PRODUCT_METADATA, {
    variables: { account_id: currentUser.attributes.customer_id },
    fetchPolicy: "network-only",
  });
  return {
    setStatus: (status): void => {
      if (!currentUser.selectedClient.client_id) return;
      setSetupStatus({
        variables: {
          account_id: currentUser.selectedClient.client_id,
          status,
        },
        fetchPolicy: "network-only",
      });
      //   .then(({ data }) => {
      //   console.log(data);
      // });
      // .catch(() => {
      //   // error
      // });
    },
    getStatus: { data, loading },
    setStatusLoading,
  };
};
