// import { Dispatch, FC } from "react";
// import { IRevenueRatiosProps, RevenueRatios } from "../../../components/RevenueRatios";
// import { IDefaultRevenueRatio } from "../../../components/RevenueRatios/useRevenueRatios";
// import { Form, IFormState } from "../../../components/formElements/Form";
import { StyledIncomePlan } from "./styled.setup-content";
//
// import { ERevenueCurves } from "../../../@types/index.d";
//
// interface IIncomePlanProps {
//   context?: IRevenueRatiosProps;
//   dispatch: Dispatch<IRevenueRatiosProps>;
//   revenueRatios?: IRevenueRatiosProps;
//   disabled?: boolean;
// }
// export const revenueReducer = (
//   state: IRevenueRatiosProps,
//   action: IRevenueRatiosProps
// ): IRevenueRatiosProps => {
//   return {
//     ...state,
//     ...action,
//   };
// };
//
// export const RevenuePlan: FC<IIncomePlanProps> = ({ context, dispatch, revenueRatios }) => {
//   const handleRevenuePlanChanges = (form: IFormState): void => {
//     if (!context) return;
//     const { data: dataInRevenueContext, revenue_growth, revenue_curve } = context;
//     // const { revenue_ratios } = data.find(({ value }) => value === revenue_curve) || {};
//     const noRevenueData = revenue_growth === undefined || revenue_curve === undefined;
//     if (!form.data) return;
//     const dataInFormContext = JSON.parse(form.data) as IDefaultRevenueRatio[];
//     const updatedCurve = form.curve as ERevenueCurves;
//
//     const updatedRatios = dataInFormContext.find((data) => data.value === updatedCurve)
//       ?.revenue_ratios;
//     const updatedGrowth = parseFloat(form.growth);
//
//     const hasMissingData =
//       isNaN(updatedGrowth) || updatedCurve === undefined || updatedRatios === undefined;
//     const dataHasChanged =
//       !hasMissingData &&
//       (noRevenueData ||
//         revenue_growth !== updatedGrowth ||
//         revenue_curve !== updatedCurve ||
//         JSON.stringify(dataInRevenueContext) !== form.data);
//     if (updatedRatios === undefined) return;
//     if (dataHasChanged) {
//       dispatch({
//         ...context,
//         revenue_curve: updatedCurve,
//         revenue_growth: updatedGrowth,
//         data: dataInFormContext,
//       });
//     }
//   };
//
//   return (
//     <StyledIncomePlan isLoading={revenueRatios === undefined}>
//       <ErrorBoundary>
//         {revenueRatios && (
//           <Form subscribe={handleRevenuePlanChanges}>
//             <RevenueRatios {...revenueRatios} />
//           </Form>
//         )}
//       </ErrorBoundary>
//     </StyledIncomePlan>
//   );
// };
import { FC } from "react";
import { ErrorBoundary } from "@canei/app-components";

export const RevenuePlan: FC = ({ children }) => (
  <StyledIncomePlan isLoading={false}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </StyledIncomePlan>
);
