import styled, { css } from "styled-components/macro";

const Wrapper = styled.div(({ theme }) => {
  return css`
    padding: 0 ${theme.rem(1)} ${theme.rem(1)} ${theme.rem(1)};
    min-width: ${theme.rem(47)};
    max-height: 80vh;
    overflow: scroll;

    & td:not(:first-of-type) {
      background-color: #f5f5f5;
      padding-right: 10px;
    }
  `;
});

const Title = styled.div(({ theme }) => {
  return css`
    font-size: ${theme.rem(1.6)};
    font-weight: ${theme.values.fontWeight.bold};
    color: red;
  `;
});
const Text = styled.small(({ theme }) => {
  return css`
    display: block;
    font-size: ${theme.rem(1)};
    color: ${theme.values.colors.global.secondary._040};
    text-align: right;
    font-style: italic;
    margin-bottom: ${theme.rem(1)};
    padding-right: ${theme.rem(4)};
  `;
});

const Footer = styled.div(({ theme }) => {
  return css`
    width: 100%;
    margin-top: ${theme.rem(2)};
  `;
});

const TableWrapper = styled.section(({ theme }) => {
  return css`
    tr {
      width: 100%;
    }
  `;
});

const DetailsIcon = styled.span(({ theme }) => {
  return css`
    display: inline-block;
    transition: rotate 1s;
    padding-right: 2px;
    margin-left: -14px;
    &:hover {
      cursor: pointer;
    }
    &.rotate > span {
      transform: rotate(90deg);
    }
  `;
});

const DetailsRow = styled.tr(({ theme }) => {
  return css`
    width: 100%;
    display: none;

    &.show {
      display: table-row;
    }
  `;
});

export const StyledDetailDialog = {
  Wrapper,
  Title,
  Text,
  Footer,
  TableWrapper,
  DetailsIcon,
  DetailsRow,
};
