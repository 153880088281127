import { Dispatch, FC, Suspense, useEffect, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CustomerState, EQPrivatePaths, ILocalState } from "../../@types/index.d";
import { lazy } from "@loadable/component";
import { useDispatch, useSelector } from "react-redux";
import {
  ESubscriptionStatus,
  LoaderTypeEnums,
  LoadingIndicator,
  SelectClientState,
  useDialogs,
} from "@canei/app-components";
import Logout from "./Logout";
import { EAppStoreActionType, IAppStoreAction } from "../../_lib/store/reducers";
import { useAppStoreHelper } from "../../containers/QAppStore/useAppStoreHelper";

const Home = lazy(() => import("./Home"));
const QSetupFlow = lazy(() => import("./NewSetupFlow"));
const PaymentWall = lazy(() => import("./Payment/PaymentWall"));

export const Private: FC = () => {
  const { currentUser } = useSelector((state: ILocalState) => {
    return state;
  });
  const { content, customerData, paymentWall } = useSelector(
    (state: ILocalState) => state.appStore
  );
  const dialogs = useDialogs();
  const { getAppStoreContent } = useAppStoreHelper();
  const dispatchAppStore = useDispatch<Dispatch<IAppStoreAction>>();

  // get the content for the selected menu items
  useEffect(() => {
    if (!content) getAppStoreContent();
  }, [content, getAppStoreContent]);

  const paymentRequired = useMemo<boolean>(() => {
    if (!customerData) return false;
    const now = Date.now() / 1000;
    if (
      customerData?.status !== ESubscriptionStatus.ACTIVE &&
      customerData?.status !== ESubscriptionStatus.TRIALING
    ) {
      if (
        customerData?.status === ESubscriptionStatus.CANCELED &&
        customerData?.canceled_at &&
        customerData?.canceled_at > now
      ) {
        return false;
      }

      return true;
    }

    if (customerData?.features.find((feature) => feature.status === "payment_required"))
      return true;

    return false;
  }, [customerData]);

  useEffect(() => {
    if (paymentRequired && !paymentWall) {
      dispatchAppStore({
        type: EAppStoreActionType.SET_PAYMENT_WALL,
        payload: { paymentWall: true },
      });
    }
  }, [dispatchAppStore, paymentRequired, paymentWall]);

  useEffect(() => {
    if (!paymentRequired && paymentWall) {
      dispatchAppStore({
        type: EAppStoreActionType.SET_PAYMENT_WALL,
        payload: { paymentWall: false },
      });
    }
  }, [dispatchAppStore, paymentRequired, paymentWall]);

  useEffect(() => {
    if (!currentUser.authenticated) {
      dialogs.closeAll();
    }
  }, [currentUser.authenticated, dialogs]);

  const isLoading = useMemo(() => {
    return (
      currentUser.state === CustomerState.READY &&
      currentUser.selectedClient.state === SelectClientState.UNKNOWN
    );
  }, [currentUser.selectedClient.state, currentUser.state]);

  if (isLoading) {
    return <LoadingIndicator type={LoaderTypeEnums.PAGE} />;
  }

  return (
    <Switch>
      <Route exact path={EQPrivatePaths.SETUP}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          {currentUser.selectedClient.state === SelectClientState.READY ? (
            <Redirect to={EQPrivatePaths.HOME} />
          ) : (
            <QSetupFlow />
          )}
        </Suspense>
      </Route>
      <Route exact path={EQPrivatePaths.PAYMENT}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <PaymentWall />
        </Suspense>
      </Route>
      <Route path={EQPrivatePaths.LOGOUT}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Logout />
        </Suspense>
      </Route>
      <Route path={[EQPrivatePaths.HOME, `${EQPrivatePaths.HOME}/#/:section`]}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          {currentUser.selectedClient.state !== SelectClientState.READY && (
            <Redirect to={EQPrivatePaths.SETUP} />
          )}
          {paymentWall && currentUser.selectedClient.state === SelectClientState.READY && (
            <Redirect to={EQPrivatePaths.PAYMENT} />
          )}
          {currentUser.selectedClient.state === SelectClientState.READY && <Home />}
        </Suspense>
      </Route>
      <Route path="*">
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Redirect
            to={
              currentUser.selectedClient.state === SelectClientState.READY
                ? EQPrivatePaths.HOME
                : EQPrivatePaths.SETUP
            }
          />
        </Suspense>
      </Route>
    </Switch>
  );
};
