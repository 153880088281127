export interface IUploadsAction {
  type: EUploadsActionType;
  payload: IUploadActionPayload;
}

export interface IUploadFileState {
  name: string;
  identifier: string;
}
export enum EUploadsActionType {
  ADD_UPLOAD = "ADD_UPLOAD",
  REMOVE_UPLOAD = "REMOVE_UPLOAD",
  ADD_UPLOAD_ERROR = "ADD_UPLOAD_ERROR",
}

export interface IUploadActionPayload {
  identifier: string;
  data: Partial<Omit<IUploadFileState, "identifier">>;
}

const uploads = (state: IUploadFileState[] = [], action: IUploadsAction): IUploadFileState[] => {
  switch (action.type) {
    case EUploadsActionType.ADD_UPLOAD:
      return [
        ...state,
        { identifier: action.payload.identifier, name: "", ...action.payload.data },
      ];
    case EUploadsActionType.REMOVE_UPLOAD:
      return state.filter(({ identifier }) => identifier !== action.payload.identifier);
    case EUploadsActionType.ADD_UPLOAD_ERROR:
      return state;
    default:
      return state;
  }
};

export default uploads;
