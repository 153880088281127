import { Auth } from "aws-amplify";
import { ILocalState, UserStateActionTypes } from "../../@types/index.d";
import { gql, useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useClientSelection } from "./useClientSelection";
import { useSwitchClient } from "./useSwitchClient";
import { Dispatch } from "redux";
import { ICurrentUserAction } from "../store/reducers/currentUser";
import { useClients } from "./useClients";
import { CustomerState, SelectClientState } from "@canei/app-components";
import { useAppStoreInfo } from "./appStore";
import config from "../../config";

const GET_USERS = gql`
  query getUsers($id: ID!) {
    getUsers(id: $id) {
      user_id
    }
  }
`;

const GET_APP_USER_INFO = gql`
  query appUserInfo($userId: ID!) {
    getAppUserInfo(userId: $userId) {
      user_id
      customer_id
      alias
      email
      root
      links {
        href
        rel
      }
      clients {
        client_id
      }
      customer {
        api_access
        contact {
          first_name
          last_name
          telephone
        }
        address {
          country
        }
        customer_id
        links {
          href
          rel
        }
        name
        ou
        type
      }
    }
  }
`;

type TUseAuthState = () => IUseAuthStateReturnValue;
export interface IUseAuthStateReturnValue {
  authenticated: boolean;
  authenticating: boolean;
  readyState: CustomerState;
}
export const useAuthState: TUseAuthState = () => {
  const client = useApolloClient();
  const { clientSelection } = useClientSelection();
  const { switchClient } = useSwitchClient();
  const { update: updateClients } = useClients();
  const { currentUser } = useSelector<ILocalState, ILocalState>((state) => state);
  const { getSubscriptions, getCustomerStatus } = useAppStoreInfo();
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  const [clientListLoading, setClientListLoading] = useState(false);
  const authenticated = useMemo(() => currentUser.authenticated, [currentUser.authenticated]);
  const authenticating = useMemo(() => currentUser.authenticating, [currentUser.authenticating]);
  const readyState = useMemo(() => currentUser.state, [currentUser.state]);

  useEffect(() => {
    if (currentUser.state === CustomerState.ERROR) {
      throw new Error("Error user validation...");
    }
  }, [currentUser.state]);

  const initialClientSelectionMemo = useMemo<{
    clientId: string | null;
    state?: SelectClientState;
  }>(() => {
    // if state is already known, do nothing
    if (currentUser.selectedClient.state !== SelectClientState.UNKNOWN)
      return {
        clientId: currentUser.selectedClient.client_id,
        state: currentUser.selectedClient.state,
      };

    // currentUser has =0 clients

    if (currentUser.clients.length === 0) {
      return {
        clientId: null,
        state: SelectClientState.UNKNOWN,
      };
    }

    // currentUser has =1 clients and state is not yet known
    if (currentUser.clients.length === 1) return { clientId: currentUser.clients[0].client_id };

    // currentUser has >1 clients
    // **************************
    // has selectedClient in LocalStore
    const selectedClientIdInStorage = clientSelection.get().client_id;
    if (selectedClientIdInStorage) {
      // is this a valid client of customer:
      const isValid = currentUser.clients.find(
        (client) => client.client_id === selectedClientIdInStorage
      );
      return isValid ? { clientId: selectedClientIdInStorage } : { clientId: null };
    }
    // There are more than 1 customer and none of them previously selected,
    // let user to select one of them:
    return { clientId: null, state: SelectClientState.SELECTABLE };
  }, [currentUser, clientSelection]);

  const authCallback = useCallback(
    (user) => {
      dispatch({
        type: UserStateActionTypes.CURRENT_USER,
        payload: {
          username: user.username,
          temporaryPassword: "",
          authenticated: true,
          authenticating: false,
          forceChange: false,
          attributes: {
            // __typename: TypeNameEnums.USER_ATTRIBUTES,
            customer_id: user.attributes["custom:customer-id"],
            user_id: user.attributes["custom:user-id"],
            name: user.attributes.name,
            sub: user.attributes.sub,
          },
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentUser.authenticating) return;
    Auth.currentAuthenticatedUser()
      .then(authCallback)
      .catch(() => {
        return dispatch({
          type: UserStateActionTypes.CURRENT_USER,
          payload: {
            authenticating: false,
            // __typename: UserStateActionTypes.CURRENT_USER,
            username: "",
            authenticated: false,
            state: CustomerState.READY,
          },
        });
      });
  }, [dispatch, authCallback, currentUser.authenticating]);

  useEffect(() => {
    if (currentUser.authenticated && currentUser.state === CustomerState.UNKNOWN) {
      if (clientListLoading) return;
      let useMock: string | null = sessionStorage.getItem("useMock");
      useMock = useMock && JSON.parse(useMock);

      setClientListLoading(true);
      client
        .query({
          query: GET_USERS,
          variables: { id: config.mock_customer_id },
        })
        .then(({ data }) => {
          client
            .query({
              query: GET_APP_USER_INFO,
              variables: {
                userId: useMock ? data.getUsers[0].user_id : currentUser.attributes.user_id,
              },
            })
            .then(({ data }) => {
              const appUser = { ...data.getAppUserInfo };
              updateClients({ variables: { customer_id: appUser.customer_id } });
              getCustomerStatus(appUser.customer_id);
              // getAllPricings(config.product_id);
              // getAllPricings(config.product_key);
              return dispatch({
                type: UserStateActionTypes.CURRENT_USER,
                payload: {
                  appUser,
                },
              });
            })
            .then(({ payload }) => {
              return getSubscriptions(payload.appUser.customer_id);
            })
            .catch(() => {
              // user in cache has wrong credentials, should re login.
              return dispatch({
                type: UserStateActionTypes.CURRENT_USER,
                payload: {
                  authenticating: false,
                  // __typename: UserStateActionTypes.CURRENT_USER,
                  username: "",
                  authenticated: false,
                  state: CustomerState.READY,
                },
              });
            });
        });
    }
    return (): void => {
      if (clientListLoading) {
        setClientListLoading(false);
      }
    };
  }, [
    dispatch,
    client,
    currentUser,
    updateClients,
    clientListLoading,
    getSubscriptions,
    getCustomerStatus,
  ]);

  useEffect(() => {
    if (currentUser.state !== CustomerState.READY) return;
    if (clientSelection.get().customer_id) {
      switchClient(initialClientSelectionMemo.clientId, initialClientSelectionMemo.state);
    }
  }, [
    currentUser.state,
    clientSelection,
    switchClient,
    initialClientSelectionMemo.clientId,
    initialClientSelectionMemo.state,
  ]);

  return {
    authenticating,
    authenticated,
    readyState,
  };
};
