import { Dispatch, MouseEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IFeatures, ILocalState } from "../../@types";
import { ITreeData } from "../../_lib/hooks/appStore/appStoreTypes";
import { EAppStoreActionType, IAppStoreAction } from "../../_lib/store/reducers";
import { EButtonFunction, EButtonText, EFinePrint, IFeatureContentItem } from "./appStoreTypes.d";
import { EAppStoreMenuItem } from "./useAppStore";
import {
  ButtonBrandedVariantEnums,
  EFormatCurrencyDivision,
  EFormatNumberStyle,
  ESubscriptionStatus,
} from "@canei/app-components";
import { formatNumber } from "../../_lib/common/formatNumber";
import { EPaymentMethods, useAppStoreInfo } from "../../_lib/hooks";
import config from "../../config";

interface IUseAppStoreHelper {
  getAppStoreContent: () => void;
  enrichAppStoreContent: () => void;
  getPrice: (item: EAppStoreMenuItem) => string | undefined;
  getPricingId: (item: IFeatureContentItem) => string | undefined;
  handleLocation: (item: string) => void;
  handleSelected: (e: MouseEvent<HTMLElement>) => void;
  getStatusInfo: (item: string) => string | undefined;
  isMonthlySub: () => boolean;
  getSubExists: (item: EAppStoreMenuItem) => boolean;
  isOneTimePurchase: (item: EAppStoreMenuItem) => boolean;
  expValid: boolean;
  button: IButtonContent | undefined;
  bundles: IFeatures[] | undefined;
  finePrint: IFinePrint | undefined;
}

interface IButtonContent {
  text: EButtonText;
  inverted: boolean;
  disabled: boolean;
  variant: ButtonBrandedVariantEnums;
  onClick?: EButtonFunction | undefined;
  thirdParty?: boolean;
}

interface IFinePrint {
  text: EFinePrint;
  trial_ends: string | null;
  price: string | null | undefined;
}

export const useAppStoreHelper = (): IUseAppStoreHelper => {
  const { t } = useTranslation("quick/app_store");
  const dispatchAppStore = useDispatch<Dispatch<IAppStoreAction>>();
  const { appStore } = useSelector((state: ILocalState) => state);
  const { content, pricings, selectedMenuItem, featuresData } = useSelector(
    (state: ILocalState) => state.appStore
  );
  const { getAllPricings } = useAppStoreInfo();

  // determines if there is a bundle among features
  const bundles = useMemo(() => {
    if (!appStore.featuresData) return undefined;
    if (!appStore.customerData?.features) return undefined;

    const bundlesIncluded = appStore.customerData?.features.filter((feature) => {
      if (!appStore.featuresData?.bundleTree) return false;
      const now = Date.now() / 1000;

      return appStore.featuresData?.bundleTree.some(
        (bundle: ITreeData) =>
          feature.feature_id === bundle.value &&
          ((feature.status === "canceled" && feature.canceled_at > now) ||
            feature.status !== "canceled")
      );
    });

    // if none found return undefined, otherwise return bundle array
    return bundlesIncluded.length > 0 ? bundlesIncluded : undefined;
  }, [appStore.featuresData, appStore.customerData?.features]);

  // returns an object with information on if quick is trialing and if it is a feature page and quick is trialing
  const trialing = useMemo(() => {
    const trialing = {
      isQuickTrialing:
        content &&
        content[selectedMenuItem].product_id &&
        appStore.customerData?.status === ESubscriptionStatus.TRIALING,
      isFeatureAndQuickTrialing:
        content &&
        content[selectedMenuItem].feature_id &&
        appStore.customerData?.status === ESubscriptionStatus.TRIALING,
    };
    return trialing;
  }, [content, selectedMenuItem, appStore.customerData?.status]);

  // returns true or false according to valid payment type
  const expValid = useMemo<boolean>(() => {
    //if no payment method in customer data make false
    if (!appStore.customerData?.payment_method) return false;

    // if payment is sepa and it is in customer data make true
    if (appStore.customerData?.payment_method?.type === EPaymentMethods.sepa) return true;

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    // if payment method is card and exp date is higher than today make true
    if ((appStore.customerData?.payment_method?.exp_year as number) > currentYear) return true;
    if (
      (appStore.customerData?.payment_method?.exp_year as number) === currentYear &&
      (appStore.customerData?.payment_method?.exp_month as number) >= currentMonth
    )
      return true;

    // if payment method is card and exp date is lower than today make false
    return false;
  }, [appStore.customerData?.payment_method]);

  // create appStore Content
  const getAppStoreContent = (): void => {
    getAllPricings(config.product_id);

    // create a content object grabbing relevant info from locales
    const content = {} as Record<EAppStoreMenuItem, IFeatureContentItem>;

    // procedurally fill the content object with info from locales
    for (const key of Object.values(EAppStoreMenuItem)) {
      content[key] = {
        ...t(`quick/app_store:content.${key}`, { returnObjects: true }),
      };
    }

    // dispatch the content object to the store
    dispatchAppStore({
      type: EAppStoreActionType.SET_APP_STORE_CONTENT,
      payload: {
        content,
      },
    });
  };

  // enriches the content with which feature is disabled
  const enrichAppStoreContent = (): void => {
    const contentCopy = { ...content } as Record<EAppStoreMenuItem, IFeatureContentItem>;
    for (const key of Object.values(EAppStoreMenuItem)) {
      if (!content) break;
      // continue if page is paxment or overview
      if (key === EAppStoreMenuItem.PAYMENT || key === EAppStoreMenuItem.OVERVIEW) continue;

      // // determine if the feature is in dev status and disabled
      let disabled = pricings?.some((pricing) => pricing.feature_id === key && pricing.disabled);

      // if there is a subscription remove disabled from the feature
      if (getSubExists(key)) disabled = false;

      // determines if it is running locally
      const isDev = process.env.NODE_ENV === "development";
      // const isDev = false;

      // //enrich with disabled status
      disabled && !isDev ? (contentCopy[key].disabled = true) : (contentCopy[key].disabled = false);
    }

    // dispatch the content object to the store
    dispatchAppStore({
      type: EAppStoreActionType.SET_APP_STORE_CONTENT,
      payload: {
        content: contentCopy,
      },
    });
  };

  // get price for feature or product
  const getPrice = (item: EAppStoreMenuItem): string | undefined => {
    if (!content) return "";
    if (item === EAppStoreMenuItem.OVERVIEW || item === EAppStoreMenuItem.PAYMENT) return "";
    // grab pricing for this item from appStore.pricings
    const pricing = appStore.pricings?.find(
      (pricing) => pricing.pricing_id === getPricingId(content[item])
    );

    let price = "0,00";

    if (pricing && pricing.price)
      price = formatNumber(pricing.price.toFixed(2), {
        maximumFractionDigits: 2,
        style: EFormatNumberStyle.decimal,
        divideTo: EFormatCurrencyDivision.NONE,
      });

    if (!pricing?.subscription && getSubExists(item)) price = "0,00";
    // enrich with key price and the price as string
    // return trialing.isQuickTrialing
    //   ? "0,00"
    //   : pricing && pricing.price
    //   ? formatNumber(pricing.price.toFixed(2), {
    //       maximumFractionDigits: 2,
    //       style: EFormatNumberStyle.decimal,
    //       divideTo: EFormatCurrencyDivision.NONE,
    //     })
    //   : "0,00";
    return price;
  };

  // get pricingId to be used for feature array update
  const getPricingId = useCallback(
    (item: IFeatureContentItem): string | undefined => {
      const id = item.product_id ? item.product_id : item.feature_id;
      const param = item.product_id ? "product_id" : "feature_id";
      let pricing_id: string | undefined;

      // if product id is defined, get pricing_id from main customer object
      if (param === "product_id") {
        pricing_id = appStore.customerData?.pricing_id;
      }
      // if feature id is defined, get pricing_id from feature array
      else {
        pricing_id = appStore.customerData?.features?.find(
          (feature) => id === feature.feature_id && feature.status !== "canceled"
        )?.pricing_id;
      }

      if (!pricing_id && id)
        pricing_id = appStore.pricings?.find(
          (pricing) => pricing[param] === id && pricing.default
        )?.pricing_id;
      return pricing_id;
    },
    [appStore]
  );

  // returns true or false if it is included in subscription list using selected item as key
  const getSubExists = useCallback(
    (item: EAppStoreMenuItem): boolean => {
      if (!content) return false;
      const param = content[item].product_id ? "product_id" : "feature_id";
      let included: boolean | undefined;

      // if product id is defined, check status in main customer object
      if (param === "product_id") {
        included = appStore.customerData?.status === "active" || expValid;
      }

      // if feature id is defined, check status in feature array
      else {
        included = appStore.customerData?.features?.some(
          (feature) => feature[param] === content[item][param] && feature.status === "active"
        );
      }

      // if none found return false
      if (!included) return false;
      return included;
    },
    [appStore.customerData?.features, appStore.customerData?.status, content, expValid]
  );

  // returns true or false if a bundle is booked for items included in bundle
  const getIncluded = useCallback(
    (item: IFeatureContentItem): boolean => {
      // if there are no bundles return false
      if (!bundles) return false;
      if (!appStore.featuresData) return false;
      // if (!dialogContext) return false;

      const param = item.product_id ? item.product_id : item.feature_id;

      // if there are bundles detected compare the selected item with what is included in the bundle
      const included = appStore.featuresData.bundleTree.some((bundle: ITreeData) =>
        bundle.tree.some((item) => item.value === param)
      );

      return included;
    },
    [appStore.featuresData, bundles]
  );

  // status info for Überblick card and fineprint in content
  const getStatusInfo = useCallback(
    (item: string): string => {
      if (!content) return "";
      const param = content[item as keyof typeof content].product_id ? "product_id" : "feature_id";
      const id =
        content[item as keyof typeof content].product_id ||
        content[item as keyof typeof content].feature_id;

      let status;
      let date;
      // if product id is defined, get status from main customer object
      if (param === "product_id") {
        // status = appStore.customerData?.status;
        // if (status === "trialing") {
        //   date = (appStore.customerData?.trial_end as number) * 1000;
        //   const localDate = new Date(date).toLocaleDateString("en-GB").split("/").join(".");
        //   return `${t(`quick/app_store:option_list.trialing`)}${localDate}`;
        // }
        // if (status === "active") {
        //   date = (appStore.customerData?.start_date as number) * 1000;
        //   const localDate = new Date(date).toLocaleDateString("en-GB").split("/").join(".");
        //   return item === "canei_quick"
        //     ? "frei"
        //     : `${t(`quick/app_store:option_list.booked`)}${localDate}`;
        // }
        // if (status === "canceled") {
        //   date = (appStore.customerData?.canceled_at as number) * 1000;
        //   const localDate = new Date(date).toLocaleDateString("en-GB").split("/").join(".");
        //   return `${t(`quick/app_store:option_list.cancelled`)}${localDate}`;
        // }
        return "Kostenlos";
      }

      // if feature id is defined, get status from feature array
      else {
        const included = getIncluded(content[item as keyof typeof content]);

        status = appStore.customerData?.features?.find((feature) => feature[param] === id)?.status;

        if (status === "trialing" && !included) {
          date =
            (appStore.customerData?.features?.find((feature) => feature[param] === id)
              ?.trial_end as number) * 1000;
          const localDate = new Date(date).toLocaleDateString("en-GB").split("/").join(".");
          return `${t(`quick/app_store:option_list.trialing`)}${localDate}`;
        }
        if (status === "active" && !included) {
          date =
            (appStore.customerData?.features?.find((feature) => feature[param] === id)
              ?.start_date as number) * 1000;
          const localDate = new Date(date).toLocaleDateString("en-GB").split("/").join(".");
          return `${t(`quick/app_store:option_list.booked`)}${localDate}`;
        }
        if (status === "canceled" && !included) {
          date =
            (appStore.customerData?.features?.find((feature) => feature[param] === id)
              ?.canceled_at as number) * 1000;
          const localDate = new Date(date).toLocaleDateString("en-GB").split("/").join(".");
          return `${t(`quick/app_store:option_list.cancelled`)}${localDate}`;
        }
        if (included) return `${t(`quick/app_store:buttons.included`)}`;
      }
      return t(`quick/app_store:option_list.bookable`);
    },
    [appStore.customerData?.features, content, getIncluded, t]
  );

  // returns whether the feature is one-time purchase (like circyoular) or not (like reportings which is montly)
  const isOneTimePurchase = useCallback(
    (item: EAppStoreMenuItem): boolean => {
      if (!selectedMenuItem) return false;
      if (!content) return false;
      if (!pricings) return false;

      const pricing = pricings?.find((pricing) => pricing.feature_id === content[item].feature_id);

      if (getSubExists(item) && !content[item].subscription && !pricing?.subscription) {
        return true;
      }
      return false;
    },
    [getSubExists, content, selectedMenuItem, pricings]
  );

  // returns an object wiht data for fineprint in content
  const finePrint = useMemo<IFinePrint | undefined>(() => {
    if (!content) {
      return undefined;
    }
    const pricing_id = getPricingId(content[selectedMenuItem]);
    const pricing = pricings?.find((pricing) => pricing.pricing_id === pricing_id);

    const finePrint = {} as IFinePrint;

    // if on produt page
    if (content[selectedMenuItem].product_id) {
      if (!trialing.isQuickTrialing) {
        return undefined;
      }

      finePrint.text = EFinePrint.TRIAL_TEXT;
      finePrint.trial_ends = getStatusInfo(selectedMenuItem).slice(-10);
      finePrint.price = pricing?.price.toFixed(2);
    }
    if (content[selectedMenuItem].feature_id) {
      if (expValid) {
        return undefined;
      }

      finePrint.text = EFinePrint.ACTIVATE_TEXT;

      // if (isOneTimePurchase(selectedMenuItem)) {
      //   finePrint.text = EFinePrint.USE_TEXT;
      // }
    }

    return finePrint;
  }, [
    content,
    expValid,
    getPricingId,
    getStatusInfo,
    pricings,
    selectedMenuItem,
    trialing.isQuickTrialing,
  ]);

  // this function governs wether sub features are shown or not in sidebar
  const setSubFeaturesForNav = (menuItem: EAppStoreMenuItem): void => {
    if (!content) return;
    if (!menuItem) return;
    if (!featuresData) return;
    if (!featuresData.bundleTree) return;
    const subFeatures = featuresData.bundleTree.find((item) => item.value === menuItem);

    // open subFeatures when redirected to feature with subfeatures
    if (content && !content[menuItem as EAppStoreMenuItem].sub_feature && subFeatures) {
      dispatchAppStore({
        type: EAppStoreActionType.SET_SUB_FEATURES,
        payload: {
          menuSubFeatures: subFeatures,
        },
      });
    }

    // close subFeatures when redirected to feature with no subfeatures
    if (!subFeatures && !content[menuItem as EAppStoreMenuItem].sub_feature) {
      dispatchAppStore({
        type: EAppStoreActionType.SET_SUB_FEATURES,
        payload: {
          menuSubFeatures: undefined,
        },
      });
    }
  };

  // use list in overview to set location
  const handleLocation = (menuItem: string): void => {
    if (!content) return;

    dispatchAppStore({
      type: EAppStoreActionType.SET_SELECTED_MENU_ITEM,
      payload: {
        selectedMenuItem: menuItem as EAppStoreMenuItem,
      },
    });

    setSubFeaturesForNav(menuItem as EAppStoreMenuItem);
  };

  // use sidenav to set location
  const handleSelected = (e: MouseEvent<HTMLElement>): void => {
    if (!content) return;
    const menuItem = e.currentTarget.id as EAppStoreMenuItem;

    // dispatch the selected menu item to context
    dispatchAppStore({
      type: EAppStoreActionType.SET_SELECTED_MENU_ITEM,
      payload: {
        selectedMenuItem: menuItem,
      },
    });

    // clear session storage if user navigates to anything but payment
    if (menuItem !== EAppStoreMenuItem.PAYMENT) {
      sessionStorage.clear();
    }

    setSubFeaturesForNav(menuItem as EAppStoreMenuItem);
  };

  // get button setup
  const button = useMemo<IButtonContent | undefined>(() => {
    if (!content || !pricings) return undefined;
    const type = content[selectedMenuItem].product_id ? "product" : "feature";

    // define if it is a one-time purchase or not
    const isSubscription = pricings.some(
      (pricing) => pricing.feature_id === selectedMenuItem && pricing.subscription
    );

    const thirdParty = pricings.some(
      (pricing) => pricing.feature_id === selectedMenuItem && pricing.page
    );

    // check if a subscription exists
    const subscriptionExists = getSubExists(selectedMenuItem);

    // check if the feature is included in a bundle
    const isIncluded = getIncluded(content[selectedMenuItem]) && isSubscription;

    let button;

    // if product
    if (type === "product") {
      // subscription doesn't exist as default
      button = {
        inverted: false,
        text: EButtonText.ACTIVATE,
        disabled: false,
        variant: ButtonBrandedVariantEnums.PRIMARY,
        onClick: EButtonFunction.ADD_FEATURE,
        thirdParty: false,
      };

      // subscription exist
      if (subscriptionExists) {
        button.text = EButtonText.REMOVE;
        button.variant = ButtonBrandedVariantEnums.DEFAULT;
        button.onClick = EButtonFunction.CANCEL_FEATURE;
      }
    }

    // if feature
    if (type === "feature") {
      // default feature button
      button = {
        inverted: false,
        text: EButtonText.ADD,
        disabled: false,
        variant: ButtonBrandedVariantEnums.PRIMARY,
        onClick: EButtonFunction.ADD_FEATURE,
        thirdParty: false,
      };

      // if montly feature
      if (isSubscription) {
        // feature subscription exist
        if (subscriptionExists && !isIncluded) {
          button.text = EButtonText.REMOVE;
          button.variant = ButtonBrandedVariantEnums.DEFAULT;
          button.onClick = EButtonFunction.CANCEL_FEATURE;
        }

        // feature subscription exist and it is a 3rd party feature
        if (subscriptionExists && !isIncluded && thirdParty) {
          button.thirdParty = true;
        }

        // feature is included in a bundle
        if (isIncluded && !subscriptionExists) {
          button.inverted = true;
          button.disabled = true;
          button.text = EButtonText.INCLUDED;
          button.variant = ButtonBrandedVariantEnums.DEFAULT;
        }

        // feature subscription doesn't exist
        if (!subscriptionExists && !isIncluded) {
          // product not activated
          if (!expValid) {
            button.onClick = EButtonFunction.ADD_FEATURE;
          }
        }
      }

      // if one-time feature
      if (!isSubscription) {
        // console.log("one time feature");

        // feature subscription exists
        // if (subscriptionExists) {
        //   button.text = EButtonText.USE;
        //   button.onClick = EButtonFunction.USE_FEATURE;
        // }
        if (subscriptionExists) {
          button.text = EButtonText.ADD;
          button.disabled = true;
          button.thirdParty = true;
        }

        // feature subscription doesn't exist
        if (!subscriptionExists) {
          // product not activated
          if (!expValid) {
            button.onClick = EButtonFunction.ADD_FEATURE;
          }
        }
      }
    }
    // console.log("getButtonContent : ", button);
    return button;
  }, [content, pricings, selectedMenuItem, getSubExists, getIncluded, expValid]);

  // returns true or false if the feature is a monthly subscription. Returns true for product
  const isMonthlySub = (): boolean => {
    const isMontly = pricings?.some(
      (pricing) =>
        pricing.product_id === selectedMenuItem ||
        (pricing.feature_id === selectedMenuItem && pricing.subscription)
    );
    return isMontly ? true : false;
  };

  return {
    getAppStoreContent,
    enrichAppStoreContent,
    getPrice,
    getPricingId,
    button,
    handleLocation,
    handleSelected,
    getSubExists,
    isOneTimePurchase,
    bundles,
    finePrint,
    getStatusInfo,
    isMonthlySub,
    expValid,
  };
};
