import { gql, useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { EventErrorsCodes, RelationLink } from "@canei/app-components";

import { EDataSyncLevel, EDataSyncScope, EDataSyncTypes } from "../../@types/index.d";

const GET_CACHE_STATE = gql`
  query getTransaction(
    $transactionId: ID!
    $accountId: ID!
    $level: CacheLevel!
    $scope: EventScopes
    $type: EventTypes
  ) {
    getCachedTransaction(
      transactionId: $transactionId
      accountId: $accountId
      level: $level
      scope: $scope
      type: $type
    ) {
      status
      error {
        id
        msg
        # type
        # code
        # msg
        # source
        # created_at
      }
      action {
        type
        scope
        guid
        covid
        links {
          rel
          href
        }
      }
    }
  }
`;
interface ITransactionQueryResult {
  getCachedTransaction?: ICachedTransactionData;
}
interface ICachedTransactionData {
  action: ICachedTransactionAction;
  status: EventErrorsCodes;
  error?: ErrorInfo;
}

interface ErrorInfo {
  id: string;
  msg: string;
}

interface ICachedTransactionAction {
  covid: string;
  guid: string;
  links: RelationLink;
  type: string; //TODO: define type enums as in app_backend (POST/PUT/UPSERT)
  scope: EDataSyncScope;
}
export interface ITransactionStateInput {
  transactionId: string;
  accountId: string;
  level: EDataSyncLevel;
  scope?: EDataSyncScope;
  type?: EDataSyncTypes;
}

type IUseTransactionEventReturnValue = [
  (params: ITransactionStateInput) => void,
  Record<string, EventErrorsCodes> | undefined,
  ITransactionStateInput | undefined,
  () => void,
  string | undefined
];
type TUseTransactionEvent = () => IUseTransactionEventReturnValue;
export const useTransactionEventListener: TUseTransactionEvent = () => {
  const apolloClient = useApolloClient();
  const [errorState, setErrorState] = useState<Record<string, EventErrorsCodes> | undefined>(
    undefined
  );
  const [errMsg, setErrMsg] = useState<string | undefined>(undefined);
  const [params, setParams] = useState<ITransactionStateInput | undefined>(undefined);

  useEffect(() => {
    // let mounted = true;

    if (params === undefined) return;
    const initializedAt = Date.now();
    let i = -1;
    const getErrorState = (): void => {
      apolloClient
        .query<ITransactionQueryResult>({
          query: GET_CACHE_STATE,
          variables: {
            ...params,
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          if (data?.getCachedTransaction && data?.getCachedTransaction?.status) {
            const { status, error } = data?.getCachedTransaction;
            setErrorState({ [params.transactionId]: status });
            error && error.msg && setErrMsg(data?.getCachedTransaction?.error?.msg);
          } else {
            // poll has to be longer than visibility timeout of conversion event queue
            if ((Date.now() - initializedAt) / 1000 > /*2 Minute*/ 2 * 60) {
              setErrorState({ [params.transactionId]: EventErrorsCodes.TIME_OUT });
              window.location.reload();
            } else {
              i = window.setTimeout(getErrorState, 1000);
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    };
    getErrorState();
    return (): void => {
      // mounted = false;

      i !== -1 && window.clearTimeout(i);
    };
  }, [apolloClient, params, errMsg]);

  return [
    (p: ITransactionStateInput): void => {
      // it sets the errorstate initially to UNKNOWN until we get the response
      setErrorState({ [p.transactionId]: EventErrorsCodes.UNKNOWN });
      setParams(p);
    },
    errorState,
    params,
    (): void => {
      setParams(undefined);
      setErrorState(undefined);
      setErrMsg(undefined);
    },
    errMsg,
  ];
};
