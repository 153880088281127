export enum EPrivatePathName {
  DASHBOARD = "/dashboard",
  ACCOUNT = "/account",
  REPORTS = "/reports",
  REPORTS_CURRENT = "/reports/current-client",
  REPORTS_CROSS_CLIENT = "/reports/cross-client",
  COMPANY = "/company",
  COMPANY_DATA = "/company/data",
  COMPANY_USERS = "/company/users",
  KPI = "/kpi",
  PLAN = "/planning",
  PLAN_CREATE = "/planning/create",
  PLAN_PREVIEW = "/planning/preview",
  UPLOAD = "/upload",
  SETUP = "/setup",
  LOGOUT = "/logout",
}
export enum EPublicPathName {
  HOME = "/",
  RESET = "/reset",
  LOGIN = "/login",
  FORGOT = "/forgot",
  REGISTER = "/register",
  REGISTER_SUCCESS = "/register-success",
  CHANGE_PASSWORD = "/change-password",
}

export enum EQPrivatePaths {
  HOME = "/", // Übersicht
  SETUP = "/setup",
  PAYMENT = "/payment",
  OVERVIEW = "/#/overview", // Übersicht
  SUCCESS = "/#/success", // Erfolg
  ASSETS = "/#/assets", // Vermögen
  DEBTS = "/#/debts", // Schulden
  ABNORMALITIES = "/#/abnormalities", // Auffälligkeiten
  BALANCE = "/#/balance", // Kapitalsituation
  BANKERS = "/#/bankers", // Bankensicht
  KPIS = "/#/kpis", // Kennzahlen
  LIQUIDITY = "/#/liquidity", //Liquiditätsplanung
  PROFITLOSS = "/#/profitloss", //Ertragsplanung
  GRADE = "/#/grade", // Unternehmenzeugnis
  UPGRADE = "/#/upgrade", // Upgrade
  COSTS = "/#/costs", // Ausgaben
  OFFER = "/#/offer", // Offer
  LOGOUT = "/logout",
}
