import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { ELegalForms } from "@canei/app-components";

const GET_LEGAL_FORMS = gql`
  query {
    results: getLegalForms {
      key
      value
    }
  }
`;

interface ILegalFormsQuery {
  results: [ILegalFormsQueryResult];
}
interface ILegalFormsQueryResult {
  key: string;
  value: ELegalForms;
}

interface IUseLegalFormsRetVal extends Pick<ILegalFormsQueryResult, "value"> {
  text: string;
}
export const useLegalForms = (): IUseLegalFormsRetVal[] => {
  const { t } = useTranslation(["quick/common"]);
  const { data } = useQuery<ILegalFormsQuery>(GET_LEGAL_FORMS);
  const translated = t("quick/common:wizards.registration.customer_data.legal_form_types", {
    returnObjects: true,
  }) as Record<ELegalForms, string>;

  return (
    data?.results.map(({ value }) => ({
      value,
      text: translated[value],
    })) || []
  );
};
