import { IFilterableData } from "@canei/app-components";
import { useQuery, gql, QueryResult } from "@apollo/client";

const GET_CATEGORIES_TREE = gql`
  {
    result: getCategoriesTree {
      value
      tree {
        value
        tree {
          value
        }
      }
    }
  }
`;

export interface ICategoryTreeQueryResult {
  result: IFilterableData[];
}

export const useCategoriesData = (): QueryResult<ICategoryTreeQueryResult> => {
  return useQuery<ICategoryTreeQueryResult>(GET_CATEGORIES_TREE);
};
