import { gql } from "@apollo/client";

export const GET_PLANS_OF_CLIENT = gql`
  query getPlans($params: GetPlansInput!) {
    getPlans(params: $params) {
      plan_id
      active
      title
      description
      created_at
      months
      start_month
    }
  }
`;

export const EDIT_ERTRAGS_PLAN = gql`
  mutation editErtragsPlan($plan: ErtragsPlanInput!, $covId: ID!) {
    editErtragsPlan(plan: $plan, covId: $covId) {
      plan_id
      interval
    }
  }
`;

export const GET_LIQUIDITY_PLAN = gql`
  query getLiquidityPlan($client_id: ID!, $type: String!) {
    getLiquidityPlan(client_id: $client_id, type: $type) {
      plan_id
      susa_id
      client_id
      title
      description
      revenue_ratios {
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
      }
      revenue_growth
      months
      status
      created_at
      active
      values_updated_at
      interval
      calculated {
        week
        month
        business_month
        date
        data {
          global_code_id
          balance
          balance_cumulated
          starting_balance
          sum
          ratio
        }
      }
    }
  }
`;

export const EDIT_LIQUIDITY_PLAN = gql`
  mutation editLiquidityPlan($plan: LiquidityPlanInput!, $covId: ID!) {
    editLiquidityPlan(plan: $plan, covId: $covId) {
      plan_id
      interval
    }
  }
`;

export const CREATE_PLAN = gql`
  mutation ceatePlan($plan: PlanInput!) {
    createPlan(plan: $plan) {
      plan_id
      active
      title
      description
      created_at
    }
  }
`;
export const COPY_PLAN = gql`
  mutation copyPlan($plan: CopyPlanInput!, $plan_id: ID!) {
    copyPlan(plan: $plan, plan_id: $plan_id) {
      plan_id
      active
      title
      description
      created_at
    }
  }
`;
export const EDIT_PLAN = gql`
  mutation copyPlan($plan: CopyPlanInput!, $plan_id: ID!) {
    editPlan(plan: $plan, plan_id: $plan_id) {
      plan_id
      active
      title
      description
      created_at
    }
  }
`;
export const DELETE_PLAN = gql`
  mutation deletePlan($plan_id: ID!) {
    deletePlan(plan_id: $plan_id)
  }
`;
