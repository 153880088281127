import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { ILogoProps, localCache, LocalCacheEnums } from "@canei/app-components";

const CLIENT_LOGO_QUERY = gql`
  query getClientLogo($id: ID!) {
    getClientLogo(id: $id) {
      image
      hash
      ext
    }
  }
`;
const CLIENT_LOGO_HASH = gql`
  query logoHash($id: ID!) {
    getClientLogoHash(id: $id)
  }
`;
export type TUseClientLogo = (client_id?: string) => IUseClientLogoReturnValue;
export type IUseClientLogoReturnValue = [
  (client_id?: string) => void,
  {
    image?: string;
    loading: boolean;
    hash?: string;
  }
];
export const useClientLogo: TUseClientLogo = () => {
  const [getLogo] = useLazyQuery(CLIENT_LOGO_QUERY, {
    onCompleted: (data) => {
      const { image, hash } = data?.getClientLogo || {};

      if (image && hash) {
        if (!logoData) {
          setLogoData(image);
          localCache.setItem(LocalCacheEnums.CLIENT_LOGOS, { hash, image });
        }
      }
    },
  });
  const [getLogoHash, { variables: hashVariables }] = useLazyQuery(CLIENT_LOGO_HASH, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      //
      if (data?.getClientLogoHash === null) {
        getLogo({ variables: { id: clientId } });

        setLogoData(undefined);
        return;
      }
      const _hash = data?.getClientLogoHash;
      if (_hash !== hash) setHash(_hash);
      const logos = localCache.getItem(LocalCacheEnums.CLIENT_LOGOS) as unknown as ILogoProps[];

      if (Array.isArray(logos)) {
        const clientLogo = logos.find((logo) => logo.hash && logo.hash.toString() === _hash);
        if (clientLogo) {
          setLogoData(clientLogo.image);
        } else {
          getLogo({ variables: { id: clientId } });
        }
      } else {
        getLogo({ variables: { id: clientId } });
      }
    },
  });

  const [logoData, setLogoData] = useState<string | undefined>(undefined);
  const [clientId, setClientId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [hash, setHash] = useState<undefined | string>();

  useEffect(() => {
    if (!clientId) return;
    // if (hashLoading || hashCalled) return;
    if (hashVariables && hashVariables.id === clientId) return;
    setHash(undefined);
  }, [clientId, getLogoHash, hashVariables]);

  return [
    (client_id?: string): void => {
      if (clientId !== client_id) {
        getLogoHash({ variables: { id: client_id } });
        setClientId(client_id);
        setLoading(false);
        setHash(undefined);
        setLogoData(undefined);
      }
    },
    { image: logoData, loading, hash },
  ];
};
