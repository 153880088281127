import {
  createContext,
  Dispatch,
  FC,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import {
  ILocalState,
  IWarmUpScopes,
  IWarmUpTopics,
  UserStateActionTypes,
  EQPrivatePaths,
} from "../../../@types/index.d";

import { SimpleLayout } from "../../../_lib/Layout";
import { SetupSteps } from "./SetupSteps";
import { StyledQWizardForm } from "../../../StyledComponents/styled.q-wizard-form";
import {
  ButtonBranded,
  FileData,
  ButtonBrandedVariantEnums,
  EClientFiscalYear,
  EventErrorsCodes,
  FileUploadFormat,
  FileUploadVariant,
  IDialogKeyEnums,
  IQProgressProps,
  QProgress,
  ErrorBoundary,
  useDialogs,
  isValidLengthString,
  EQCardSectionTypes,
  QCard,
  QCardSection,
  LoadingIndicator,
  LoaderTypeEnums,
  SelectClientState,
} from "@canei/app-components";
import { useTranslation } from "react-i18next";
import {
  useClients,
  useRelationLinks,
  useSelectedClientData,
  useSuSaUpload,
  useWarmUp,
} from "../../../_lib/hooks";

import { EPreviewErrorCodes, IFileUploadState } from "../../../containers/Uploaders";
import { useDispatch, useSelector } from "react-redux";
import { ICurrentUserAction } from "../../../_lib/store/reducers";
import { Logo } from "../../Public/Logo";
import { StyledSetup } from "./styled.setup";
import { useHistory } from "react-router-dom";

//COMMENTED LINES ARE RELATED TO SUSA FORMAT, NOW IT IS HARDCODED AS PDV_DATEV_PRO
export interface IQuickSetupContextData {
  companyData: IQuickCompanyData;
  paymentData: IQuickPaymentData;
  progress: IQProgressProps<EQuickSetupSteps>;
  isCompanyDataValid: Record<keyof IQuickCompanyData, ETriState>;
  isPaymentDataValid: Record<keyof IQuickPaymentData, ETriState>;
  canUpload: boolean;
  transactionError?: EventErrorsCodes;
  errMsg?:
    | string
    | undefined /* errMsg added to render the message of 411 error which is coming from useTransaction event via useSusaUpload event */;
}

export interface IQuickCompanyData {
  fiscal_year?: EClientFiscalYear;
  susa_date?: string;
  file?: FileData;
  // format?: TSusaUploadFormat;
  format?: FileUploadFormat.PDF_DATEV_PRO;
}

export type TSusaUploadFormat =
  | FileUploadFormat.PDF_DATEV_COMPANY
  | FileUploadFormat.PDF_DATEV_PRO
  | "__INITIAL__";
export interface IQuickPaymentData {
  company?: string;
  firstname?: string;
  lastname?: string;
  street?: string;
  zip?: string;
  city?: string;
  method?: string;
  owners_name?: string;
  card_number?: string;
  cvc?: string;
  valid_until?: string;
}

export enum ETriState {
  INITIAL,
  TRUE,
  FALSE,
}
export interface IQuickSetupAction {
  type: IQuickSetupActionTypes;
  payload: Partial<IQuickSetupContextData>;
}

export enum EQuickSetupSteps {
  COMPANY_DATA = "COMPANY_DATA",
  DONE = "DONE",
}
export enum IQuickSetupActionTypes {
  SET_FISCAL_YEAR = "SET_FISCAL_YEAR",
  SET_SUSA_DATE = "SET_SUSA_DATE",
  SET_SUSA_FORMAT = "SET_SUSA_FORMAT",
  PREV_STEP = "PREV_STEP",
  // NEXT_STEP = "NEXT_STEP",
  UPLOAD_SUCCEEDED = "UPLOAD_SUCCEEDED",
  SET_SUSA_FILE = "SET_SUSA_FILE",
  SET_COMPANY = "SET_COMPANY",
  SET_FIRSTNAME = "SET_FIRSTNAME",
  SET_LASTNAME = "SET_LASTNAME",
  SET_STREET = "SET_STREET",
  SET_ZIP = "SET_ZIP",
  SET_CITY = "SET_CITY",
  SET_METHOD = "SET_METHOD",
  SET_OWNERS_NAME = "SET_OWNERS_NAME",
  SET_CARD_NUMBER = "SET_CARD_NUMBER",
  SET_CVC = "SET_CVC",
  SET_VALID_UNTIL = "SET_VALID_UNTIL",
  VALIDATE_COMPANY_DATA = "VALIDATE_COMPANY_DATA",
  SET_TRANSACTION_STATE = "SET_TRANSACTION_STATE",
  SET_ERR_MSG = "SET_ERR_MSG",
}
const initialQSetupData: IQuickSetupContextData = {
  companyData: {
    // format: "__INITIAL__",
    format: FileUploadFormat.PDF_DATEV_PRO,
  },
  paymentData: {},
  canUpload: false,

  progress: {
    steps: [],
    currentStep: EQuickSetupSteps.COMPANY_DATA,
  },
  isCompanyDataValid: {
    susa_date: ETriState.INITIAL,
    fiscal_year: ETriState.INITIAL,
    file: ETriState.INITIAL,
    // format: ETriState.INITIAL,
    format: ETriState.TRUE,
  },
  isPaymentDataValid: {
    company: ETriState.INITIAL,
    firstname: ETriState.INITIAL,
    lastname: ETriState.INITIAL,
    street: ETriState.INITIAL,
    zip: ETriState.INITIAL,
    city: ETriState.INITIAL,
    method: ETriState.INITIAL,
    owners_name: ETriState.INITIAL,
    card_number: ETriState.INITIAL,
    cvc: ETriState.INITIAL,
    valid_until: ETriState.INITIAL,
  },
  errMsg: "",
};

export const QuickSetupContext = createContext<IQuickSetupContextData>(initialQSetupData);
export const QuickSetupDispatch = createContext<Dispatch<IQuickSetupAction>>((state) => state);
const QuickSetupReducer = (
  state: IQuickSetupContextData,
  action: IQuickSetupAction
): IQuickSetupContextData => {
  const validateCompanyData = (): IQuickSetupContextData => {
    const canUpload =
      Object.values(state.isCompanyDataValid).filter((v) => v !== ETriState.TRUE).length === 0;

    return {
      ...state,
      canUpload,
      isCompanyDataValid: {
        ...state.isCompanyDataValid,
        fiscal_year:
          state.isCompanyDataValid.fiscal_year === ETriState.TRUE
            ? ETriState.TRUE
            : ETriState.FALSE,
        susa_date:
          state.isCompanyDataValid.susa_date === ETriState.TRUE ? ETriState.TRUE : ETriState.FALSE,
        file: state.isCompanyDataValid.file === ETriState.TRUE ? ETriState.TRUE : ETriState.FALSE,
        // format:
        //   state.isCompanyDataValid.format === ETriState.TRUE ? ETriState.TRUE : ETriState.FALSE,
        format: ETriState.TRUE,
      },
    };
  };
  const validatePaymentElement = (el: Partial<keyof IQuickPaymentData>): IQuickSetupContextData => {
    if (action.payload.paymentData === undefined) return state;
    const value = action.payload.paymentData[el] || "";

    return {
      ...state,
      paymentData: {
        ...state.paymentData,
        [el]: value,
      },
      isPaymentDataValid: {
        ...state.isPaymentDataValid,
        [el]: isValidLengthString(value) ? ETriState.TRUE : ETriState.FALSE,
      },
    };
  };
  // TODO: Include Payment and Payment Validation
  // const validatePayment = (): IQuickSetupContextData => {
  //   const areAllPaymentDataValid =
  //     Object.values(state.isPaymentDataValid).filter((v) => v !== ETriState.TRUE).length === 0;
  //   if (areAllPaymentDataValid) {
  //     return { ...state, progress: { ...state.progress, currentStep: EQuickSetupSteps.DONE } };
  //   }
  //   const validateAll = Object.entries(state.isPaymentDataValid).reduce(
  //     (prev, [key, value]) => ({
  //       ...prev,
  //       [key]: value === ETriState.TRUE ? ETriState.TRUE : ETriState.FALSE,
  //     }),
  //     {}
  //   );
  //
  //   return { ...state, isPaymentDataValid: { ...state.isPaymentDataValid, ...validateAll } };
  // };

  switch (action.type) {
    case IQuickSetupActionTypes.SET_FISCAL_YEAR: {
      const { fiscal_year } = action.payload.companyData || {};
      if (fiscal_year === undefined) return state;
      return {
        ...state,
        companyData: {
          ...state.companyData,
          fiscal_year,
        },
        isCompanyDataValid: { ...state.isCompanyDataValid, fiscal_year: ETriState.TRUE },
      };
    }
    case IQuickSetupActionTypes.SET_SUSA_DATE: {
      const { susa_date } = action.payload.companyData || {};
      if (susa_date === undefined) return state;
      return {
        ...state,
        companyData: {
          ...state.companyData,
          susa_date,
        },
        isCompanyDataValid: { ...state.isCompanyDataValid, susa_date: ETriState.TRUE },
      };
    }
    case IQuickSetupActionTypes.SET_SUSA_FILE: {
      if (action.payload.companyData?.file === undefined)
        return {
          ...state,
          companyData: {
            ...state.companyData,
            file: undefined,
          },
          canUpload: false,
          isCompanyDataValid: { ...state.isCompanyDataValid, file: ETriState.FALSE },
        };
      return {
        ...state,
        companyData: {
          ...state.companyData,
          file: action.payload.companyData.file,
        },
        isCompanyDataValid: { ...state.isCompanyDataValid, file: ETriState.TRUE },
      };
    }
    // case IQuickSetupActionTypes.SET_SUSA_FORMAT: {
    //   if (action.payload.companyData?.format === undefined) return state;
    //   if (action.payload.companyData?.format === "__INITIAL__")
    //     return {
    //       ...state,
    //       canUpload: false,
    //       isCompanyDataValid: { ...state.isCompanyDataValid, format: ETriState.FALSE },
    //     };
    //   return {
    //     ...state,
    //     companyData: {
    //       ...state.companyData,
    //       format: action.payload.companyData.format,
    //     },
    //     isCompanyDataValid: { ...state.isCompanyDataValid, format: ETriState.TRUE },
    //   };
    // }
    case IQuickSetupActionTypes.PREV_STEP: {
      return {
        ...state,
        progress: { ...state.progress, currentStep: EQuickSetupSteps.COMPANY_DATA },
      };
    }
    case IQuickSetupActionTypes.UPLOAD_SUCCEEDED: {
      return { ...state, progress: { ...state.progress, currentStep: EQuickSetupSteps.DONE } };
    }
    case IQuickSetupActionTypes.VALIDATE_COMPANY_DATA: {
      return validateCompanyData();
    }
    case IQuickSetupActionTypes.SET_COMPANY:
      return validatePaymentElement("company");
    case IQuickSetupActionTypes.SET_FIRSTNAME:
      return validatePaymentElement("firstname");
    case IQuickSetupActionTypes.SET_LASTNAME:
      return validatePaymentElement("lastname");
    case IQuickSetupActionTypes.SET_STREET:
      return validatePaymentElement("street");
    case IQuickSetupActionTypes.SET_ZIP:
      return validatePaymentElement("zip");
    case IQuickSetupActionTypes.SET_CITY:
      return validatePaymentElement("city");
    case IQuickSetupActionTypes.SET_METHOD:
      return validatePaymentElement("method");
    case IQuickSetupActionTypes.SET_OWNERS_NAME:
      return validatePaymentElement("owners_name");
    case IQuickSetupActionTypes.SET_CARD_NUMBER:
      return validatePaymentElement("card_number");
    case IQuickSetupActionTypes.SET_CVC:
      return validatePaymentElement("cvc");
    case IQuickSetupActionTypes.SET_VALID_UNTIL:
      return validatePaymentElement("valid_until");
    case IQuickSetupActionTypes.SET_TRANSACTION_STATE:
      return { ...state, transactionError: action.payload.transactionError };
    case IQuickSetupActionTypes.SET_ERR_MSG:
      return { ...state, errMsg: action.payload.errMsg };
    default:
      return state;
  }
};

interface ITranslatedSetupData {
  step_name: string;
  headline: string;
  labels: Record<string, string>;
}
type TTranslatedText = Record<EQuickSetupSteps, ITranslatedSetupData>;

export const Setup: FC = () => {
  const { relationLinks } = useRelationLinks();
  const { currentUser } = useSelector((state: ILocalState) => state);
  const selectedClientData = useSelectedClientData();
  const history = useHistory();
  const { t } = useTranslation(["quick/common"]);
  const [upload, , errorState, transaction, resetTransaction, errMsg] = useSuSaUpload();
  const { updateClientData } = useClients();
  const warmUp = useWarmUp();
  const dialogs = useDialogs();
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const revertClientState = useCallback(() => {
    dispatchUser({ type: UserStateActionTypes.SET_SELECTED_CLIENT_DRAFT, payload: {} });
  }, [dispatchUser]);
  const text: TTranslatedText = {
    [EQuickSetupSteps.COMPANY_DATA]: t("wizards.setup.company_data", { returnObjects: true }),
    [EQuickSetupSteps.DONE]: t("wizards.setup.done", { returnObjects: true }),
  };
  const [context, dispatch] = useReducer<Reducer<IQuickSetupContextData, IQuickSetupAction>>(
    QuickSetupReducer,
    {
      ...initialQSetupData,
      progress: {
        steps: [
          { label: text.COMPANY_DATA.step_name, stepId: EQuickSetupSteps.COMPANY_DATA },
          /*{ label: text.PAYMENT.step_name, stepId: EQuickSetupSteps.PAYMENT },*/
          { label: text.DONE.step_name, stepId: EQuickSetupSteps.DONE },
        ],
        currentStep: EQuickSetupSteps.COMPANY_DATA,
      },
    }
  );

  useEffect(() => {
    warmUp(IWarmUpTopics.FILE_TOPIC, IWarmUpScopes.SUSA);
  }, [warmUp]);
  useEffect(() => {
    dialogs.close(IDialogKeyEnums.QUICK_SUSA_UPLOAD);
  }, [dialogs]);
  useEffect(() => {
    if (transaction?.transactionId && errorState) {
      const errorCode = errorState[transaction.transactionId];
      switch (errorCode) {
        case EventErrorsCodes.UNKNOWN:
          if (context.transactionError !== undefined) {
            dispatch({
              type: IQuickSetupActionTypes.SET_TRANSACTION_STATE,
              payload: { transactionError: undefined },
            });
          }
          break;
        case EventErrorsCodes.TIME_OUT:
          if (context.transactionError !== EventErrorsCodes.UPLOAD_TIME_OUT) {
            dispatch({
              type: IQuickSetupActionTypes.SET_TRANSACTION_STATE,
              payload: { transactionError: EventErrorsCodes.UPLOAD_TIME_OUT },
            });
            dispatch({
              type: IQuickSetupActionTypes.SET_SUSA_FILE,
              payload: { companyData: { ...context.companyData, file: undefined } },
            });
            revertClientState();
          }
          break;
        case EventErrorsCodes.TRANSACTION_OK: {
          if (context.progress.currentStep === EQuickSetupSteps.COMPANY_DATA) {
            dispatchUser({ type: UserStateActionTypes.SET_SELECTED_CLIENT_UNKNOWN, payload: {} });
            dispatch({ type: IQuickSetupActionTypes.UPLOAD_SUCCEEDED, payload: {} });
          }
          break;
        }
        case EventErrorsCodes.FILE_EVENT_TYPE: {
          errMsg &&
            dispatch({
              type: IQuickSetupActionTypes.SET_ERR_MSG,
              payload: { errMsg },
            });
          if (context.progress.currentStep === EQuickSetupSteps.COMPANY_DATA) {
            dispatch({
              type: IQuickSetupActionTypes.SET_TRANSACTION_STATE,
              payload: { transactionError: EventErrorsCodes.FILE_EVENT_TYPE },
            });
            revertClientState();
          }
          break;
        }
        default:
          if (context.transactionError !== errorCode) {
            dispatch({
              type: IQuickSetupActionTypes.SET_TRANSACTION_STATE,
              payload: { transactionError: errorCode },
            });
            revertClientState();
          }
      }
    }
  }, [
    context.progress.currentStep,
    context.transactionError,
    context.companyData,
    dispatchUser,
    errorState,
    revertClientState,
    transaction,
    errMsg,
  ]);

  const handleStepBack = (): void => {
    //TODO: back to re-upload currently disabled
    // dispatch({ type: IQuickSetupActionTypes.PREV_STEP, payload: {} });
  };
  const handleLogout = (): void => {
    history.push(EQPrivatePaths.LOGOUT);
  };

  const handleGoToNextStep = (): void => {
    switch (context.progress.currentStep) {
      case EQuickSetupSteps.COMPANY_DATA:
        resetTransaction();
        dispatch({ type: IQuickSetupActionTypes.VALIDATE_COMPANY_DATA, payload: {} });
        break;
      case EQuickSetupSteps.DONE:
        dispatchUser({ type: UserStateActionTypes.SET_SELECTED_CLIENT_READY, payload: {} });

        break;
      default:
        return;
    }
  };

  const uploadableFile = useMemo<IFileUploadState | undefined>(() => {
    if (context.companyData.file === undefined) return undefined;
    if (context.companyData.susa_date === undefined) return undefined;
    if (currentUser.selectedClient.client_id === null)
      throw new Error("Client can not be undefined");
    return {
      client_id: currentUser.selectedClient.client_id,
      file: {
        client_id: currentUser.selectedClient.client_id,
        ...context.companyData.file,
        links: relationLinks,
        date: context.companyData.susa_date,
      },
      arrangementDone: true,
      previewErrorCode: EPreviewErrorCodes.OK,
      fileSelected: null,
      dateSelected: null,
      isReady: true,
      files: {},
      savePreferences: false,
      fileUploadState: {},
      settings: {
        // format: context.companyData.format as FileUploadFormat,
        format: FileUploadFormat.PDF_DATEV_PRO,
        variant_type: FileUploadVariant.UNKNOWN,
      },
      multipleUpload: false,
    };
  }, [
    context.companyData.file,
    context.companyData.susa_date,
    // context.companyData.format,
    currentUser.selectedClient,
    relationLinks,
  ]);
  useEffect(() => {
    if (selectedClientData === false) return;
    if (!context.companyData.fiscal_year) return;
    if (transaction === undefined && context.canUpload && uploadableFile !== undefined) {
      dispatch({
        type: IQuickSetupActionTypes.SET_SUSA_FILE,
        payload: { companyData: { ...context.companyData, file: undefined } },
      });

      dispatchUser({ type: UserStateActionTypes.SET_SELECTED_CLIENT_PENDING, payload: {} });

      updateClientData({
        client_id: selectedClientData.client_id,
        data: { fiscal_year: context.companyData.fiscal_year },
      }).then(() => {
        upload(uploadableFile);
      });
    }
  }, [
    context.canUpload,
    context.companyData.fiscal_year,
    context.companyData,
    dispatchUser,
    selectedClientData,
    transaction,
    upload,
    uploadableFile,
    updateClientData,
    currentUser?.selectedClient?.client_id,
  ]);

  if (currentUser.selectedClient.state === SelectClientState.PENDING) {
    return <LoadingIndicator type={LoaderTypeEnums.PAGE} />;
  }

  return (
    <SimpleLayout>
      <ErrorBoundary>
        <QuickSetupDispatch.Provider value={dispatch}>
          <QuickSetupContext.Provider value={context}>
            <StyledSetup.Wrapper>
              <StyledSetup.LogoWrapper>
                <Logo isDark={false} />
              </StyledSetup.LogoWrapper>
              <QCard>
                <QCardSection type={EQCardSectionTypes.DEFAULT}>
                  <StyledQWizardForm.Wrapper>
                    <QProgress {...context.progress} />
                    <StyledQWizardForm.FormCaption>
                      {text[context.progress.currentStep].headline}
                    </StyledQWizardForm.FormCaption>
                    <StyledQWizardForm.Content>
                      <SetupSteps />
                    </StyledQWizardForm.Content>
                    <StyledQWizardForm.Footer>
                      <ButtonBranded
                        type={"button"}
                        inline={true}
                        variant={ButtonBrandedVariantEnums.DEFAULT}
                        onClick={handleLogout}
                        inverted
                      >
                        {t("quick/common:wizards.logout")}
                      </ButtonBranded>
                      {context.progress.currentStep === undefined && (
                        <ButtonBranded
                          inverted={true}
                          type={"button"}
                          inline={true}
                          variant={ButtonBrandedVariantEnums.DEFAULT}
                          onClick={handleStepBack}
                        >
                          {t("quick/common:wizards.back")}
                        </ButtonBranded>
                      )}
                      <ButtonBranded
                        inverted={false}
                        type={"button"}
                        inline={true}
                        variant={ButtonBrandedVariantEnums.PRIMARY}
                        disabled={false}
                        onClick={handleGoToNextStep}
                      >
                        {t("quick/common:wizards.next")}
                      </ButtonBranded>
                    </StyledQWizardForm.Footer>
                  </StyledQWizardForm.Wrapper>
                </QCardSection>
              </QCard>
            </StyledSetup.Wrapper>
          </QuickSetupContext.Provider>
        </QuickSetupDispatch.Provider>
      </ErrorBoundary>
    </SimpleLayout>
  );
};
export default Setup;
