import { IAppStoreInfo, IFeatures, IPricing, IRenderRules, ISubscription } from "../../../@types";
import { EAppStoreMenuItem } from "../../../containers/QAppStore";
import { IFeatureContentItem } from "../../../containers/QAppStore/appStoreTypes";
import {
  IBillingDetailedAddress,
  ICoupon,
  ICustomerData,
  IFeaturesDataRetVal,
  IGetPromotionsData,
  ITreeData,
} from "../../hooks/appStore/appStoreTypes";
import initialState from "../initialState";

export interface IAppStoreAction {
  type: EAppStoreActionType;
  payload: IAppStorePayload;
}
export interface IAppStorePayload {
  subscriptions?: ISubscription[];
  paymentType?: string;
  features?: IFeatures[];
  pricings?: IPricing[];
  customerData?: ICustomerData;
  couponList?: ICoupon[];
  featuresData?: IFeaturesDataRetVal;
  promotionsData?: IGetPromotionsData[];
  billingAddress?: IBillingDetailedAddress;
  content?: Record<EAppStoreMenuItem, IFeatureContentItem>;
  selectedMenuItem?: EAppStoreMenuItem;
  paymentWall?: boolean;
  menuSubFeatures?: ITreeData;
  comparisonData?: IRenderRules[];
}

export enum EAppStoreActionType {
  SET_SUBSCRIPTION_DATA = "SET_SUBSCRIPTION_DATA",
  SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE",
  SET_PRICINGS = "SET_PRICINGS",
  SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA",
  SET_COUPON_LIST = "SET_COUPON_LIST",
  SET_FEATURES_DATA = "SET_FEATURES_DATA",
  SET_PROMOTIONS_DATA = "SET_PROMOTIONS_DATA",
  SET_BILLING_ADDRESS = "SET_BILLING_ADDRESS",
  SET_APP_STORE_CONTENT = "SET_APP_STORE_CONTENT",
  SET_SELECTED_MENU_ITEM = "SET_SELECTED_MENU_ITEM",
  SET_PAYMENT_WALL = "SET_PAYMENT_WALL",
  SET_SUB_FEATURES = "SET_SUB_FEATURES",
  SET_COMPARISON_DATA = "SET_COMPARISON_DATA",
}

const appStore = (state = initialState.appStore, action: IAppStoreAction): IAppStoreInfo => {
  switch (action.type) {
    case EAppStoreActionType.SET_SUBSCRIPTION_DATA:
      if (action.payload.subscriptions === undefined) return state;
      // if (state.subscription === undefined) return state;
      return {
        ...state,
        subscriptions: action.payload.subscriptions,
      };
    case EAppStoreActionType.SET_PAYMENT_TYPE:
      if (action.payload.paymentType === undefined) return state;
      return {
        ...state,
        paymentType: action.payload.paymentType,
      };
    case EAppStoreActionType.SET_PRICINGS:
      if (action.payload.pricings === undefined) return state;
      return {
        ...state,
        pricings: action.payload.pricings,
      };
    case EAppStoreActionType.SET_CUSTOMER_DATA:
      if (action.payload.customerData === undefined) return state;
      return {
        ...state,
        customerData: {
          ...action.payload.customerData,
        },
      };
    case EAppStoreActionType.SET_COUPON_LIST:
      if (action.payload.couponList === undefined) return state;
      return {
        ...state,
        couponList: action.payload.couponList,
      };
    case EAppStoreActionType.SET_FEATURES_DATA:
      if (action.payload.featuresData === undefined) return state;
      return {
        ...state,
        featuresData: action.payload.featuresData,
      };
    case EAppStoreActionType.SET_PROMOTIONS_DATA:
      if (action.payload.promotionsData === undefined) return state;
      return {
        ...state,
        promotionsData: action.payload.promotionsData,
      };
    case EAppStoreActionType.SET_BILLING_ADDRESS:
      if (action.payload.billingAddress === undefined) return state;
      return {
        ...state,
        billingAddress: action.payload.billingAddress,
      };
    case EAppStoreActionType.SET_APP_STORE_CONTENT:
      if (action.payload.content === undefined) return state;
      return {
        ...state,
        content: action.payload.content,
      };
    case EAppStoreActionType.SET_SELECTED_MENU_ITEM:
      if (action.payload.selectedMenuItem === undefined) return state;
      return {
        ...state,
        selectedMenuItem: action.payload.selectedMenuItem,
      };
    case EAppStoreActionType.SET_PAYMENT_WALL:
      if (action.payload.paymentWall === undefined) return state;
      return {
        ...state,
        paymentWall: action.payload.paymentWall,
      };
    case EAppStoreActionType.SET_SUB_FEATURES:
      return {
        ...state,
        menuSubFeatures: action.payload.menuSubFeatures,
      };
    case EAppStoreActionType.SET_COMPARISON_DATA:
      if (action.payload.comparisonData === undefined) return state;
      return {
        ...state,
        comparisonData: action.payload.comparisonData,
      };
    default:
      return state;
  }
};

export default appStore;
