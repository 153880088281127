import { FC, useContext, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { StyledRevenueRatios } from "./styled.revenue-ratios";

import { i18nKey } from "./index";
import {
  Alert,
  AlignmentEnums,
  AlertTypeEnums,
  IDialogKeyEnums,
  Button,
  ButtonVariantEnums,
  Form,
  FormContext,
  FormRow,
  Input,
  InputTypes,
  useDialogContext,
  useDialogs,
  useFormatNumber,
} from "@canei/app-components";

export interface IRevenueFormManualEdit {
  chartData: number[];
  valid: boolean;
  editedData?: number[];
  editComplete?: boolean;
}

const MONTH_RATIO_PREFIX = "MonthRatio-";

const Footer: FC = () => {
  const dialogs = useDialogs();
  const { valid } = useDialogContext<IRevenueFormManualEdit>(IDialogKeyEnums.EDIT_REVENUE_RATIOS, {
    chartData: [],
    valid: false,
  });
  const handleSavAndClose = (): void => {
    dialogs.setData<Partial<IRevenueFormManualEdit>>(IDialogKeyEnums.EDIT_REVENUE_RATIOS, {
      editComplete: true,
    });

    //
  };
  const { t } = useTranslation();
  return (
    <>
      <FormRow align={AlignmentEnums.RIGHT}>
        <Button
          onClick={handleSavAndClose}
          variant={ButtonVariantEnums.DEFAULT}
          inverted={false}
          type={"submit"}
          inline={true}
          disabled={!valid}
        >
          {t(i18nKey.enter_manually_save)}
        </Button>
      </FormRow>
    </>
  );
};
const ContentForm: FC = () => {
  const { t } = useTranslation();
  const { formatNumber } = useFormatNumber();
  const dialog = useDialogs();
  const rowCount = 6;
  const months: string[] = t("misc.months.long", { returnObjects: true });
  const formContext = useContext<Record<string, string>>(FormContext);
  const monthsPerRow = [months.slice(0, rowCount), months.slice(rowCount, 12)];
  const { chartData, valid, editComplete } = useDialogContext<IRevenueFormManualEdit>(
    IDialogKeyEnums.EDIT_REVENUE_RATIOS,
    { chartData: [], valid: false, editComplete: false }
  );
  const currentValues = useMemo(
    () => Object.entries(formContext).filter(([key]) => key.startsWith(MONTH_RATIO_PREFIX)),
    [formContext]
  );
  const totalSum = useMemo(() => {
    const result = currentValues
      .map(([, value]) => parseFloat(value))
      .reduce((prev, cur) => prev + cur, 0);

    return 100 - 0.05 < result && result < 100 + 0.05 ? 100 : result;
  }, [currentValues]);

  useEffect(() => {
    const isSum100 = parseFloat(totalSum.toString()) === 100;
    if (valid === isSum100) return;

    dialog.setData<Partial<IRevenueFormManualEdit>>(IDialogKeyEnums.EDIT_REVENUE_RATIOS, {
      valid: isSum100,
    });
  }, [valid, totalSum, dialog]);

  useEffect(() => {
    if (editComplete) {
      const editedData = currentValues.map(([, value]) => parseFloat(value));

      dialog.setData<Partial<IRevenueFormManualEdit>>(IDialogKeyEnums.EDIT_REVENUE_RATIOS, {
        editedData,
      });
    }
  }, [currentValues, dialog, editComplete]);

  return (
    <>
      {monthsPerRow.map((monthsRow, qIndex) => (
        <StyledRevenueRatios.ManualEditList key={qIndex}>
          {monthsRow.map((month, index) => {
            const monthIndex = qIndex * rowCount + index;
            const _value = chartData[monthIndex];

            if (_value === undefined) return null;

            const parsedValue = parseFloat(_value.toString()).toFixed(2);
            return (
              <StyledRevenueRatios.ManualEditItem key={index}>
                <StyledRevenueRatios.ManualEditItemMonth>
                  {month}
                </StyledRevenueRatios.ManualEditItemMonth>
                <StyledRevenueRatios.ManualEditItemRatio>
                  <Input
                    name={`${MONTH_RATIO_PREFIX}${monthIndex}`}
                    value={parsedValue}
                    type={InputTypes.NUMBER}
                    placeholder={formatNumber(0)}
                    min={0}
                    max={100}
                  />
                </StyledRevenueRatios.ManualEditItemRatio>
              </StyledRevenueRatios.ManualEditItem>
            );
          })}
        </StyledRevenueRatios.ManualEditList>
      ))}
      <FormRow align={AlignmentEnums.LEFT}>
        <Trans i18nKey={i18nKey.enter_manually_total} values={{ sum: totalSum.toFixed(2) }} />
      </FormRow>
      {!valid && (
        <FormRow align={AlignmentEnums.LEFT}>
          <Alert type={AlertTypeEnums.WARNING} closable={false}>
            <Trans
              i18nKey={i18nKey.enter_manually_exceed_error}
              values={{ sum: totalSum.toFixed(2) }}
            />
          </Alert>
        </FormRow>
      )}
    </>
  );
};

const Content: FC = () => {
  return (
    <StyledRevenueRatios.ManualEditListContainer>
      <Form>
        <ContentForm />
      </Form>
    </StyledRevenueRatios.ManualEditListContainer>
  );
};
export const ManualEdit = { Content, Footer };
