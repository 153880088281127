import { useContext, useMemo } from "react";
import { EFileUploadState, UploadersContext } from "../../index";

export const useIsMultipleUploadInProgress = (): boolean => {
  const uploadersContext = useContext(UploadersContext);
  return useMemo(
    (): boolean =>
      Object.values(uploadersContext.sharedState.fileUploadState).filter(
        ({ state }) => state === EFileUploadState.UPLOADING
      ).length > 0,
    [uploadersContext.sharedState.fileUploadState]
  );
};
