import { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  IDialogKeyEnums,
  useDialogs,
  FormRow,
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  useDialogContext,
  useFormatNumber,
  useFormatPercentage,
  Table,
  Row,
  Cell,
  IconButton,
  EFormatNumberStyle,
  EFormatCurrencyDivision,
  IconSizeEnums,
  ButtonVariantEnums,
  IconNameEnums,
  Icon,
  windowOpen,
  sumOfBalanceByGlobalCode,
  sumOfRatioByGlobalCode,
} from "@canei/app-components";
import { useTheme } from "styled-components/macro";
import { IQTiledSectionData } from "../../../containers/QTiledSection";
import { StyledDetailDialog } from "./styled.detail.dialog";
import { ActualSuSaData, ActualDetails } from "../../../@types/amplify";
import { EDashboardComponents } from "../../../@types/index.d";

export interface IDetailTableDialogData {
  data: ActualSuSaData[];
  date: string;
  comp?: EDashboardComponents;
}
export interface ListProps {
  rowsLabels: string[];
  data: ListData[];
}
export interface ListData {
  code: string;
  name: string;
  balance: number;
  ratio: number;
  details?: ActualDetails[];
  diagramColor?: string;
  url?: string;
}
export interface SubListProps {
  details?: ActualDetails[];
  code?: string;
}

const SubList: FC<SubListProps> = ({ details, code }) => {
  const { formatNumber } = useFormatNumber();

  return (
    <>
      {details &&
        details.map((item, ind) => (
          <StyledDetailDialog.DetailsRow key={"subList_" + ind}>
            <Cell level={"subSubTitle"}>
              <b>{item.account_code}  </b> {item.description}
            </Cell>
            <Cell align="right" level={"subSubTitle"} width={"20%"}>
              {formatNumber(
                code?.startsWith("BA") || code?.startsWith("BP")
                  ? item?.balance * -1
                  : item?.balance,
                {
                  maximumFractionDigits: 0,
                  style: EFormatNumberStyle.decimal,
                  divideTo: EFormatCurrencyDivision.THOUSAND,
                }
              )}
            </Cell>
            <Cell level={"subSubTitle"} width={"20%"}></Cell>
          </StyledDetailDialog.DetailsRow>
        ))}
    </>
  );
};

const List: FC<ListProps> = ({ data, rowsLabels }) => {
  const [showDetails, setShowDetails] = useState(false);

  const { formatNumber } = useFormatNumber();
  const { formatPercentage } = useFormatPercentage();

  const handleShowDetails = (e: MouseEvent<HTMLElement>): void => {
    const target = e.currentTarget as HTMLElement;
    target.classList.toggle("rotate");
    const parentTbody = target.closest("tbody") as HTMLElement;
    const nextAllTr = parentTbody.querySelectorAll("tr:not(:first-of-type)");
    nextAllTr.forEach((item) => item.classList.toggle("show"));

    setShowDetails(!showDetails);
  };

  return (
    <StyledDetailDialog.TableWrapper>
      <Table width={"100%"}>
        <thead>
          <Row>
            <Cell> </Cell>
            {rowsLabels.map((rowLabel, index) => (
              <Cell level={"upper"} key={index} align="right" width={"20%"}>
                <Trans i18nKey={rowLabel} components={{ italic: <i /> }} />
              </Cell>
            ))}
          </Row>
        </thead>
        {data.map(({ name, code, ratio, balance, details, url, ...cellProps }, index) => (
          <tbody key={"list_" + index}>
            <Row>
              <Cell {...cellProps}>
                {details && details.length > 0 && (
                  <StyledDetailDialog.DetailsIcon onClick={(e): void => handleShowDetails(e)}>
                    <Icon size={IconSizeEnums.XSMALL} name={IconNameEnums.CHEVRON_RIGHT} />
                  </StyledDetailDialog.DetailsIcon>
                )}
                {name}
                {url && (
                  <IconButton
                    icon={<Icon size={IconSizeEnums.XSMALL} name={IconNameEnums.QUESTION} />}
                    inverted={false}
                    variant={ButtonVariantEnums.DEFAULT}
                    type={"button"}
                    onClick={(): void => windowOpen({ url })}
                  />
                )}
              </Cell>

              <Cell {...cellProps} align="right" width={"20%"}>
                {formatNumber(balance, {
                  maximumFractionDigits: 0,
                  style: EFormatNumberStyle.decimal,
                  divideTo: EFormatCurrencyDivision.THOUSAND,
                })}
              </Cell>
              <Cell {...cellProps} align="right" width={"20%"}>
                {formatPercentage(ratio || 0)}
              </Cell>
            </Row>
            {details && <SubList details={details} code={code} />}
          </tbody>
        ))}
      </Table>
    </StyledDetailDialog.TableWrapper>
  );
};

export const Content: FC = () => {
  const { t } = useTranslation();
  const dialogs = useDialogs();
  const dialogContext = useDialogContext<IDetailTableDialogData | undefined>(
    IDialogKeyEnums.SIMPLE,
    undefined
  );
  const theme = useTheme();

  const { secondary, diagram } = theme.values.colors.global;
  const month = Number(dialogContext?.date.split("/")[0]) - 1;
  const year = dialogContext?.date.split("/")[1];

  const months: string[] = t("misc.months.long", { returnObjects: true });
  const tableTitles = t(`quick/common:main_content.${dialogContext?.comp}.detail_table_title`);
  const tableRowTitles = t(`quick/common:main_content.${dialogContext?.comp}.detail`, {
    returnObjects: true,
  }) as Record<string, string>;
  const pageLinks = t(`quick/knowledgebase:pages_links`, { returnObjects: true }) as Record<
    string,
    { name: string; url: string }
  >;
  // same rowLabels for both success and assets comps
  const rowLabels = [
    "quick/common:main_content.success.absolute",
    "quick/common:main_content.success.index",
  ];

  const getRowDataCallback = useCallback(
    (code: string): IQTiledSectionData => {
      const data = dialogContext?.data;

      const { balance, ratio, details } = data?.find(
        ({ global_code_id }) => global_code_id === code
      ) || {
        balance: 0,
        ratio: 0,
        details: [],
      };
      return {
        code,
        name: tableRowTitles[code],
        balance,
        ratio,
        details,
        url: pageLinks[code.toString()] && pageLinks[code.toString()].url,
      };
    },
    [dialogContext?.data, pageLinks, tableRowTitles]
  );

  const totalTaxes = useMemo(() => {
    const data = dialogContext?.data;
    return sumOfBalanceByGlobalCode(data as ActualSuSaData[], ["P15", "P16"]);
  }, [dialogContext?.data]);

  const sumRatioForP15P16 = useMemo(() => {
    const data = dialogContext?.data;
    return sumOfRatioByGlobalCode(data as ActualSuSaData[], ["P15", "P16"]);
  }, [dialogContext?.data]);

  // GUV
  const tableDataForSuccess: IQTiledSectionData[] = [
    { ...getRowDataCallback("P01"), level: "subTitle" },
    { ...getRowDataCallback("P02"), level: "subTitle" },
    { ...getRowDataCallback("P02R"), level: "title", diagramColor: secondary._100 },
    { ...getRowDataCallback("P03"), level: "subTitle" },
    { ...getRowDataCallback("P03T"), level: "title", diagramColor: secondary._100 },
    { ...getRowDataCallback("P04"), level: "subTitle" },
    { ...getRowDataCallback("P05"), level: "subTitle" },
    { ...getRowDataCallback("P05A"), level: "title", diagramColor: secondary._100 },
    { ...getRowDataCallback("P05B"), level: "upper" },
    { ...getRowDataCallback("P06"), level: "subTitle" },
    { ...getRowDataCallback("P07"), level: "subTitle" },
    { ...getRowDataCallback("P08A"), level: "subTitle" },
    { ...getRowDataCallback("P08B"), level: "subTitle" },
    { ...getRowDataCallback("P08C"), level: "subTitle" },
    { ...getRowDataCallback("P08D"), level: "subTitle" },
    { ...getRowDataCallback("P08E"), level: "subTitle" },
    { ...getRowDataCallback("P08F"), level: "subTitle" },
    { ...getRowDataCallback("P08G"), level: "subTitle" },
    { ...getRowDataCallback("P08"), level: "subTitle" },
    { ...getRowDataCallback("P08T"), level: "upper" },
    { ...getRowDataCallback("P09"), level: "subTitle" },
    { ...getRowDataCallback("P09E"), level: "upper" },
    { ...getRowDataCallback("P10"), level: "subTitle" },
    { ...getRowDataCallback("P11"), level: "subTitle" },
    { ...getRowDataCallback("P12"), level: "subTitle" },
    { ...getRowDataCallback("P12N"), level: "title", diagramColor: diagram._100 },
    { ...getRowDataCallback("P12O"), level: "upper" },
    { ...getRowDataCallback("P13"), level: "subTitle" },
    { ...getRowDataCallback("P14"), level: "subTitle" },
    { ...getRowDataCallback("P14R"), level: "title", diagramColor: diagram._100 },
    { ...getRowDataCallback("P15"), level: "subTitle" },
    {
      ...getRowDataCallback("P16"),
      balance: totalTaxes,
      ratio: sumRatioForP15P16,
      level: "subTitle",
    },
    { ...getRowDataCallback("P20N"), level: "upper" },
  ];

  // balance active-passive
  const tableDataForAssets: IQTiledSectionData[] = [
    { ...getRowDataCallback("BA02"), level: "subTitle" },
    { ...getRowDataCallback("BA03"), level: "subTitle" },
    { ...getRowDataCallback("BA03A"), level: "title", diagramColor: secondary._100 },
    { ...getRowDataCallback("BA04"), level: "subTitle" },
    { ...getRowDataCallback("BA05"), level: "subTitle" },
    { ...getRowDataCallback("BA06"), level: "subTitle" },
    { ...getRowDataCallback("BA06T"), level: "subTitle" },
    { ...getRowDataCallback("BA06A"), level: "title", diagramColor: secondary._100 },
    { ...getRowDataCallback("BA07"), level: "subTitle" },
    { ...getRowDataCallback("BA08"), level: "subTitle" },
    { ...getRowDataCallback("BA08A"), level: "title", diagramColor: secondary._100 },
    { ...getRowDataCallback("BA08B"), level: "upper" },
    { ...getRowDataCallback("BA09"), level: "subTitle" },
    { ...getRowDataCallback("BA10"), level: "subTitle" },
    { ...getRowDataCallback("BA11"), level: "subTitle" },
    { ...getRowDataCallback("BA11A"), level: "title", diagramColor: secondary._100 },
    { ...getRowDataCallback("BA12"), level: "subTitle" },
    { ...getRowDataCallback("BA13"), level: "subTitle" },
    { ...getRowDataCallback("BA14"), level: "subTitle" },
    { ...getRowDataCallback("BA15"), level: "subTitle" },
    { ...getRowDataCallback("BA16"), level: "subTitle" },
    { ...getRowDataCallback("BA16A"), level: "title", diagramColor: secondary._100 },
    { ...getRowDataCallback("BA17"), level: "subTitle" },
    { ...getRowDataCallback("BA18"), level: "subTitle" },
    { ...getRowDataCallback("BA20T"), level: "upper" },
    { ...getRowDataCallback("BP01"), level: "subTitle" },
    { ...getRowDataCallback("BP02"), level: "subTitle" },
    { ...getRowDataCallback("BP03"), level: "subTitle" },
    { ...getRowDataCallback("BP03A"), level: "upper" },
    { ...getRowDataCallback("BP04"), level: "subTitle" },
    { ...getRowDataCallback("BP05"), level: "subTitle" },
    { ...getRowDataCallback("BP06"), level: "subTitle" },
    { ...getRowDataCallback("BP07"), level: "subTitle" },
    { ...getRowDataCallback("BP08"), level: "subTitle" },
    { ...getRowDataCallback("BP09"), level: "subTitle" },
    { ...getRowDataCallback("BP10"), level: "subTitle" },
    { ...getRowDataCallback("BP10L"), level: "upper" },
    { ...getRowDataCallback("BP11"), level: "subTitle" },
    { ...getRowDataCallback("BP20T"), level: "upper" },
  ];

  return (
    <>
      <StyledDetailDialog.Wrapper>
        <StyledDetailDialog.Title>{tableTitles}</StyledDetailDialog.Title>
        <StyledDetailDialog.Text>{`per ${months[month]} ${year}`}</StyledDetailDialog.Text>
        <List
          data={
            dialogContext?.comp === EDashboardComponents.SUCCESS
              ? tableDataForSuccess
              : tableDataForAssets
          }
          rowsLabels={rowLabels}
        />
        <StyledDetailDialog.Footer>
          <FormRow align={AlignmentEnums.RIGHT}>
            <ButtonBranded
              inverted={false}
              type={"button"}
              inline={true}
              variant={ButtonBrandedVariantEnums.PRIMARY}
              onClick={(): void => {
                dialogs.close(IDialogKeyEnums.SIMPLE);
              }}
            >
              {t("misc.close")}
            </ButtonBranded>
          </FormRow>
        </StyledDetailDialog.Footer>
      </StyledDetailDialog.Wrapper>
    </>
  );
};

interface IDetailsTableDialogVal {
  open: () => void;
}

export const useDetailsTableForGuvAndBalance = (
  data: ActualSuSaData[],
  date: string,
  comp?: EDashboardComponents
): IDetailsTableDialogVal => {
  const dialogs = useDialogs();

  const open = (): void => {
    const initialData = {
      data,
      date,
      comp,
    };
    dialogs.open({
      dialogKey: IDialogKeyEnums.SIMPLE,
      closable: true,
      data: initialData,
      content: <Content />,
      flexible: false,
    });
  };
  return { open };
};
