import { FC } from "react";
import { EPublicPathName, ICardTypeEnums } from "../../../@types/index.d";
import { Card, CardBody, CardTitle, ErrorBoundary, P, FadeIn } from "@canei/app-components";

import { Link } from "react-router-dom";

import { SimpleLayout } from "../../../_lib/Layout";

interface IErrorPage {
  title: string;
  message: string;
}
export const ErrorPage: FC<IErrorPage> = ({ title, message }) => {
  return (
    <SimpleLayout>
      <ErrorBoundary>
        <FadeIn>
          <Card type={ICardTypeEnums.CENTERED}>
            <CardTitle>{title}</CardTitle>
            <CardBody>
              <P>{message}</P>
              <P>
                Go to<Link to={EPublicPathName.HOME}> Home Page</Link> and use menu to search your
                content.
              </P>
            </CardBody>
          </Card>
        </FadeIn>
      </ErrorBoundary>
    </SimpleLayout>
  );
};
