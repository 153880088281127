import React, { FC, useEffect, useState } from "react";

import {
  AlignmentEnums,
  Button,
  ButtonVariantEnums,
  FormRow,
  useDialogContext,
  useDialogs,
  P,
  IDialogKeyEnums,
} from "@canei/app-components";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";

type TUseCopyEMailTemplateDialog = () => void;
interface IEmailTmplDialogData {
  title: string;
  info: string;
  template: string;
}
export const Content: FC = () => {
  const dialogs = useDialogs();
  const data = useDialogContext<IEmailTmplDialogData>(IDialogKeyEnums.SIMPLE, {
    title: "",
    info: "",
    template: "",
  });
  const handleChangedText = (value: string): void => {
    if (data.template === value) return;
    dialogs.setData<IEmailTmplDialogData>(IDialogKeyEnums.SIMPLE, {
      ...data,
      template: value,
    });
  };
  // FIXME: Use styled Component wrapper to make text editro responsive
  return (
    <div>
      <P>{data?.info}</P>

      <ReactQuill
        theme="snow"
        value={data.template}
        bounds={`[data-text-editor="template"]`}
        style={{ display: "block", width: "640px", height: "180px" }}
        modules={{ toolbar: false }}
        onChange={handleChangedText}
      />
    </div>
  );
};
export const Footer: FC = () => {
  const data = useDialogContext<IEmailTmplDialogData | undefined>(
    IDialogKeyEnums.SIMPLE,
    undefined
  );
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyEmailTemplate = (): void => {
    const listener = (e: ClipboardEvent): void => {
      if (data?.template === undefined) return;
      if (e.clipboardData === null) return;
      e.clipboardData.setData("text/html", data?.template);

      e.preventDefault();
    };
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
    setIsCopied(true);
  };
  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      mounted && isCopied && setIsCopied(false);
    }, 500);
    return (): void => {
      mounted = false;
    };
  }, [isCopied]);
  return (
    <FormRow align={AlignmentEnums.CENTER}>
      <Button
        variant={isCopied ? ButtonVariantEnums.SECONDARY : ButtonVariantEnums.DEFAULT}
        inverted={false}
        type={"button"}
        inline
        onClick={handleCopyEmailTemplate}
      >
        {isCopied ? "Kopiert" : "Kopieren"}
      </Button>
    </FormRow>
  );
};

export const useCopyEMailTemplateDialog = (): TUseCopyEMailTemplateDialog => {
  const dialogs = useDialogs();
  const { t } = useTranslation(["quick/common", "quick/tmpl-tax-office"]);
  const data = t("quick/common:wizards.setup.company_data.email_template", {
    returnObjects: true,
  }) as Record<string, string>;
  const title = t("quick/tmpl-tax-office:title");
  const body = t("quick/tmpl-tax-office:body");
  const list = t("quick/tmpl-tax-office:list");

  return (): void => {
    dialogs.open({
      dialogKey: IDialogKeyEnums.SIMPLE,
      content: <Content />,
      footer: <Footer />,
      closable: true,
      flexible: false,
      data: {
        ...data,
        template: `${title}${body}${list}`,
      },
      title: "Vorlage für Ihren Steuerberater",
    });
  };
  //
};
