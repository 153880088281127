import { gql, QueryLazyOptions, useLazyQuery, useMutation } from "@apollo/client";

import { useSelectedClientData } from "./useSelectedClientData";
import { useEffect, useMemo, useState } from "react";
import { ITransactionStateInput, useTransactionEventListener } from "./useTransactionEventListener";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ICurrentUserAction } from "../store/reducers";
import {
  EDataSyncLevel,
  EDataSyncScope,
  ILocalState,
  UserStateActionTypes,
  EDataSyncTypes,
} from "../../@types/index.d";
import {
  IUploadedSuSa,
  EventErrorsCodes,
  useDialogs,
  IDialogKeyEnums,
} from "@canei/app-components";

interface IGetUploadsListVariables {
  client_id: string;
}
const GET_UPLOADS_LIST = gql`
  query getUploads($client_id: ID!) {
    getAllSuSasOfClient(client_id: $client_id, withPlans: true) {
      date
      format
      plans_count
      uploaded_at
      susa_id
      check {
        active_passive
        balance_cashflow
        profitloss_balance
      }
    }
  }
`;
const MUT_UPLOADS_LIST = gql`
  mutation mutUploads($client_id: ID!) {
    mutAllSuSasOfClient(client_id: $client_id, withPlans: true) {
      date
      format
      plans_count
      uploaded_at
      susa_id
      check {
        active_passive
        balance_cashflow
        profitloss_balance
      }
    }
  }
`;
const DELETE_SUSA = gql`
  query deleteSuSa($susa_id: ID!, $links: [RelationLinkInput]!, $covId: ID) {
    deleteSuSa(susa_id: $susa_id, links: $links, covId: $covId)
  }
`;
export interface IUseEditUploadListReturnValue {
  deleteInProgress: string[];
  susaDeletion: boolean;
  // deleteCompleted: string[];
  deleteUploadBySuSaId: TDeleteUploadsBySuSaId;
  listLoading?: boolean;
  getUploads: (options?: QueryLazyOptions<IGetUploadsListVariables>) => void;
}
type TDeleteUploadsBySuSaId = (susa_id: string) => void;
export const useEditUploadList = (): IUseEditUploadListReturnValue => {
  const { currentUser } = useSelector((state: ILocalState) => state);
  const [deleteSuSa, { data: deletedCovid }] = useLazyQuery(DELETE_SUSA, {
    fetchPolicy: "network-only",
  });
  const [transactionEvent, errorState, params] = useTransactionEventListener();
  const [susaIdTobeDeleted, setSusaIdTobeDeleted] = useState<string | undefined>(undefined);
  const [deleteInProgress, setDeleteInProgress] = useState<string[]>([]);
  // NEXT STATE IS TO GET THE DATA NOT ONLY FOR DELETION OF SELECTED SUSA, BUT ANY SUSA
  const [susaDeletion, setSusaDeletion] = useState<boolean>(false);
  const [isSusaToBeDeletedHasAttachedPlan, setIsSusaToBeDeletedHasAttachedPlan] =
    useState<boolean>(false);
  const [updateListAfterDelete, setUpdateListAfterDelete] = useState(false);
  const selectedClientData = useSelectedClientData();
  // const susaIdWithLiquidityPlan = currentUser?.selectedClient?.liquidity?.weekly?.susa_id;
  const dialogs = useDialogs();
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();

  const [getUploads, { data, loading: listLoading, called: listCalled }] = useLazyQuery(
    GET_UPLOADS_LIST,
    { fetchPolicy: "network-only" }
  );

  // next 3 memos are to see whether the susa (to be deleted) has any attached plan
  const susaIdWithWeeklyLiquidityPlan = useMemo(() => {
    if (!currentUser.selectedClient) return;
    if (currentUser.selectedClient.liquidity?.weekly?.susa_id)
      return currentUser.selectedClient.liquidity?.weekly?.susa_id;
  }, [currentUser.selectedClient]);

  const susaIdWithMonthlyLiquidityPlan = useMemo(() => {
    if (!currentUser.selectedClient) return;
    if (currentUser.selectedClient.liquidity?.monthly?.susa_id)
      return currentUser.selectedClient.liquidity?.monthly?.susa_id;
  }, [currentUser.selectedClient]);

  const susaIdWithProfitlossPlan = useMemo(() => {
    if (!currentUser.selectedClient) return;
    if (currentUser.selectedClient.profitloss?.susa_id)
      return currentUser.selectedClient.profitloss?.susa_id;
  }, [currentUser.selectedClient]);

  const uploadsList: IUploadedSuSa[] = data?.getAllSuSasOfClient;

  useEffect(() => {
    if (uploadsList) return;
    if (currentUser.selectedClient.uploads?.loading !== listLoading && listCalled) {
      dispatchUser({
        type: UserStateActionTypes.UPDATE_UPLOADS_LIST,
        payload: {
          selectedClient: {
            uploads: {
              loading: listLoading,
            },
          },
        },
      });
    }
  }, [currentUser.selectedClient.uploads, dispatchUser, listLoading, listCalled, uploadsList]);

  useEffect(() => {
    if (!uploadsList) return;

    dispatchUser({
      type: UserStateActionTypes.UPDATE_UPLOADS_LIST,
      payload: {
        selectedClient: {
          uploads: {
            loading: false,
            list: uploadsList,
          },
        },
      },
    });
  }, [dispatchUser, uploadsList]);

  useEffect(() => {
    if (selectedClientData === false) return;
    if (susaIdTobeDeleted) {
      setDeleteInProgress([...deleteInProgress, susaIdTobeDeleted]);
      setSusaDeletion(true);

      const selectedId =
        [
          susaIdWithWeeklyLiquidityPlan,
          susaIdWithMonthlyLiquidityPlan,
          susaIdWithProfitlossPlan,
        ].find((id) => id === susaIdTobeDeleted) !== undefined;
      setIsSusaToBeDeletedHasAttachedPlan(!!selectedId);
      deleteSuSa({
        variables: {
          susa_id: susaIdTobeDeleted,
          links: selectedClientData.links,
        },
      });
      dialogs.close(IDialogKeyEnums.WARNING);
      setSusaIdTobeDeleted(undefined);
    }
  }, [
    selectedClientData,
    dialogs,
    susaIdTobeDeleted,
    deleteSuSa,
    deleteInProgress,
    susaIdWithWeeklyLiquidityPlan,
    susaIdWithMonthlyLiquidityPlan,
    susaIdWithProfitlossPlan,
  ]);

  useEffect(() => {
    if (selectedClientData === false) return;
    const transactionId = deletedCovid?.deleteSuSa as string;
    if (!transactionId) return;
    if (transactionId === params?.transactionId) return;
    const transactionParams: ITransactionStateInput = {
      transactionId,
      accountId: selectedClientData.client_id,
      level: EDataSyncLevel.CLIENT,
    };

    if (
      currentUser?.selectedClient?.uploads?.list &&
      currentUser?.selectedClient?.uploads?.list?.length === 1
    ) {
      transactionParams.scope = EDataSyncScope.PLAN;
      transactionParams.type = EDataSyncTypes.DELETE;
    } else {
      if (isSusaToBeDeletedHasAttachedPlan) {
        transactionParams.scope = EDataSyncScope.PLAN;
        transactionParams.type = EDataSyncTypes.UPSERT;
      } else {
        transactionParams.scope = EDataSyncScope.SUSA;
        transactionParams.type = EDataSyncTypes.DELETE;
      }
    }

    setUpdateListAfterDelete(true);
    transactionEvent({
      ...transactionParams,
    });
  }, [
    deletedCovid,
    selectedClientData,
    transactionEvent,
    params,
    isSusaToBeDeletedHasAttachedPlan,
    currentUser?.selectedClient?.uploads?.list,
  ]);

  useEffect(() => {
    const transactionId = deletedCovid?.deleteSuSa as string;
    if (!transactionId || !errorState) return;
    if (
      (errorState[transactionId] === EventErrorsCodes.TRANSACTION_OK ||
        errorState[transactionId] === EventErrorsCodes.TIME_OUT) &&
      selectedClientData !== false
    ) {
      if (updateListAfterDelete) {
        setUpdateListAfterDelete(false);
        getUploads({ variables: { client_id: selectedClientData.client_id } });
        dispatchUser({
          type: UserStateActionTypes.SET_SUSA_DELETION_RUNNING,
          payload: { susaDeletionRunning: false },
        });
        setSusaDeletion(false);
      }
    }
  }, [
    errorState,
    getUploads,
    updateListAfterDelete,
    selectedClientData,
    deletedCovid?.deleteSuSa,
    dispatchUser,
  ]);

  const deleteUploadBySuSaId: TDeleteUploadsBySuSaId = (susa_id: string) => {
    if (selectedClientData === false) return;
    setSusaIdTobeDeleted(susa_id);
  };

  return {
    deleteInProgress,
    susaDeletion,
    deleteUploadBySuSaId,
    listLoading,
    getUploads,
  };
};

export interface IUseUpdateUploadsListReturnValue {
  mutUploads: (options?: QueryLazyOptions<IGetUploadsListVariables>) => void;
  mutListLoading?: boolean;
}

export const useUpdateUploadsList = (): IUseUpdateUploadsListReturnValue => {
  const { currentUser } = useSelector((state: ILocalState) => state);
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();

  const [mutUploads, { data, loading: mutListLoading }] = useMutation(MUT_UPLOADS_LIST, {
    onCompleted: (data) => {
      dispatchUser({
        type: UserStateActionTypes.SET_NEW_SUSA_UPLOADED,
        payload: {
          newSusaUploaded: true,
        },
      });
    },
    fetchPolicy: "network-only",
  });

  const uploadsList: IUploadedSuSa[] = data?.mutAllSuSasOfClient;

  useEffect(() => {
    if (uploadsList) return;
    if (currentUser.selectedClient.uploads?.loading !== mutListLoading) {
      dispatchUser({
        type: UserStateActionTypes.UPDATE_UPLOADS_LIST,
        payload: {
          selectedClient: {
            uploads: {
              loading: mutListLoading,
            },
          },
        },
      });
    }
  }, [currentUser.selectedClient.uploads, uploadsList, dispatchUser, mutListLoading]);

  useEffect(() => {
    if (!uploadsList) return;

    dispatchUser({
      type: UserStateActionTypes.UPDATE_UPLOADS_LIST,
      payload: {
        selectedClient: {
          uploads: {
            loading: false,
            list: uploadsList,
          },
        },
      },
    });
  }, [currentUser?.selectedClient?.uploads?.list?.length, dispatchUser, uploadsList]);

  return {
    mutListLoading,
    mutUploads,
  };
};
