import { useContext, useMemo } from "react";
import { EFileUploadState, UploadersContext } from "../../index";

export const useSingleUploadSucceeded = (): boolean => {
  const uploadersContext = useContext(UploadersContext);

  return useMemo((): boolean => {
    if (uploadersContext.sharedState.fileUploadState.multipleUpload) return false;
    const fileName = Object.keys(uploadersContext.sharedState.fileUploadState)[0];
    if (uploadersContext.sharedState.fileUploadState[fileName] === undefined) return false;
    return (
      uploadersContext.sharedState.fileUploadState[fileName].state === EFileUploadState.UPLOADED
    );
  }, [uploadersContext.sharedState.fileUploadState]);
};
