import { ChangeEvent, FC, useContext } from "react";

import {
  Select,
  SelectItem,
  Input,
  ISelectValue,
  SelectionTypes,
  Form,
  FormRow,
  AlignmentEnums,
  CategoryFilter,
  Panel,
  PanelBody,
  PanelTitle,
  AccountMappingType,
} from "@canei/app-components";

import { StyledClientData } from "./styled.setup-content";
import { useTranslation } from "react-i18next";
import { gql, useQuery } from "@apollo/client";

import { ClientSetupContext, ClientSetupDispatch } from "./index";

import { DraftClientMissingFields } from "../../../@types/index.d";
import { useCategoriesData } from "../../../_lib/hooks";

interface FiscalYears {
  [key: string]: string;
}

export const clientDataTestID = "ClientData";
export const fiscalYearTestID = "FiscalYear";
export const mappingTestID = "mapping";

export const ClientData: FC = () => {
  const { t } = useTranslation(["common", "datastore"]);
  const setupContext = useContext(ClientSetupContext);
  const setupDispatch = useContext(ClientSetupDispatch);
  const { data: categoriesData, loading: categoriesLoading } = useCategoriesData();
  const FiscalYears: FiscalYears = t("datastore:client.fiscal_year", {
    returnObjects: true,
  });
  const { data: accountFrameTemplates, loading: templatesLoading } = useQuery(
    gql`
      {
        getTemplates
      }
    `
  );

  const handleSetClientName = (e: ChangeEvent<HTMLInputElement>): void => {
    setupContext.name !== e.target.value && setupDispatch({ name: e.target.value });
  };

  const handleClientSelections = (e: ISelectValue<string>): void => {
    switch (e.name) {
      case "industries":
      case "sub_industries": {
        setupContext.category !== e.value &&
          setupDispatch({ category: e.value, category_type: e.name });
        break;
      }
      case "fiscal_year": {
        setupContext.fiscal_year !== e.value && setupDispatch({ fiscal_year: e.value });
        break;
      }
      case "mapping": {
        setupContext.mapping !== e.value &&
          setupDispatch({ mapping: e.value as AccountMappingType });
        break;
      }
      default:
        return;
    }
  };

  return (
    <StyledClientData data-test-id={clientDataTestID}>
      <Panel>
        <PanelTitle
          headline={t("setup.client_form.headline")}
          subline={t("setup.client_form.subline")}
          help="0"
        />
        <PanelBody>
          <Form>
            <FormRow align={AlignmentEnums.CENTER}>
              <Input
                name="name"
                value={setupContext.name}
                label={t("setup.client_form.label.company_name")}
                placeholder={t("setup.client_form.placeholder.company_name")}
                required={true}
                onChange={handleSetClientName}
                valid={!setupContext.errors.includes(DraftClientMissingFields.NAME)}
                invalidWarning={t("misc.required_field")}
              />
            </FormRow>
            <FormRow align={AlignmentEnums.STRETCH}>
              <Select
                name="category"
                text={
                  setupContext.category_type &&
                  t(`datastore:client.${setupContext.category_type}.${setupContext.category}`)
                }
                placeholder={t("setup.client_form.placeholder.branches")}
                label={t("setup.client_form.label.branches")}
                branded={false}
                type={SelectionTypes.DIALOG}
                value={setupContext.category || ""}
                onChange={handleClientSelections}
                valid={!setupContext.errors.includes(DraftClientMissingFields.CATEGORY)}
                invalidWarning={t("misc.required_field")}
              >
                <CategoryFilter
                  updater={handleClientSelections}
                  category={setupContext.category || ""}
                  categoriesData={categoriesData?.result}
                  categoriesLoading={categoriesLoading}
                  testID={clientDataTestID}
                  t={t}
                />
              </Select>
            </FormRow>
            <FormRow align={AlignmentEnums.STRETCH}>
              <Select
                name="fiscal_year"
                text={
                  setupContext.fiscal_year
                    ? t(`datastore:client.fiscal_year.${setupContext.fiscal_year}`)
                    : ""
                }
                label={t("setup.client_form.label.fiscal_year")}
                placeholder={t("setup.client_form.placeholder.fiscal_year")}
                branded={false}
                type={SelectionTypes.LIST}
                value={setupContext.fiscal_year}
                onChange={handleClientSelections}
                valid={!setupContext.errors.includes(DraftClientMissingFields.FISCAL_YEAR)}
                invalidWarning={t("misc.required_field")}
                testID={fiscalYearTestID}
              >
                {Object.keys(FiscalYears)?.map((item: string, index: number) => {
                  return (
                    <SelectItem
                      key={index}
                      value={item}
                      text={t(`datastore:client.fiscal_year.${item}`)}
                      selected={setupContext.fiscal_year === item}
                      testID={fiscalYearTestID}
                    />
                  );
                })}
              </Select>
            </FormRow>
            <FormRow align={AlignmentEnums.STRETCH}>
              <Select
                name="mapping"
                text={
                  setupContext.mapping ? t(`datastore:client.mappings.${setupContext.mapping}`) : ""
                }
                label={t("setup.client_form.label.mapping")}
                placeholder={t("setup.client_form.placeholder.mapping")}
                type={SelectionTypes.LIST}
                value={setupContext?.mapping || ""}
                onChange={handleClientSelections}
                valid={!setupContext.errors.includes(DraftClientMissingFields.MAPPING)}
                invalidWarning={t("misc.required_field")}
                testID={mappingTestID}
                disabled={templatesLoading}
              >
                {accountFrameTemplates?.getTemplates.map((item: string, index: number) => {
                  return (
                    <SelectItem
                      key={index}
                      value={item}
                      text={t(`datastore:client.mappings.${item}`)}
                      selected={setupContext.mapping === item}
                      testID={mappingTestID}
                    />
                  );
                })}
              </Select>
            </FormRow>
          </Form>
        </PanelBody>
      </Panel>
    </StyledClientData>
  );
};
