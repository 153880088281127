import { FC, useContext, useEffect } from "react";
import {
  getMontPickerData,
  IMonthPickerProps,
  MonthPicker,
  FormContext,
  toSuSaDate,
} from "@canei/app-components";

import { EUploadersActionTypes, UploadersContext, UploadersDispatch } from "../index";

export interface ISuSaDateProps {
  disabled: boolean;
  dateSelected?: boolean;
  name: string;
}
export const SingleSuSaDate: FC<IMonthPickerProps> = ({ children, ...rest }) => {
  const formContext = useContext(FormContext);

  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const { value, valid } = getMontPickerData(rest.name, formContext);
  const to = toSuSaDate();

  useEffect(() => {
    if (valid && uploadersContext.sharedState.file.date !== value) {
      uploadersDispatch({
        type: EUploadersActionTypes.SET_STATE,
        payload: { data: { dateSelected: true }, file: { date: value } },
      });
    }
  }, [uploadersContext.sharedState.file.date, uploadersDispatch, valid, value]);
  return <MonthPicker to={to} {...rest} />;
};
