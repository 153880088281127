import {
  EQuickCheckCode,
  IUploadValidationContext,
  uploadValidationSummaryDialog,
} from "../uploadValidationSummaryDialog";
import { useTranslation } from "react-i18next";
import { useDialogs, IDialogKeyEnums, parseDate } from "@canei/app-components";

type TUseUploadValidationSummary = () => IUseUploadValidationSummaryRetVal;
interface IUseUploadValidationSummaryRetVal {
  validate: (susa_id: string, date: string) => void;
}
const initialValidationState: IUploadValidationContext = {
  susa_id: "",
  date: "",
  loading: true,
  check: {
    profitloss_balance: EQuickCheckCode.INITIAL,
    active_passive: EQuickCheckCode.INITIAL,
    balance_cashflow: EQuickCheckCode.INITIAL,
  },
};
export const useUploadValidationSummary: TUseUploadValidationSummary = () => {
  const { setData, open } = useDialogs();
  const { t } = useTranslation();
  const months = t("misc.months.long", { returnObjects: true });
  return {
    validate: (susa_id: string, date: string): void => {
      const { month, year } = parseDate(date);
      const title = t("upload.summary.title", { month: months[month], year });
      open({ ...uploadValidationSummaryDialog, title });
      setData(IDialogKeyEnums.BASIC, { ...initialValidationState, susa_id, date });
    },
  };
};
