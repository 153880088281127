import { IProfitAndLossChartProps } from "@canei/app-components";

export const profitAndLoss: IProfitAndLossChartProps = {
  optimisticData: Array(24).fill({ label: "......... ....", actual: null, plan: null }),
  labels: {
    y: "    ",
    actual: "    ",
    plan: "    ",
  },
};
