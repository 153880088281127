import { FC } from "react";
import { useSelector } from "react-redux";
import { ILocalState } from "../../../@types";
import { EAppStoreMenuItem } from "../useAppStore";
import { Trans } from "react-i18next";
import { useAppStoreHelper } from "../useAppStoreHelper";
import { Icon, IconNameEnums, IconSizeEnums } from "@canei/app-components";
import { StyledAppStoreMenu } from "./styled.appStore-menu";

interface IAppStoreMenuItemProps {
  contentItem: EAppStoreMenuItem;
  // handleClick: () => void;
  showSubFeatures: boolean;
}

export const AppStoreMenuItem: FC<IAppStoreMenuItemProps> = ({
  contentItem,
  // handleClick,
  showSubFeatures,
}) => {
  const { content, selectedMenuItem } = useSelector((state: ILocalState) => state.appStore);
  const { handleSelected, isOneTimePurchase } = useAppStoreHelper();
  if (!content || content[contentItem].disabled) return null;

  return (
    <StyledAppStoreMenu.MenuItem
      selected={contentItem === selectedMenuItem}
      subFeature={
        content[contentItem as EAppStoreMenuItem].feature_id &&
        content[contentItem as EAppStoreMenuItem].sub_feature
      }
      // onClick={handleClick}
      showSubFeatures={showSubFeatures}
    >
      <StyledAppStoreMenu.MenuItemLabel onClick={handleSelected} id={contentItem}>
        <Trans components={{ sup: <sup /> }}>
          {content[contentItem as EAppStoreMenuItem].title}
        </Trans>
        {isOneTimePurchase(EAppStoreMenuItem.CIRCYOULAR) &&
          content[contentItem as EAppStoreMenuItem].feature_id === EAppStoreMenuItem.CIRCYOULAR && (
            <Icon name={IconNameEnums.BUBBLE_WITH_NUMBER} size={IconSizeEnums.SMALL} />
          )}
      </StyledAppStoreMenu.MenuItemLabel>
    </StyledAppStoreMenu.MenuItem>
  );
};
