import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useTransactionEventListener } from "./useTransactionEventListener";
import { EDataSyncLevel, EDataSyncTypes, ILocalState } from "../../@types/index.d";

import { useRelationLinks } from "./useRelationLinks";

import {
  RelationLink,
  localCache,
  LocalCacheEnums,
  EventErrorsCodes,
  FileUploadFormat,
  FileData,
} from "@canei/app-components";

import { useSelector } from "react-redux";

const MUT_LOGO_UPLOAD = gql`
  mutation uploadLogo($file: FileInput!) {
    result: uploadClientLogo(file: $file) {
      covid
      guid
    }
  }
`;
const MUT_LOGO_DELETE = gql`
  mutation deleteLogo($id: ID!, $links: [RelationLinkInput]!) {
    deleteClientLogo(id: $id, links: $links) {
      guid
      covid
    }
  }
`;
interface ILogoFileInput {
  client_id: string;
  links: RelationLink[];
  content: string;
  lastModified: string;
  name: string;
  size: number;
  type: string;
  date: string;
}
interface ILogoUploadVars {
  file: ILogoFileInput;
}
interface ILogoUploadData {
  result: { covid: string; guid: string };
}
type TUseLogoUpload = () => {
  upload: (file: FileData, onComplete?: (data: ILogoUploadData) => void) => void;
  mutationResult: MutationResult<ILogoUploadData>;
  errorState?: Record<string, EventErrorsCodes>;
};
export const useLogoUpload: TUseLogoUpload = () => {
  const [upload, mutationResult] = useMutation<ILogoUploadData, ILogoUploadVars>(MUT_LOGO_UPLOAD);
  const [transactionEvent, errorState] = useTransactionEventListener();

  const { currentUser } = useSelector((state: ILocalState) => state);
  const { client_id } = currentUser.selectedClient;
  const { relationLinks } = useRelationLinks();
  return {
    upload: (file, onComplete): void => {
      if (!client_id) return;
      const fileFormat: { [key: string]: FileUploadFormat } = {
        "image/jpeg": FileUploadFormat.JPG,
        "image/png": FileUploadFormat.PNG,
        "image/svg+xml": FileUploadFormat.SVG,
      };
      upload({
        variables: {
          file: {
            ...file,
            format: fileFormat[file.type],
            date: Date.now().toString(),
            links: relationLinks,
            client_id: client_id,
          } as ILogoFileInput,
        },
      })
        .then(({ data }) => {
          const transactionId = data?.result.covid;
          if (!transactionId) return;
          transactionEvent({
            accountId: client_id,
            transactionId,
            level: EDataSyncLevel.CLIENT,
            type: EDataSyncTypes.UPSERT,
          });
          if (onComplete && data) onComplete(data);
        })
        .catch(() => {
          // Upload error
        });
    },
    mutationResult,
    errorState,
  };
};

type TUseLogoDelete = () => {
  removeLogo: (
    hash: string,
    onComplete?: (data: FetchResult<Record<string, string>>) => void
  ) => void;

  mutationResult: MutationResult;
};
export const useLogoDelete: TUseLogoDelete = () => {
  const { relationLinks } = useRelationLinks();
  const { currentUser } = useSelector((state: ILocalState) => state);
  const { client_id } = currentUser.selectedClient;
  const [deleteLogo, mutationResult] = useMutation(MUT_LOGO_DELETE);

  return {
    removeLogo: (hash, onComplete): void => {
      if (!client_id) return;
      if (hash) {
        localCache.removeItem(LocalCacheEnums.CLIENT_LOGOS, { logo_hash: hash });
      }
      deleteLogo({
        variables: {
          id: client_id,
          links: relationLinks,
        },
      }).then(({ data }) => {
        if (onComplete) onComplete(data);
      });
    },
    mutationResult,
  };
};
