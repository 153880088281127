import { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { PreviewTable } from "./PreviewTable";
import { PreviewVariations } from "./PreviewVariations";
import { UploadReviewedFile } from "./UploadReviewedFile";

import { useSelectedClientData } from "../../../../_lib/hooks";
import {
  AlignmentEnums,
  AlertTypeEnums,
  FormRow,
  Alert,
  LoaderTypeEnums,
  LoadingIndicator,
  Cell,
  Row,
  Table,
  FileUploadVariant,
} from "@canei/app-components";

import {
  EPreviewErrorCodes,
  EUploadersActionTypes,
  UploadersContext,
  UploadersDispatch,
} from "../../index";

const PreviewLoading: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Alert type={AlertTypeEnums.INFO}>{t("setup.upload_form_2.excel.preview_loading")}</Alert>
      <Table width={"100%"}>
        <tbody>
          {["", "", "", "", "", "", ""].map((_, row) => (
            <Row key={row}>
              {["", "", "", "", "", "", ""].map((__, cell) => (
                <Cell key={cell}>
                  <LoadingIndicator type={LoaderTypeEnums.TEXT} length={10} />
                </Cell>
              ))}
            </Row>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export const FilePreview: FC = () => {
  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const { t } = useTranslation(["common", "errors"]);
  const { tableData, previewLoading } = uploadersContext.sharedState;
  const selectedClientData = useSelectedClientData();
  const isVariantTypeSelected =
    uploadersContext.sharedState.settings.variant_type !== FileUploadVariant.UNKNOWN;

  useEffect(() => {
    if (selectedClientData === false) return;
    const savedSettings = selectedClientData.customization.upload_settings;
    if (
      isVariantTypeSelected &&
      savedSettings?.mapping &&
      !uploadersContext.sharedState.settings.mapping
    ) {
      uploadersDispatch({
        type: EUploadersActionTypes.SET_STATE,
        payload: {
          settings: {
            mapping: {
              ...savedSettings.mapping,
            },
          },
        },
      });
    }
  }, [
    selectedClientData,
    uploadersContext.sharedState.settings.mapping,
    uploadersContext.sharedState.settings.variant_type,
    isVariantTypeSelected,
    uploadersDispatch,
  ]);
  if (uploadersContext.sharedState.previewErrorCode !== EPreviewErrorCodes.OK) {
    return (
      <FormRow align={AlignmentEnums.STRETCH}>
        <Alert type={AlertTypeEnums.ERROR}>
          {t(`errors:${uploadersContext.sharedState.previewErrorCode}`)}
        </Alert>
      </FormRow>
    );
  }
  return previewLoading ? (
    <PreviewLoading />
  ) : tableData ? (
    <>
      <PreviewTable canSelect={false} />
      <PreviewVariations />
      {isVariantTypeSelected && <PreviewTable canSelect={true} />}

      {uploadersContext.sharedState.isReady && <UploadReviewedFile />}
    </>
  ) : (
    <></>
  );
};
