import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";

import {
  AlertTypeEnums,
  EUpdateCacheType,
  ICopyPlan,
  ICopyPlanVariables,
  ICreatePlan,
  ICreatePlanVariables,
  IDeletePlan,
  IDeletePlanVariables,
  IEditPlan,
  IEditPlanVariables,
  IPlan,
  IPlanData,
  IPlanDataVariables,
  IPlanEntryItem,
  IPlanInput,
  IUpdateCacheAfter,
  IUpdateCacheBefore,
  EAutoCloseTimeOut,
} from "@canei/app-components";

import { ILocalState } from "../../../@types";
import { useCallback, useEffect } from "react";
import {
  COPY_PLAN,
  CREATE_PLAN,
  DELETE_PLAN,
  EDIT_PLAN,
  GET_PLANS_OF_CLIENT,
} from "./plan-queries";
import { useSelector } from "react-redux";
import { useSystemMessages } from "../useSystemMessages";
import { Trans } from "react-i18next";

export type ICopyFromPlan = Pick<IPlan, "active" | "title" | "description" | "plan_id">;
interface IUsePlansReturnValue {
  plans: IPlanEntryItem[] | undefined;
  plansLoading: boolean;
  create: (plan: IPlanInput) => Promise<IPlan | undefined>;
  duplicate: (plan: ICopyFromPlan) => Promise<boolean | void>;
  edit: (plan: ICopyFromPlan) => Promise<boolean | void>;
  remove: (plan_id: string) => Promise<boolean | void>;
  activate: (plan_id: string) => void;
}

type TUsePlans = () => IUsePlansReturnValue;
/**
 * CRUD Hook for selected Client
 */
export const usePlans: TUsePlans = () => {
  const apolloClient = useApolloClient();
  const { addSystemMessage } = useSystemMessages();

  const errorMessageCallback = useCallback(
    (err) => {
      addSystemMessage({
        type: AlertTypeEnums.ERROR,
        autoCloseTimeout: EAutoCloseTimeOut.MEDIUM,
        identifier: err.code,
        code: err.message,
        closable: false,
        Message: <Trans i18nKey={err.code} />,
      });
    },
    [addSystemMessage]
  );
  const [getPlansOfClient, { data, called, loading: plansLoading, variables }] = useLazyQuery<
    IPlanData,
    IPlanDataVariables
  >(GET_PLANS_OF_CLIENT);
  const { currentUser } = useSelector((state: ILocalState) => state);
  const [createPlan] = useMutation<ICreatePlan, ICreatePlanVariables>(CREATE_PLAN, {
    onError: errorMessageCallback,
  });
  const [copyPlan] = useMutation<ICopyPlan, ICopyPlanVariables>(COPY_PLAN, {
    onError: errorMessageCallback,
  });
  const [editPlan] = useMutation<IEditPlan, IEditPlanVariables>(EDIT_PLAN, {
    onError: errorMessageCallback,
  });
  const [removePlan] = useMutation<IDeletePlan, IDeletePlanVariables>(DELETE_PLAN, {
    onError: errorMessageCallback,
  });

  const clientId = currentUser.selectedClient.client_id;

  useEffect(() => {
    if (clientId === null) return;
    if (variables?.params.client_id && variables.params.client_id !== clientId) {
      getPlansOfClient({ variables: { params: { client_id: clientId } } });
    }
  }, [getPlansOfClient, clientId, variables?.params.client_id]);
  useEffect(() => {
    if (!called && clientId !== null && data === undefined) {
      getPlansOfClient({ variables: { params: { client_id: clientId } } });
    }
  }, [called, clientId, data, getPlansOfClient]);

  const create = (plan: IPlanInput): Promise<IPlan | undefined> => {
    return createPlan({ variables: { plan } }).then(({ data }) => {
      const plan = data?.createPlan;
      if (plan && plan.plan_id) {
        updateCacheAfter({ type: EUpdateCacheType.CREATE, plan_id: plan.plan_id, new_data: plan });
      }
      return plan;
    });
  };

  const updateCacheBefore = ({ type, plan_id, new_data }: IUpdateCacheBefore): void => {
    const tmpPlanId = `${type}_${plan_id}`;
    apolloClient.cache.modify({
      id: "ROOT_QUERY",
      fields: {
        getPlans(cachedPlan: IPlan[]): IPlan[] {
          switch (type) {
            case EUpdateCacheType.DUPLICATE:
              const tmpPlan = cachedPlan.find((master) => master.plan_id === plan_id);
              if (!tmpPlan) return cachedPlan;
              return new_data?.active
                ? [
                    ...cachedPlan.map((item) => ({ ...tmpPlan, ...item, active: false })),
                    { ...tmpPlan, ...new_data, plan_id: tmpPlanId },
                  ]
                : [...cachedPlan, { ...tmpPlan, ...new_data, plan_id: tmpPlanId }];
            case EUpdateCacheType.EDIT:
              return cachedPlan.map((item) =>
                item.plan_id === plan_id ? { ...item, plan_id: tmpPlanId, ...new_data } : item
              );
            case EUpdateCacheType.REMOVE:
              return cachedPlan.filter((item) => item.plan_id !== plan_id);
            case EUpdateCacheType.ACTIVATE:
              return cachedPlan.map((item) =>
                item.plan_id === plan_id
                  ? { ...item, plan_id: tmpPlanId, ...new_data }
                  : { ...item, active: false }
              );
            default:
              return cachedPlan;
          }
        },
      },
      broadcast: true,
      optimistic: true,
    });
  };
  const updateCacheAfter = ({ plan_id, type, new_data }: IUpdateCacheAfter): boolean | void => {
    const tmpPlanId = `${type}_${plan_id}`;
    if (new_data === null || new_data === undefined) return;
    return apolloClient.cache.modify({
      id: "ROOT_QUERY",
      fields: {
        getPlans(cachedPlan: IPlan[]): IPlan[] {
          switch (type) {
            case EUpdateCacheType.REMOVE:
              return cachedPlan.filter((p) => p.plan_id !== tmpPlanId);
            case EUpdateCacheType.CREATE:
            case EUpdateCacheType.DUPLICATE:
              return [
                ...cachedPlan.map((item) =>
                  item.plan_id?.startsWith(type)
                    ? { ...item, ...new_data }
                    : {
                        ...item,
                        active: new_data.active ? false : item.active,
                      }
                ),
              ];

            case EUpdateCacheType.EDIT:
              return cachedPlan.map((p) =>
                p.plan_id === tmpPlanId
                  ? new_data
                  : { ...p, active: new_data.active ? false : p.active }
              );
            case EUpdateCacheType.ACTIVATE:
              return cachedPlan.map((p) => {
                return p.plan_id === tmpPlanId ? new_data : { ...p, active: false };
              });
            default:
              return cachedPlan.map((p) => (p.plan_id === tmpPlanId ? new_data : p));
          }
        },
      },
      broadcast: true,
      optimistic: true,
    });
  };
  const duplicate = ({ plan_id, ...plan }: ICopyFromPlan): Promise<boolean | void> => {
    if (!plan_id) throw new Error("plan_id must be defined");

    updateCacheBefore({ type: EUpdateCacheType.DUPLICATE, plan_id, new_data: plan });
    return copyPlan({
      variables: { plan, plan_id },
    }).then(({ data: duplicatedData }) => {
      if (!duplicatedData) return;

      return updateCacheAfter({
        type: EUpdateCacheType.DUPLICATE,
        plan_id,
        new_data: duplicatedData.copyPlan,
      });
    });
  };
  const edit = ({ plan_id, ...plan }: ICopyFromPlan): Promise<boolean | void> => {
    if (!plan_id) return Promise.reject("plan_id must be defined");

    updateCacheBefore({ type: EUpdateCacheType.EDIT, plan_id, new_data: plan });

    return editPlan({
      variables: { plan, plan_id },
    }).then(({ data: editedData }) => {
      if (!editedData) return;
      updateCacheAfter({ type: EUpdateCacheType.EDIT, plan_id, new_data: editedData.editPlan });
    });
  };
  const remove = (plan_id: string): Promise<boolean | void> => {
    updateCacheBefore({ type: EUpdateCacheType.REMOVE, plan_id });
    return removePlan({ variables: { plan_id } }).then(() => {
      updateCacheAfter({ type: EUpdateCacheType.REMOVE, plan_id });
    });
  };
  const activate = (plan_id: string): void => {
    if (!plan_id) throw new Error("plan_id must be defined");
    if (data?.getPlans === undefined) throw new Error("plans can not be undefined");
    const selectedPlan = data.getPlans.find((item) => item.plan_id === plan_id);
    if (selectedPlan === undefined) throw new Error("selectedPlan can not be undefined");
    const { title, description } = selectedPlan;
    updateCacheBefore({ type: EUpdateCacheType.ACTIVATE, plan_id });
    editPlan({
      variables: {
        plan_id,
        plan: {
          active: true,
          title,
          description,
        },
      },
    }).then(({ data: activeData }) => {
      updateCacheAfter({
        type: EUpdateCacheType.ACTIVATE,
        plan_id,
        new_data: activeData?.editPlan,
      });
    });
  };

  return {
    plans: data?.getPlans,
    plansLoading: data === undefined || plansLoading,
    create,
    duplicate,
    edit,
    remove,
    activate,
  };
};
