import styled, { css, keyframes } from "styled-components/macro";
import { CellProps, TableProps } from "..";

const appearIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const StyledTable = styled.table<TableProps>(({ theme, width, fontSize }) => {
  return css`
    width: ${width ? width : "unset"};
    border-spacing: 0;
    font-size: ${theme.rem(fontSize ? fontSize : 1)};
    margin-bottom: ${theme.rem(1)};
    empty-cells: show;
  `;
});

export const THead = styled.thead(({ theme }) => {
  return css``;
});

export const TFoot = styled.tfoot(({ theme }) => {
  return css``;
});

export const TBody = styled.tbody(({ theme }) => {
  return css``;
});

export const TR = styled.tr(({ theme }) => {
  return css``;
});

export const TH = styled.th<CellProps>(
  ({
    theme,
    alignText,
    alignH,
    alignV,
    highlight,
    level,
    width = "auto",
    height,
    animate = true,
    hasBorder = true,
  }) => {
    const titleCss = css`
      font-weight: ${theme.values.fontWeight.bold};
    `;
    const upperCss = css`
      text-transform: uppercase;
    `;
    const subTitleCss = css`
      padding-left: ${theme.rem(1.5)};
      position: relative;
      z-index: 1;
    `;
    const subSubTitleCss = css`
      padding-left: ${theme.rem(3)};
      font-size: ${theme.rem(0.8)};
      position: relative;
      z-index: 1;
    `;
    const animationCss = css`
      opacity: 0;
      animation: ${appearIn} 300ms forwards;
      animation-delay: 300ms;
    `;
    return css`
      display: flex;
      text-align: ${alignText || "left"};
      flex-direction: column;
      justify-content: ${alignH || "left"};
      align-items: ${alignV || "center"};
      background-color: ${highlight
        ? theme.values.colors.global.greys.second
        : theme.values.colors.global.greys.first};
      height: ${height ? theme.rem(height) : "auto"};
      width: ${width};
      padding: ${theme.rem(0.5)};
      border-bottom: 1px solid;
      border-bottom-color: ${hasBorder ? theme.values.colors.global.greys._025 : "transparent"};

      ${animate && animationCss}
      ${(level === "title" || level === "upper") && titleCss}
      ${level === "upper" && upperCss}
      ${level === "subTitle" && subTitleCss}
      ${level === "subSubTitle" && subSubTitleCss}

      &:hover {
        z-index: 2;
      }
    `;
  }
);

export const TD = styled.td<CellProps>(
  ({
    theme,
    alignText,
    alignH,
    alignV,
    highlight,
    level,
    width = "auto",
    height,
    animate = true,
    hasBorder = true,
  }) => {
    const titleCss = css`
      font-weight: ${theme.values.fontWeight.bold};
    `;
    const upperCss = css`
      text-transform: uppercase;
    `;
    const subTitleCss = css`
      padding-left: ${theme.rem(1.5)};
      position: relative;
      z-index: 1;
    `;
    const subSubTitleCss = css`
      padding-left: ${theme.rem(3)};
      font-size: ${theme.rem(0.8)};
      position: relative;
      z-index: 1;
    `;
    const animationCss = css`
      opacity: 0;
      animation: ${appearIn} 300ms forwards;
      animation-delay: 300ms;
    `;
    return css`
      display: flex;
      text-align: ${alignText || "left"};
      justify-content: ${alignH || "left"};
      align-items: ${alignV || "center"};
      background-color: ${highlight
        ? theme.values.colors.global.greys.second
        : theme.values.colors.global.greys.first};
      height: ${height ? theme.rem(height) : "auto"};
      width: ${width};
      padding: ${theme.rem(0.5)};
      border-bottom: 1px solid;
      border-bottom-color: ${hasBorder ? theme.values.colors.global.greys._025 : "transparent"};

      ${animate && animationCss}
      ${(level === "title" || level === "upper") && titleCss}
      ${level === "upper" && upperCss}
      ${level === "subTitle" && subTitleCss}
      ${level === "subSubTitle" && subSubTitleCss}

      &:hover {
        z-index: 2;
      }
    `;
  }
);
