import { IThemeOptions, ThemeStateActionTypes } from "../../../@types/index.d";
import initialData from "../initialState";

export interface IThemeOptionsAction {
  type: ThemeStateActionTypes;
  payload: Partial<IThemeOptions>;
}
const themeOptions = (
  state: IThemeOptions = initialData.themeOptions,
  action: IThemeOptionsAction
): IThemeOptions => {
  switch (action.type) {
    case ThemeStateActionTypes.SET_THEME:
      return {
        ...state,
        ...action.payload,
      };
    case ThemeStateActionTypes.SET_LAYOUT:
      if (action.payload.layout === undefined) return state;
      return {
        ...state,
        layout: action.payload.layout,
      };
    case ThemeStateActionTypes.SIDENAV_EXPAND:
      return {
        ...state,
        sideNavExpanded: true,
      };
    case ThemeStateActionTypes.SIDENAV_COLLAPSE:
      return {
        ...state,
        sideNavExpanded: false,
      };
    case ThemeStateActionTypes.CLEAR_STORE:
      return initialData.themeOptions;
    default:
      return state;
  }
};

export default themeOptions;
