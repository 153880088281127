import { IRenderRules } from "../../../@types";
import initialState from "../initialState";

export interface IRenderRulesAction {
  type: ERenderRulesActionType;
  payload: IRenderRulePayload;
}
export interface IRenderRulePayload {
  renderRules?: IRenderRules;
  runLoadingSpinner?: boolean;
}

export enum ERenderRulesActionType {
  SET_RENDER_RULES = "SET_RENDER_RULES",
  SET_LOADING_SPINNER = "SET_LOADING_SPINNER",
}

const renderRules = (
  state = initialState.renderRules,
  action: IRenderRulesAction
): IRenderRules => {
  switch (action.type) {
    case ERenderRulesActionType.SET_RENDER_RULES:
      if (action.payload.renderRules === undefined) return state;
      if (state === undefined) return state;
      return {
        ...state,
        dashboardComponents: {
          ...state.dashboardComponents,
          ...action.payload.renderRules.dashboardComponents,
        },
        dashboardGrid: action.payload.renderRules.dashboardGrid,
        menuItems: {
          ...state.menuItems,
          ...action.payload.renderRules.menuItems,
        },
        reports: {
          ...state.reports,
          ...action.payload.renderRules.reports,
        },
        userMenuItems: {
          ...state.userMenuItems,
          ...action.payload.renderRules.userMenuItems,
        },
        logo: action.payload.renderRules.logo,
      };
    case ERenderRulesActionType.SET_LOADING_SPINNER:
      if (action.payload.runLoadingSpinner === undefined) return state;
      if (state === undefined) return state;
      return {
        ...state,
        runLoadingSpinner: action.payload.runLoadingSpinner,
      };

    default:
      return state;
  }
};

export default renderRules;
