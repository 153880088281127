import { gql, QueryResult, useQuery } from "@apollo/client";

const KPI_REF = gql`
  {
    getKpiReferencesTree {
      value
      tree {
        value
      }
    }
    getKpiGroupsTree {
      value
      tree {
        value
      }
    }
  }
`;
const optimisticItemsTree = new Array(6).fill({
  value: "",
});
const optimisticGroupTree = new Array(6).fill({
  value: "",
  tree: optimisticItemsTree,
});
export interface IKPIRefsAndGroups {
  value: string;
  tree?: [IKPIRefsAndGroups];
}
export interface IUseKpiRefsAndGroupsReturnValue extends QueryResult {
  references: IKPIRefsAndGroups[];
  optimisticReferences: IKPIRefsAndGroups[];
  groups: IKPIRefsAndGroups[];
  optimisticGroups: IKPIRefsAndGroups[];
  ready: boolean;
}
export const useKpiRefsAndGroups = (): IUseKpiRefsAndGroupsReturnValue => {
  const queryResult = useQuery(KPI_REF);
  return {
    ...queryResult,
    references: queryResult?.data?.getKpiReferencesTree || [],
    groups: queryResult?.data?.getKpiGroupsTree || [],
    optimisticReferences: optimisticItemsTree,
    optimisticGroups: optimisticGroupTree,
    ready: !!queryResult && !!queryResult.data,
  };
};
