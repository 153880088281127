import { useApolloClient } from "@apollo/client";
import { IDialogKeyEnums, useDialogs } from "@canei/app-components";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { EProductEnums, ILocalState } from "../../../@types/index.d";
import { GET_RENDER_RULES } from "../../../_lib/hooks/appStore/appStore-queries";
import { IGetRenderRules } from "../../../_lib/hooks/appStore/appStoreTypes";
import { EAppStoreActionType, IAppStoreAction } from "../../../_lib/store/reducers";
import { ComparisonTable } from "./ComparisonTable";
import { StyledComparison } from "./styled.comparison-table";

export enum EProductLevelEnums {
  PROTECT = "PROTECT",
  // LITE = "LITE",
  CONTROL = "CONTROL",
  PRO = "PRO",
}

export const getProductIdFromEProductEnums = (product: EProductEnums): string => {
  switch (product) {
    case EProductEnums.PROTECT:
      return "canei_quick";
    case EProductEnums.LITE:
      return "canei_quick_lite";
    case EProductEnums.CONTROL:
      return "canei_quick_plus";
    case EProductEnums.PRO:
      return "canei_pro";
    default:
      return "";
  }
};

export const ComparisonContent: FC = () => {
  const dispatchAppStore = useDispatch<Dispatch<IAppStoreAction>>();
  const { comparisonData } = useSelector((state: ILocalState) => state.appStore);
  const apolloClient = useApolloClient();

  // number of tables to render
  const numColumns = Object.keys(EProductLevelEnums).length + 1;

  // calculate the width of the main wrapper
  const width = `${100 / numColumns}%`;

  // check if comparisonData exists, if not grab it from the server and save it to redux
  useEffect(() => {
    // if data already exists return
    if (comparisonData && comparisonData.length) return;

    // get ids for comparison call by filtering EProductEnums with EProductLevelEnums
    const ids = Object.values(EProductEnums)
      .filter((product) => Object.keys(EProductLevelEnums).includes(product))
      .map((product) => getProductIdFromEProductEnums(product as EProductEnums));

    // call app backend
    apolloClient
      .query<IGetRenderRules, { ids: string[]; comparison: boolean }>({
        query: GET_RENDER_RULES,
        variables: {
          ids,
          comparison: true,
        },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        if (data && data.renderRules) {
          // dispatch the data to redux
          dispatchAppStore({
            type: EAppStoreActionType.SET_COMPARISON_DATA,
            payload: { comparisonData: data.renderRules },
          });
        }
      });
  }, [apolloClient, comparisonData, dispatchAppStore]);

  return (
    <StyledComparison.Wrapper numColumns={numColumns}>
      {/* render the table with the list (product: boolean is the trigger for styling of product tables) */}
      <StyledComparison.TableWrapper product={false} width={width}>
        <ComparisonTable width={"90%"} />
      </StyledComparison.TableWrapper>

      {/* render one table for each entry in EProductLevelEnums */}
      {Object.values(EProductEnums)
        .filter((product) => Object.keys(EProductLevelEnums).includes(product))
        .map((product) => (
          <StyledComparison.TableWrapper product={true} width={width} key={Math.random()}>
            <ComparisonTable
              logo={product}
              productId={getProductIdFromEProductEnums(product)}
              width={"90%"}
              key={Math.random()}
            />
          </StyledComparison.TableWrapper>
        ))}
    </StyledComparison.Wrapper>
  );
};

interface IUseComparisonRetVal {
  open: () => void;
}

export const useComparison = (): IUseComparisonRetVal => {
  const dialogs = useDialogs();

  return {
    open: (): void => {
      dialogs.open({
        dialogKey: IDialogKeyEnums.BASIC,
        closable: true,
        content: <ComparisonContent />,
        flexible: false,
      });
    },
  };
};
