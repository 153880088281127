import { createGlobalStyle, css } from "styled-components/macro";
import { LayoutEnums } from "../../@types/index.d";

const GlobalStyle = createGlobalStyle(({ theme }) => {
  const themedBodyCss = {
    [LayoutEnums.SIMPLE]: css`
      background: ${theme.values.colors.global.secondary._100};
    `,
    [LayoutEnums.COMMON]: css`
      background: ${theme.values.colors.global.secondary._100};
    `,
    [LayoutEnums.QUICK]: css`
      background: ${theme.values.colors.global.greys._000};
    `,
  };
  return css`
    html,
    body {
      font-family: ${theme.values.fontFamily.copy.primary};
      font-size: ${theme.rem(1)};
      line-height: 1.2;
      font-weight: ${theme.values.fontWeight.normal};
      margin: 0;
      padding: 0;
      text-decoration: ${theme.layout === LayoutEnums.SIMPLE ? "true" : "false"};
      color: ${theme.values.colors.global.secondary._100};
    }

    body {
      ${themedBodyCss[theme.layout]}
    }
    a {
      color: ${theme.values.colors.global.primary._100};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    button,
    button[type="button"],
    button[type="submit"],
    button[type="reset"],
    textarea,
    input,
    input[type="text"],
    input[type="mail"],
    input[type="password"],
    input[type="tel"],
    input[type="checkbox"],
    input[type="radio"],
    input[type="file"],
    input[type="date"],
    input[type="month"] {
      appearance: none;
      font-family: inherit;

      &:focus,
      &:active {
        outline: none;
        appearance: none;
      }
      &::placeholder {
        font-style: italic;
      }
    }
    p {
      line-height: 1.2;
    }
    video {
      display: block;
      max-width: 100%;
    }
  `;
});

export default GlobalStyle;
