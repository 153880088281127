import { gql, useLazyQuery } from "@apollo/client";
import { clientDataQuery } from "./";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ICurrentUserAction } from "../store/reducers";
import { UserStateActionTypes } from "../../@types/index.d";
import { Client } from "@canei/app-components";

const GET_CLIENT_DATA_BY_ID = gql`
  query getClientData($id: ID!) {
    getClient(id: $id) {
      ${clientDataQuery}
    }
  }
`;
type TUseUpdateSelectedClient = () => TUseUpdateSelectedClientReturnValue;
type TUseUpdateSelectedClientReturnValue = (client_id: string, timeStamp?: number) => void;
export const useUpdateSelectedClient: TUseUpdateSelectedClient = () => {
  const [getClientData, { data, loading, called, refetch }] = useLazyQuery(GET_CLIENT_DATA_BY_ID, {
    fetchPolicy: "network-only",
  });
  const [clientId, setClientId] = useState<string | null>(null);
  const [queryTimeStamp, setQueryTimeStamp] = useState<number>(0);
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();

  const newClientData = data?.getClient as Client;
  useEffect(() => {
    if (clientId === null) return;
    if (loading) return;
    if (called) {
      if (queryTimeStamp > 0) {
        refetch && refetch();
      }
    } else {
      getClientData({ variables: { id: clientId } });
    }
  }, [called, clientId, getClientData, loading, queryTimeStamp, refetch]);

  useEffect(() => {
    if (newClientData) {
      dispatchUser({
        type: UserStateActionTypes.UPDATE_SELECTED_CLIENT_DATA,
        payload: {
          clients: [newClientData],
        },
      });
    }
  }, [newClientData, dispatchUser]);

  return (client_id: string, timeStamp?: number): void => {
    clientId !== client_id && setClientId(client_id);
    timeStamp && timeStamp !== queryTimeStamp && setQueryTimeStamp(timeStamp);
  };
};
