import { AuthEnums, UserStateActionTypes } from "../../@types/index.d";
import { Auth } from "aws-amplify";
import isEmail from "validator/lib/isEmail";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ICurrentUserAction } from "../store/reducers/currentUser";
export type IForgotParams = string;

type IUseForgotPassword = (email: IForgotParams) => Promise<void>;

export const useForgotPassword = (): IUseForgotPassword => {
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  return (email: string): Promise<void> => {
    if (!isEmail(email)) {
      dispatch({
        type: UserStateActionTypes.CURRENT_USER,
        payload: {
          errors: [
            {
              // __typename: TypeNameEnums.AUTH_ERROR,
              code: AuthEnums.EMAIL_REQUIRED,
              name: `empty_email`,
            },
          ],
        },
      });
    }

    return Auth.forgotPassword(email.toLowerCase())
      .then(() => {
        dispatch({
          type: UserStateActionTypes.CURRENT_USER,
          payload: {
            email: email,
            authenticated: false,
            errors: [],
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: UserStateActionTypes.CURRENT_USER,
          payload: {
            errors: [
              {
                // __typename: TypeNameEnums.AUTH_ERROR,
                code: AuthEnums.UNEXPECTED_ERROR,
                name: `${e.code || e.name}`,
              },
            ],
          },
        });
      });
  };
};
