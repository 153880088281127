import { FC } from "react";

import { StyledResponsiveImage } from "../../../StyledComponents";
import styled, { css, useTheme } from "styled-components/macro";
import { EProductEnums } from "../../../@types/index.d";

const StyledLogo = styled.div(({ theme }) => {
  return css`
    width: ${theme.rem(16)};
    display: inline-block;
    margin-bottom: ${theme.rem(2)};
    margin-right: auto;
    margin-left: auto;
  `;
});

interface ILogoProps {
  isDark?: boolean;
  product?: EProductEnums;
}

export const Logo: FC<ILogoProps> = ({ isDark = true, product = EProductEnums.QUICK }) => {
  const themeOptions = useTheme();
  const { light, dark } = themeOptions.branded.logo[product];

  return (
    <StyledLogo>
      <StyledResponsiveImage src={isDark ? dark : light} alt="" />
    </StyledLogo>
  );
};
