import initialState from "../initialState";
import { IDownloadsAction, IDownloadsActionType, TDownloadsState } from "../../../@types/index.d";

const downloads = (state = initialState.downloads, action: IDownloadsAction): TDownloadsState => {
  switch (action.type) {
    case IDownloadsActionType.ADD_DOWNLOAD:
      if (action.payload.identifier) {
        if (state.find(({ identifier }) => identifier === action.payload.identifier)) return state;
      }
      return [...state, action.payload];
    case IDownloadsActionType.REMOVE_DOWNLOAD:
      return state.filter(({ identifier }) => identifier !== action.payload.identifier);
    case IDownloadsActionType.ADD_ERROR:
      return state.map((report) =>
        report.identifier === action.payload.identifier
          ? { ...report, error: action.payload.error }
          : report
      );
    case IDownloadsActionType.EDIT_DOWNLOAD:
      const editedState = state.map((report) =>
        report.identifier === action.payload.identifier ? { ...report, ...action.payload } : report
      );
      return editedState;
    default:
      return state;
  }
};

export default downloads;
