import styled, { css } from "styled-components/macro";
import { useResponsive, StyledSubSection, SubSectionProps } from "@canei/app-components";

export interface UploadMethodSelection {
  selected: boolean;
}

export interface StyledDatevUpProps {
  loading: boolean;
}
export const StyledSetupPage = styled.section(({ theme }) => {
  return css``;
});

export const StyledClientNav = styled.div(({ theme }) => {
  return css`
    grid-area: nav;
    display: flex;
    margin-bottom: ${theme.rem(1)};
    align-items: flex-start;
    & :last-child {
      margin-left: auto;
    }
  `;
});

export const StyledClientRegister = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: grid;
        grid-template-areas:
          "client"
          "video"
          "plan";

        grid-row-gap: ${theme.rem(1)};
        grid-column-gap: ${theme.rem(1)};
        grid-template-rows: max-content;
      `,
    },
    MEDIUM: {
      style: css`
        grid-template-columns: 1fr 50%;
        grid-template-areas:
          "client plan"
          "video plan";
      `,
    },
    LARGE: {
      style: css`
        grid-template-areas:
          "client plan"
          "video plan";
      `,
    },
  });
});
export const StyledClientData = styled.div`
  grid-area: client;
`;
export const StyledIncomePlan = styled(StyledSubSection)<SubSectionProps>(
  ({ isLoading = false }) => {
    return css`
      grid-area: plan;
    `;
  }
);
export const StyledVideoHelp = styled(StyledSubSection)<SubSectionProps>(({ theme, isLoading }) => {
  return css`
    grid-area: video;
  `;
});
