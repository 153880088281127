import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";

interface IMenuItemProps {
  selected?: boolean;
  subFeature?: boolean;
  showSubFeatures?: boolean;
}

export enum EFlexDirection {
  ROW = "row",
  COLUMN = "column",
}

const MenuItemLabel = styled.span(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        position: relative;
        width: 100%;
        padding-right: ${theme.rem(0.5)};
        padding-left: ${theme.rem(0.5)};
        & > span {
          position: absolute;
          top: ${theme.rem(-1.5)};
          right: ${theme.rem(-0.66)};
        }
      `,
    },
    LARGE: {
      style: css`
        padding-top: ${theme.rem(0)};
        padding-bottom: ${theme.rem(0)};
      `,
    },
    XLARGE: {
      style: css``,
    },
  });
});

const MenuItem = styled.li<IMenuItemProps>(({ theme, selected, subFeature, showSubFeatures }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        ${subFeature && !showSubFeatures && "display: none;"}

        list-style: none;
        margin-top: ${theme.rem(1)};
        font-size: ${theme.rem(1)};
        line-height: ${theme.rem(3)};
        cursor: pointer;
        ${selected &&
        css`
          color: black;
          border-radius: ${theme.rem(1)} ${theme.rem(0)} ${theme.rem(0)} ${theme.rem(1)};
          background-color: ${theme.values.colors.global.greys._000};
        `}
        ${subFeature &&
        css`
          background-color: ${selected
            ? theme.values.colors.global.greys._000
            : theme.values.colors.global.secondary._085};
          margin-left: ${theme.rem(1)};
          font-size: ${theme.rem(0.9)};
          padding: 0;
          // & > li {
          //   margin-top: 0;
          //   margin-left: 0;
          // }
          border-radius: ${theme.rem(1)} 0 0 ${theme.rem(1)};
        `}

        &> span {
          position: relative;
          color: ${selected
            ? theme.values.colors.global.secondary._100
            : theme.values.colors.global.greys._000};
        }
      `,
    },
    LARGE: {
      style: css`
        margin-top: ${theme.rem(0.3)};
        line-height: ${subFeature ? theme.rem(2) : theme.rem(2.5)};
      `,
    },
    XLARGE: {
      style: css`
        margin-top: ${subFeature ? theme.rem(0.5) : theme.rem(0.8)};
        font-size: ${subFeature ? theme.rem(1) : theme.rem(1.2)};
        line-height: ${subFeature ? theme.rem(2.5) : theme.rem(3.5)};
      `,
    },
  });
});

const SubFeatures = styled.div(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        background-color: ${theme.values.colors.global.secondary._085};
        margin-left: ${theme.rem(1)};
        margin-top: ${theme.rem(0.5)};
        & > li {
          margin-top: 0;
          margin-left: 0;
        }
        border-radius: ${theme.rem(1)} 0 0 ${theme.rem(1)};
        animation: fadeIn 0.8s;

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `,
    },
    LARGE: {
      style: css`
        margin-left: ${theme.rem(3)};
      `,
    },
  });
});

const MenuItemWrapper = styled.div(({ theme }) => {
  return css``;
});

const MenuWrapper = styled.ul(({ theme }) => {
  const { _100 } = theme.values.colors.global.secondary;

  return css`
    height: 100%;
    padding-top: ${theme.rem(1)};
    padding-bottom: ${theme.rem(1)};
    padding-left: ${theme.rem(1)};
    margin: 0;
    grid-area: sidebar;
    background-color: ${_100};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: white;
    border-radius: ${theme.rem(2)} 0 0 ${theme.rem(2)};
  `;
});

export const StyledAppStoreMenu = {
  MenuWrapper,
  MenuItem,
  MenuItemLabel,
  MenuItemWrapper,
  SubFeatures,
};
