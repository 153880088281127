import { FC } from "react";
import { StyledTable } from "./styled.table";

export interface TableProps {
  width?: string;
  isLoading?: boolean;
}
export const Table: FC<TableProps> = ({ children, ...rest }) => {
  return <StyledTable {...rest}>{children}</StyledTable>;
};
