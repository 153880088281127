import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import awsConfig from "./awsConfig";
import Backend from "i18next-http-backend";

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales
  // (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: "de",
    ns: "common",
    defaultNS: "common",
    fallbackNS: "common",
    fallbackLng: "en",
    returnNull: false,
    saveMissing: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [
        "p",
        "b",
        "br",
        "span",
        "strong",
        "small",
        "i",
        "h3",
        "h4",
        "ul",
        "ol",
        "li",
        "a",
      ],
    },
    backend: {
      loadPath: `https://${awsConfig.i18n.host}/{{lng}}/{{ns}}.json`,
      crossDomain: true,
    },
  });

export default i18n;
