import { getI18n } from "react-i18next";

export const getLocaleFromLanguage = (): string => {
  const { language } = getI18n();
  switch (language) {
    case "de":
      return "de-DE";
    case "en":
      return "en-US";
    default:
      return "de-DE";
  }
};
