import { FC, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { gql, useLazyQuery } from "@apollo/client";

import { StyledUploadValidationSummary } from "./styled.upload-validation-summary";

import {
  Button,
  ButtonVariantEnums,
  IDialogKeyEnums,
  IDialogOptions,
  Icon,
  IconNameEnums,
  IconSizeEnums,
  P,
  useDialogContext,
  useDialogs,
  LinkButton,
} from "@canei/app-components";
import { useCopyEMailTemplateDialog } from "../../../../quick/Private/Setup/useCopyEMailTemplateDialog";

const GET_QUICK_CHECK = gql`
  query getQuickCheck($susa_id: ID!) {
    getActualSuSaById(susa_id: $susa_id) {
      susa_id
      check {
        profitloss_balance
        active_passive
        balance_cashflow
      }
    }
  }
`;
export interface IUploadValidationContext {
  susa_id: string;
  date: string;
  loading: boolean;
  check: IQuickCheckItems;
}
interface IQuickCheck {
  getActualSuSaById: {
    susa_id: string;
    check: IQuickCheckItems;
  };
}
interface IQuickCheckItems {
  active_passive: EQuickCheckCode;
  balance_cashflow: EQuickCheckCode;
  profitloss_balance: EQuickCheckCode;
}
interface IQuickCheckVar {
  susa_id: string;
}
export enum EQuickCheckCode {
  VALID = "valid",
  INVALID = "invalid",
  INITIAL = "INITIAL",
}

export interface IQuickCheckItemProps extends Pick<IUploadValidationContext, "loading"> {
  caption: string;
  state: EQuickCheckCode;
}
export const QuickCheckItem: FC<IQuickCheckItemProps> = ({ caption, loading, state }) => {
  return (
    <StyledUploadValidationSummary.QuickChek.Item>
      <StyledUploadValidationSummary.QuickChek.Caption>
        {caption}
      </StyledUploadValidationSummary.QuickChek.Caption>
      <StyledUploadValidationSummary.QuickChek.Icon state={state}>
        <Icon
          name={
            state === EQuickCheckCode.VALID
              ? IconNameEnums.CHECK_CIRCLE
              : IconNameEnums.TIMES_CIRCLE
          }
          size={IconSizeEnums.LARGE}
          loading={loading}
        />
      </StyledUploadValidationSummary.QuickChek.Icon>
    </StyledUploadValidationSummary.QuickChek.Item>
  );
};
const Content: FC = () => {
  const { t } = useTranslation(["quick/common", "quick/errors"]);
  const copyEMailTemplateDialog = useCopyEMailTemplateDialog();
  const { close } = useDialogs();

  const dialogContext = useDialogContext<IUploadValidationContext | undefined>(
    IDialogKeyEnums.BASIC,
    undefined
  );
  const { setData } = useDialogs();
  const [getQuickCheck, { data, loading }] = useLazyQuery<IQuickCheck, IQuickCheckVar>(
    GET_QUICK_CHECK
  );
  const checkResult = data?.getActualSuSaById?.check;

  useEffect(() => {
    if (dialogContext?.susa_id) {
      getQuickCheck({
        variables: { susa_id: dialogContext.susa_id },
      });
    }
  }, [dialogContext?.susa_id, getQuickCheck]);

  useEffect(() => {
    if (
      data?.getActualSuSaById.susa_id &&
      data?.getActualSuSaById.susa_id === dialogContext?.susa_id &&
      dialogContext?.loading === true
    ) {
      setData(IDialogKeyEnums.BASIC, { ...data.getActualSuSaById, loading: false });
    }
  }, [data?.getActualSuSaById, dialogContext?.susa_id, dialogContext?.loading, setData, close]);

  return (
    <>
      <P>{t("upload.summary.quick_check.title")}</P>
      <StyledUploadValidationSummary.QuickChek.Container>
        {dialogContext && (
          <>
            <QuickCheckItem
              caption={t("upload.summary.quick_check.profit_loss")}
              loading={loading}
              state={dialogContext?.check.profitloss_balance}
            />
            <QuickCheckItem
              caption={t("upload.summary.quick_check.active_passive")}
              loading={loading}
              state={dialogContext?.check.active_passive}
            />
            <QuickCheckItem
              caption={t("upload.summary.quick_check.cash_flow")}
              loading={loading}
              state={dialogContext?.check.balance_cashflow}
            />
          </>
        )}
      </StyledUploadValidationSummary.QuickChek.Container>
      {checkResult && Object.values(checkResult).includes("invalid") && (
        <>
          <StyledUploadValidationSummary.QuickChek.InvalidText>
            <StyledUploadValidationSummary.QuickChek.WarningIcon>
              <Icon
                name={IconNameEnums.TIMES_CIRCLE}
                size={IconSizeEnums.SMALL}
                loading={loading}
              />
            </StyledUploadValidationSummary.QuickChek.WarningIcon>
            <Trans i18nKey="quick/common:quick_check_dialog.email">
              <LinkButton
                variant={ButtonVariantEnums.PRIMARY}
                type={"button"}
                inverted={false}
                onClick={(): void => {
                  copyEMailTemplateDialog();
                }}
              >
                bbb
              </LinkButton>
            </Trans>
          </StyledUploadValidationSummary.QuickChek.InvalidText>
        </>
      )}
    </>
  );
};
const Footer: FC = () => {
  const { t } = useTranslation();
  const { close } = useDialogs();
  const { loading } = useDialogContext<Partial<IUploadValidationContext>>(IDialogKeyEnums.BASIC, {
    loading: false,
  });
  const handleCloseDialog = (): void => {
    close(IDialogKeyEnums.BASIC);
  };
  return (
    <StyledUploadValidationSummary.DialogFooter>
      <Button
        type={"button"}
        disabled={loading}
        inverted={false}
        variant={ButtonVariantEnums.PRIMARY}
        onClick={handleCloseDialog}
        inline={true}
      >
        {t("misc.close")}
      </Button>
    </StyledUploadValidationSummary.DialogFooter>
  );
};

export const uploadValidationSummaryDialog: IDialogOptions<IUploadValidationContext> = {
  dialogKey: IDialogKeyEnums.BASIC,
  closable: true,
  content: <Content />,
  footer: <Footer />,
  flexible: false,
};
