import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { ICurrentUserAction } from "../store/reducers";
import {
  EDataSyncLevel,
  EDataSyncScope,
  ILocalState,
  UserStateActionTypes,
  EDataSyncTypes,
} from "../../@types/index.d";
import {
  RelationLink,
  SelectClientState,
  FileUploadVariant,
  IArrangementKeys,
  EventErrorsCodes,
  FileUploadFormat,
  getFingerprint,
} from "@canei/app-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import {
  ITransactionStateInput,
  useSwitchClient,
  useSyncEvents,
  useTransactionEventListener,
} from "./";
import { useContext, useEffect, useMemo } from "react";
import {
  EFileUploadState,
  EUploadersActionTypes,
  IFileUploadState,
  UploadersContext,
  UploadersDispatch,
} from "../../containers/Uploaders";
import { useAppActivity } from "./appActivity";
import { useGetConversionMetaData } from "./useGetSetConversionMetaData";

const MUT_UPLOAD = gql`
  mutation upload($file: FileInput!) {
    result: uploadSuSaAsFile(file: $file) {
      guid
      covid
    }
  }
`;
const MUT_UPLOAD_PREVIEW = gql`
  mutation upload($file: FileInput!) {
    preview: uploadPreview(file: $file)
  }
`;
const MUT_UPLOAD_REVIEWED = gql`
  mutation upload($file: FileInput!) {
    result: publishReviewedFile(file: $file) {
      guid
      covid
    }
  }
`;

interface IUploadSuSaData {
  result: {
    guid: string;
    covid: string;
  };
}
interface IUploadSuSaVariables {
  file: SuSaFileProps;
}

interface IFilePreviewData {
  preview: string;
}
export interface SuSaFileProps {
  client_id: string;
  links: RelationLink[];
  content: string;
  lastModified: string;
  name: string;
  size: number;
  type: string;
  date: string;
  format: FileUploadFormat;
  sheet_name: string;
  delimiter: string;
  quote: string;
  variant_type: FileUploadVariant;
  mapping?: Partial<IArrangementKeys>;
}

const getSuSaFilePropsFromState = (state: IFileUploadState): SuSaFileProps => {
  if (state.settings.format === null) {
    throw new Error("File format can not be null!");
  }
  if (state.settings?.variant_type === null) {
    // throw new Error("variant_type can not be null!");
  }
  if (state.file?.date === null) {
    throw new Error("date can not be null!");
  }
  const { type, size = 0, content = "", date, lastModified, links } = state.file;
  const {
    variant_type,
    format,
    sheet_name = "",
    delimiter = "",
    quote = "",
    mapping,
  } = state.settings;
  return {
    client_id: state.client_id,
    name: state.file.name || "",
    format,
    type: type,
    sheet_name,
    delimiter,
    quote,
    variant_type,
    size: size,
    content: content,
    date,
    lastModified,
    links,
    mapping,
  };
};

type TUseSuSaUpload = () => [
  (file: IFileUploadState, onComplete?: (data: FetchResult<IUploadSuSaData>) => void) => void,
  MutationResult<IUploadSuSaData>, // redirected mutation result
  Record<string, EventErrorsCodes> | undefined, // errorState
  ITransactionStateInput | undefined,
  () => void, //resetTransaction
  string | undefined
];
type TUseSuSaUploadPreview = () => [
  (file: IFileUploadState, onComplete?: (data: FetchResult<IFilePreviewData>) => void) => void,
  MutationResult, // redirected mutation result
  Record<string, EventErrorsCodes> | undefined // errorState
];

export const useErrorState = (errorState: Record<string, EventErrorsCodes> | undefined): void => {
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();

  const { switchClient } = useSwitchClient();
  const { stopSync } = useSyncEvents();

  const susaFileUploadContext = useContext(UploadersContext);
  const susaFileUploadDispatch = useContext(UploadersDispatch);
  const transactionId = errorState && getFingerprint(Object.keys(errorState)[0]).covid;

  const history = useHistory();
  const { currentUser } = useSelector((state: ILocalState) => state);

  const susaStateDateFromTransactionId = useMemo<string | undefined>(() => {
    let stateDate;
    transactionId &&
      Object.entries(susaFileUploadContext.sharedState.fileUploadState).forEach(([name, state]) => {
        if (state.transactionId === transactionId) stateDate = name;
      });
    return stateDate;
  }, [susaFileUploadContext.sharedState.fileUploadState, transactionId]);
  useEffect(() => {
    if (!transactionId || !errorState) return;
    const errorStateOfTransaction = errorState[transactionId];
    if (!errorStateOfTransaction) return;
    switch (errorStateOfTransaction) {
      case undefined: // do nothing
        break;
      case EventErrorsCodes.UNKNOWN:
        if (currentUser.selectedClient.state === SelectClientState.PENDING) return;
        if (currentUser.selectedClient.state === SelectClientState.READY) return;
        dispatchUser({ type: UserStateActionTypes.SET_SELECTED_CLIENT_PENDING, payload: {} });
        break;
      case EventErrorsCodes.TRANSACTION_OK:
        // if (history.location.pathname === EPrivatePathName.SETUP) {
        // I REMOVED THE NEXT 2 LINES, BECAUSE OF NEW SETUPFLOW. NEXT 2 LINES REDIRECTS THE USER TO DASHBOARD WHICH WE DONT WANT
        // WE WANT THIS AFTER THE USER CLICKS THE BUTTON IN DONE-LAST PAGE OF NEW SETUPFLOW.
        // switchClient(currentUser.selectedClient.client_id, SelectClientState.READY);
        // history.push(EPrivatePathName.DASHBOARD);
        // }
        if (
          susaStateDateFromTransactionId &&
          susaFileUploadContext.sharedState.fileUploadState[susaStateDateFromTransactionId]
            .state === EFileUploadState.UPLOADING
        ) {
          susaFileUploadDispatch({
            type: EUploadersActionTypes.SET_UPLOAD_STATE,
            payload: {
              date: susaStateDateFromTransactionId,
              uploadState: {
                transactionId,
                state: EFileUploadState.UPLOADED,
                transactionState: errorStateOfTransaction,
              },
            },
          });
        }
        break;
      default:
        // an error code returned from event listener
        susaFileUploadDispatch({
          type: EUploadersActionTypes.SET_UPLOAD_STATE,
          payload: {
            date: susaStateDateFromTransactionId,
            uploadState: {
              transactionId,
              state: EFileUploadState.ERRORED,
              transactionState: errorStateOfTransaction,
            },
          },
        });
        break;
    }
  }, [
    currentUser.selectedClient.state,
    currentUser.selectedClient.client_id,
    // dispatchDataSync,
    dispatchUser,
    errorState,
    history,
    switchClient,
    // startSync,
    // inSync,
    stopSync,
    transactionId,
    susaStateDateFromTransactionId,
    susaFileUploadDispatch,
    susaFileUploadContext.sharedState.fileUploadState,
  ]);
};
export const useSuSaUpload: TUseSuSaUpload = () => {
  const [upload, rest] = useMutation<IUploadSuSaData, IUploadSuSaVariables>(MUT_UPLOAD);
  const { userUploadSusa } = useAppActivity();
  const { currentUser } = useSelector((state: ILocalState) => state);
  const [transactionEvent, errorState, transactionInput, resetTransaction, errMsg] =
    useTransactionEventListener();
  useErrorState(errorState);

  const susaList = useMemo(() => {
    if (!currentUser?.selectedClient) return;
    if (!currentUser.selectedClient.uploads?.list) return;
    return currentUser.selectedClient.uploads.list;
  }, [currentUser?.selectedClient]);

  // it gives the month and year info of the susa "MM/YYYY" which has the newest date
  const dateOfFirstSusaInList = susaList && susaList[0] && susaList[0].date.split("/");

  // to check whether the user made any manuel change in liquidity
  const isThereManuelChange = useMemo(() => {
    if (!currentUser.selectedClient) return;
    if (
      currentUser.selectedClient.liquidity?.weekly?.values_updated_at ||
      currentUser.selectedClient.liquidity?.monthly?.values_updated_at ||
      currentUser.selectedClient.profitloss?.values_updated_at
    )
      return true;
  }, [currentUser.selectedClient]);

  return [
    (file): void => {
      const dateOfNewSusa = file.file.date?.split("/"); // it gives the month and year info of the susa "MM/YYYY" that is being uploaded
      let isSusaNewDated = false; // to check whether the susa that is being uploaded is new dated or has the same month / year info

      if (dateOfFirstSusaInList && dateOfNewSusa) {
        const [monthOfFirstSusaInList, yearOfFirstSusaInList] = dateOfFirstSusaInList;
        const [monthOfNewSusa, yearOfNewSusa] = dateOfNewSusa;

        if (
          yearOfNewSusa > yearOfFirstSusaInList ||
          (yearOfNewSusa === yearOfFirstSusaInList && monthOfNewSusa >= monthOfFirstSusaInList)
        ) {
          isSusaNewDated = true;
        }
      }

      upload({
        variables: {
          file: getSuSaFilePropsFromState(file),
        },
      })
        .then(({ data }) => {
          if (!data?.result) return;

          const transactionId = getFingerprint(data.result.covid).covid;
          if (!transactionId) return;
          if (!file.file.date) return;

          // if it is initial susa upload or (a new dated susa and if there is no manuel change) then we send PLAN scope
          const transactionParams: ITransactionStateInput = {
            transactionId,
            accountId: file.client_id,
            level: EDataSyncLevel.CLIENT,
          };
          if (
            !(susaList && susaList.length >= 1) ||
            (isSusaNewDated && isThereManuelChange === undefined)
          ) {
            transactionParams.scope = EDataSyncScope.PLAN;
            transactionParams.type = EDataSyncTypes.UPSERT;
          } else {
            transactionParams.scope = EDataSyncScope.ALERT;
            transactionParams.type = EDataSyncTypes.UPSERT;
          }

          transactionEvent({
            ...transactionParams,
          });

          userUploadSusa(file.file.name);
        })
        .catch(() => {
          // Upload error
        });
    },
    rest,
    errorState,
    transactionInput,
    resetTransaction,
    errMsg,
  ];
};

export const useUploadPreview: TUseSuSaUploadPreview = () => {
  const [uploadPreview, rest] = useMutation<IFilePreviewData, IUploadSuSaVariables>(
    MUT_UPLOAD_PREVIEW
  );

  return [
    (file, onComplete): void => {
      uploadPreview({
        variables: {
          file: getSuSaFilePropsFromState(file),
        },
      }).then((fetchResult) => {
        onComplete && onComplete(fetchResult);
      });
    },
    rest,
    undefined, // errorState is not used here as this is not related with cached events
  ];
};
export const useUploadReviewedFile: TUseSuSaUpload = () => {
  const [uploadPreview, rest] = useMutation<IUploadSuSaData, IUploadSuSaVariables>(
    MUT_UPLOAD_REVIEWED
  );
  const { userUploadSusa } = useAppActivity();
  const { setConversionMetadata } = useGetConversionMetaData();
  const { currentUser } = useSelector((state: ILocalState) => state);
  const [transactionEvent, errorState, transactionInput, resetTransaction, errMsg] =
    useTransactionEventListener();

  useErrorState(errorState);

  const susaList = useMemo(() => {
    if (!currentUser?.selectedClient) return;
    if (!currentUser.selectedClient.uploads?.list) return;
    return currentUser.selectedClient.uploads.list;
  }, [currentUser?.selectedClient]);

  // it gives the month and year info of the susa "MM/YYYY" which has the newest date
  const dateOfFirstSusaInList = susaList && susaList[0] && susaList[0].date.split("/");

  // to check whether the user made any manuel change in liquidity
  const isThereManuelChange = useMemo(() => {
    if (!currentUser.selectedClient) return;
    if (
      currentUser.selectedClient.liquidity?.weekly?.values_updated_at ||
      currentUser.selectedClient.liquidity?.monthly?.values_updated_at ||
      currentUser.selectedClient.profitloss?.values_updated_at
    )
      return true;
  }, [currentUser.selectedClient]);

  return [
    (file): void => {
      const dateOfNewSusa = file.file.date?.split("/"); // it gives the month and year info of the susa "MM/YYYY" that is being uploaded
      let isSusaNewDated = false; // to check whether the susa that is being uploaded is new dated or has the same month / year info

      if (dateOfFirstSusaInList && dateOfNewSusa) {
        const [monthOfFirstSusaInList, yearOfFirstSusaInList] = dateOfFirstSusaInList;
        const [monthOfNewSusa, yearOfNewSusa] = dateOfNewSusa;

        if (
          yearOfNewSusa > yearOfFirstSusaInList ||
          (yearOfNewSusa === yearOfFirstSusaInList && monthOfNewSusa >= monthOfFirstSusaInList)
        ) {
          isSusaNewDated = true;
        }
      }

      uploadPreview({
        variables: {
          file: getSuSaFilePropsFromState(file),
        },
      })
        .then(({ data }) => {
          if (!data?.result) return;

          const transactionId = getFingerprint(data?.result.covid).covid;
          if (!transactionId) return;
          if (!file.file.date) return;

          // if it is initial susa upload or (a new dated susa and if there is no manuel change) then we send PLAN scope
          const transactionParams: ITransactionStateInput = {
            transactionId,
            accountId: file.client_id,
            level: EDataSyncLevel.CLIENT,
          };
          if (
            !(susaList && susaList.length >= 1) ||
            (isSusaNewDated && isThereManuelChange === undefined)
          ) {
            transactionParams.scope = EDataSyncScope.PLAN;
            transactionParams.type = EDataSyncTypes.UPSERT;
          } else {
            transactionParams.scope = EDataSyncScope.ALERT;
            transactionParams.type = EDataSyncTypes.UPSERT;
          }

          transactionEvent({
            ...transactionParams,
          });

          userUploadSusa(file.file.name);
        })
        .then(() => {
          const { mapping, format, variant_type } = file.settings;
          if (!format || !mapping || !variant_type) return;
          file.savePreferences &&
            setConversionMetadata({
              variables: {
                account_id: currentUser.selectedClient.client_id as string,
                format,
                variant_type,
                mapping,
              },
            });
        });
    },
    rest,
    errorState,
    transactionInput,
    resetTransaction,
    errMsg,
  ];
};
