import { TimeSeriesActuals } from "../../../@types/index.d";

export interface IActualSuSaByIdQuery {
  getActualSuSaById: TimeSeriesActuals;
}
export interface IGetSuSaByIdInput {
  susa_id: string;
}

interface IListActualSuSasQuery {
  getActualActuals: {
    actuals: TimeSeriesActuals[];
    format: ActualFormats;
  };
}

interface IActualsInputParams {
  client_id: string;
  global_code_filter?: string[];
  format: ActualFormats;
  from?: string;
  to?: string;
}
export interface IActualsInput {
  params: IActualsInputParams;
}

export enum ActualFormats {
  TIME_SERIES = "TIME_SERIES",
}
