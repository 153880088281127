/**
 * @file
 */
import { IBrandedValues, IThemeValues, LayoutEnums } from "../../@types/styled";
import { IDevice, IThemeOptions } from "../../@types/index.d";

/**
 * Creates Compiled theme which will be used to calculate style and theme related variables.
 */
export default class ThemeCompiler {
  values: IThemeValues;
  device: IDevice;
  branded: IBrandedValues;
  layout: LayoutEnums;
  sideNavExpanded: boolean;

  /**
   * @constructor
   * @param themeValues
   * @param device
   * @param options
   */
  constructor(themeValues: IThemeValues, device: IDevice, options: IThemeOptions) {
    this.values = themeValues;
    this.device = device;
    this.layout = options.layout;
    this.branded = options.branded;
    this.sideNavExpanded = options.sideNavExpanded;
  }

  /**
   * Calculates related size in px string from given `val`
   *
   * `baseFontSize` retrieved from given theme
   * @param val floating number
   */
  rem(val: number): string {
    return `${this.values.baseFontSize * val}px`;
  }
}
