import { FC, useContext, useEffect } from "react";
import { StyledUploadManualEntrySubmit } from "../styled.uploaders";

import {
  Button,
  ButtonVariantEnums,
  AlignmentEnums,
  FormContext,
  FormDispatch,
  FormRow,
  Alert,
  AlertTypeEnums,
} from "@canei/app-components";
import { useTranslation } from "react-i18next";
const comparePrecise = (val1: string, val2: string): boolean => {
  return parseFloat(val1).toPrecision(2) === parseFloat(val2).toPrecision(2);
};
export const Submit: FC = () => {
  const { t } = useTranslation();
  const formContext = useContext(FormContext);
  const formDispatch = useContext(FormDispatch);
  useEffect(() => {
    if (
      comparePrecise(formContext.BA20T, formContext.BP20T) &&
      comparePrecise(formContext.ST_BA20T, formContext.ST_BP20T)
    ) {
      if (formContext.isActiveEqualToPassive !== "true") {
        formDispatch({ isActiveEqualToPassive: "true" });
      }
    } else {
      if (formContext.isActiveEqualToPassive !== "false") {
        formDispatch({ isActiveEqualToPassive: "false" });
      }
    }
  }, [
    formDispatch,
    formContext.isActiveEqualToPassive,
    formContext.BA20T,
    formContext.BP20T,
    formContext.ST_BA20T,
    formContext.ST_BP20T,
  ]);
  const handleBeforeSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (!formContext.date || formContext.date === "") {
      e.preventDefault();
      formDispatch({ dateRequired: "true" });
      return;
    }
  };

  return (
    <StyledUploadManualEntrySubmit isLoading={false}>
      {formContext.isActiveEqualToPassive === "false" && (
        <FormRow align={AlignmentEnums.STRETCH}>
          <Alert type={AlertTypeEnums.WARNING}>
            {t("setup.upload_form_2.manual.active-equal-passive")}
          </Alert>
        </FormRow>
      )}
      <FormRow align={AlignmentEnums.RIGHT}>
        <Button
          variant={ButtonVariantEnums.DEFAULT}
          inverted={false}
          type={"submit"}
          inline={true}
          disabled={formContext.isActiveEqualToPassive === "false"}
          onClick={handleBeforeSubmit}
        >
          {t("misc.save")}
        </Button>
      </FormRow>
    </StyledUploadManualEntrySubmit>
  );
};
