import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { StyledUploadManualEntryBalance } from "../styled.uploaders";
import { Cell, Row, Table } from "../../../components/Table";
import { IManualEntryItems, ManualEntrySum, IGlobalCodes } from ".";
import {
  LoaderTypeEnums,
  LoadingIndicator,
  Panel,
  PanelBody,
  PanelTitle,
} from "@canei/app-components";
import { UploadersContext } from "../index";
const GlobalBalanceCodesLoading: FC = () => {
  const rows = new Array(33).fill("", 0, 33).map((item: string, index: number) => (
    <Row key={index}>
      <Cell>
        <LoadingIndicator type={LoaderTypeEnums.TEXT} length={12} />
      </Cell>
      <Cell>
        <LoadingIndicator type={LoaderTypeEnums.INPUT_FIELD} length={5} />
      </Cell>
      <Cell>
        <LoadingIndicator type={LoaderTypeEnums.INPUT_FIELD} length={5} />
      </Cell>
    </Row>
  ));
  return <>{rows}</>;
};

export interface IBalanceProps {
  data: IGlobalCodes;
  loading: boolean;
}
export const Balance: FC<IBalanceProps> = ({ data, loading }) => {
  const { t } = useTranslation();
  const { isInitial } = useContext(UploadersContext);
  const balance = data?.getGlobalCodesTree?.filter(
    (item: IManualEntryItems) =>
      item?.value && item?.value.startsWith("B") && item.value !== "BA17:BP05"
  );
  return (
    <StyledUploadManualEntryBalance isLoading={loading}>
      <Panel>
        <PanelTitle
          headline={t("setup.upload_form_2.manual.balance")}
          help={isInitial ? "0" : null}
        />
        <PanelBody>
          <Table width={"100%"}>
            <tbody>
              {loading ? (
                <GlobalBalanceCodesLoading />
              ) : (
                balance?.map((guv: IManualEntryItems, guvIndex: number) => (
                  <ManualEntrySum key={`guvIndex-${guvIndex}`} {...guv} type={"balance"} />
                ))
              )}
            </tbody>
          </Table>
        </PanelBody>
      </Panel>
    </StyledUploadManualEntryBalance>
  );
};
