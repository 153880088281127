import { useSelector } from "react-redux";
import { ILocalState } from "../../@types/index.d";
import { useMemo } from "react";
import { SelectedClient } from "@canei/app-components";

type TUseClientSelection = () => {
  /**
   * creates and returns a new SelectedClient class,
   * which helps to work with client information in current browser's
   * local storage
   */
  clientSelection: SelectedClient;
};

export const useClientSelection: TUseClientSelection = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser);

  const memoizedCurrentUser = useMemo(() => {
    return new SelectedClient(currentUser);
  }, [currentUser]);
  return {
    clientSelection: memoizedCurrentUser,
  };
};
