import { Dispatch, Reducer, useReducer } from "react";

export enum ISortType {
  ASC = "ASC",
  DESC = "DESC",
  NOSORT = "NOSORT",
}

export type SortFields<T> = Record<keyof T, ISortType>;

export interface IUseColumnReorderOptions<T> {
  initialSort: SortFields<T>;
}

interface IUseColumnReorderReturnValue<T> {
  reSort: Dispatch<keyof T>;
  currentSort: Record<keyof T, ISortType>;
}

type TUseColumnReorder = <T>(
  sortReducer: Reducer<Record<keyof T, ISortType>, keyof T>,
  initialSort: SortFields<T>
) => IUseColumnReorderReturnValue<T>;

type TSortReducerAction<T> = keyof T;

export enum ESortActionType {
  DEFAULT = "DEFAULT",
  TOGGLE = "TOGGLE",
}
// type TSortReducer = <S>(state: S, action: keyof S) => S;

export const useColumnReorder: TUseColumnReorder = (sortReducer, initialSort) => {
  const [currentSort, reSort] = useReducer<
    Reducer<typeof initialSort, TSortReducerAction<typeof initialSort>>
  >(sortReducer, initialSort);

  return {
    reSort,
    currentSort,
  };
};
