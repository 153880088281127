import { FC, useEffect } from "react";
import { DatevPdfUpload } from "./DatevPdfUpload";
import { DatevInterface } from "./DatevInterface";
import { StyledUploadDatev } from "../styled.uploaders";
import { ErrorBoundary } from "@canei/app-components";
import { useWarmUp } from "../../../_lib/hooks";
import { IWarmUpScopes, IWarmUpTopics } from "../../../@types/index.d";

export const DatevUpload: FC = () => {
  const warmUp = useWarmUp();

  useEffect(() => {
    warmUp(IWarmUpTopics.FILE_TOPIC, IWarmUpScopes.SUSA);
  }, [warmUp]);
  return (
    <ErrorBoundary>
      <StyledUploadDatev>
        <DatevPdfUpload />
        <DatevInterface />
      </StyledUploadDatev>
    </ErrorBoundary>
  );
};
