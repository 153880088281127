import { gql, useLazyQuery } from "@apollo/client";

import { useEffect, useMemo, useState } from "react";
import {
  IFinancialGradeData,
  useFormatNumber,
  useTranslatedDate,
  SelectClientState,
} from "@canei/app-components";
import {
  IFinancialGradeReportProps,
  IGradesTree,
} from "../../views/Private/Dashboard/FinancialGrade/FinancialGradeReport";

import { optimisticChartData, optimisticGradesTree } from "../optimisticUI";
import { IEvaluations, ILocalState } from "../../@types/index.d";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const GET_GRADES_TREE = gql`
  query getRealtedGrades($all_relevant: Boolean) {
    getGradesTree(all_relevant: $all_relevant) {
      value
      all_relevant
      tree {
        value
        weight
        tree {
          value
          weight
          tree {
            value
          }
        }
      }
    }
  }
`;
const GET_EVALUATIONS = gql`
  query getEvaluations($params: Evaluation!) {
    getEvaluationByClientId(params: $params) {
      month
      all_relevant
      grades {
        grade
        grade_id
        trend
      }
    }
  }
`;

export type TMonth = string;
export type TReportDataFn = (month?: TMonth) => void;

export interface IUseEvaluationReturnValue extends IFinancialGradeReportProps {
  chartData: IFinancialGradeData[];
  reportData: TReportDataFn;
  loading: boolean;
}

export const useEvaluations = (): IUseEvaluationReturnValue => {
  const { t } = useTranslation();
  const { formatNumber } = useFormatNumber();
  const { currentUser } = useSelector((state: ILocalState) => state);
  const translatedDate = useTranslatedDate();
  const [
    getGrades,
    {
      data: gradesData,
      loading: gradesDataLoading,
      called: gradesDataCalled,
      variables: gradesDataVariables,
    },
  ] = useLazyQuery(GET_GRADES_TREE);
  const [clientId, setClientId] = useState<undefined | string>();
  const [evaluationMonth, setEvaluationMonth] = useState<undefined | string>();
  const [
    getEvaluations,
    { data: evaluationsData, called: evaluationsCalled, loading: evaluationLoading },
  ] = useLazyQuery(GET_EVALUATIONS);

  const chartData = useMemo<IFinancialGradeData[]>(() => {
    const grades = evaluationsData?.getEvaluationByClientId as IEvaluations[];
    if (!grades) return optimisticChartData;

    return grades.map(({ month, grades }) => ({
      month,
      label: t("dashboard.financial_grade.graph.xAxisDates", translatedDate(month, "short")),
      grade: grades.find(({ grade_id }) => grade_id === "GRD000")?.grade || -1,
    }));
  }, [evaluationsData, translatedDate, t]);

  const selectedEvaluation = useMemo<IEvaluations | undefined>(() => {
    if (!evaluationMonth) return undefined;
    const evaluations = evaluationsData?.getEvaluationByClientId as IEvaluations[];
    if (!evaluations || evaluations.length === 0) return undefined;
    return evaluations.find(({ month }) => month === evaluationMonth);
  }, [evaluationMonth, evaluationsData]);

  useEffect(() => {
    if (!selectedEvaluation) return;
    const selectedEvaluationAllRelevant = selectedEvaluation.all_relevant;
    const { all_relevant: gradesDataAllRelevant } = (gradesData as IGradesTree | undefined) || {};
    const matchedGradesDataAvailable =
      gradesDataAllRelevant && gradesDataAllRelevant === selectedEvaluation.all_relevant;

    const matchedGradesDataPending =
      gradesDataCalled &&
      gradesDataVariables &&
      gradesDataVariables.all_relevant === selectedEvaluationAllRelevant;

    if (matchedGradesDataAvailable || matchedGradesDataPending) return;

    getGrades({
      variables: {
        all_relevant: selectedEvaluationAllRelevant,
      },
    });
  }, [getGrades, gradesData, gradesDataCalled, gradesDataVariables, selectedEvaluation]);
  useEffect(() => {
    if (currentUser.selectedClient.client_id === null) return;

    currentUser.selectedClient.client_id !== clientId &&
      setClientId(currentUser.selectedClient.client_id);
  }, [clientId, currentUser.selectedClient.client_id]);

  useEffect(() => {
    if (currentUser.selectedClient.client_id === null) return;
    if (currentUser.selectedClient.state === SelectClientState.DRAFT) return;

    if (!evaluationsCalled || currentUser.selectedClient.client_id !== clientId) {
      getEvaluations({
        variables: {
          params: {
            client_id: currentUser.selectedClient.client_id,
          },
        },
      });
    }
  }, [
    currentUser.selectedClient.client_id,
    currentUser.selectedClient.state,
    evaluationsCalled,
    getEvaluations,
    clientId,
  ]);
  const currentGrade = useMemo<string | undefined>(() => {
    const { grade } =
      (selectedEvaluation?.grades || []).find((_grade) => _grade.grade_id === "GRD000") || {};
    return grade ? formatNumber(grade, { maximumFractionDigits: 1 }) : undefined;
  }, [formatNumber, selectedEvaluation?.grades]);

  const prevGrade = useMemo<string | undefined>(() => {
    const evaluations = evaluationsData?.getEvaluationByClientId as IEvaluations[];
    if (!evaluations) return undefined;
    if (!evaluationMonth) return undefined;
    const currentGradeIndex = evaluations.findIndex(({ month }) => month === evaluationMonth);
    const prevGradeIndex = currentGradeIndex + 1;

    if (prevGradeIndex >= evaluations.length) return undefined;

    const { grade } =
      evaluations[prevGradeIndex].grades.find((_grade) => _grade.grade_id === "GRD000") || {};

    return grade ? formatNumber(grade, { maximumFractionDigits: 1 }) : undefined;
  }, [evaluationMonth, evaluationsData?.getEvaluationByClientId, formatNumber]);

  const reportData: TReportDataFn = (selectedMonth) => {
    if (!selectedMonth || selectedMonth === evaluationMonth) return;
    setEvaluationMonth(selectedMonth);
  };

  return {
    chartData,
    reportData,
    dateOfReport:
      evaluationMonth &&
      t("dashboard.financial_grade.date", translatedDate(evaluationMonth, "short")),
    loading: gradesDataLoading || evaluationLoading,
    gradesTree: (gradesData?.getGradesTree as IGradesTree) || optimisticGradesTree,
    currentGrade,
    prevGrade,
    notes: selectedEvaluation?.grades || [],
  };
};
