import { FC, useMemo } from "react";
import {
  ErrorBoundary,
  LoaderTypeEnums,
  LoadingIndicator,
  Dialog,
  AppComponents,
  EShortLocales,
  IAppComponentsContextProps,
  CustomerState,
  StripeJs,
} from "@canei/app-components";
import { Theme } from "./_lib/Theme";

import { useAuthState } from "./_lib/hooks";
import { Private } from "./quick/Private";
import { Public } from "./quick/Public";

import { BrowserRouter as Router } from "react-router-dom";

import { getI18n, useTranslation } from "react-i18next";
import awsConfig from "./awsConfig";
interface IPageProps {
  loading: boolean;
  authenticated: boolean;
}
const stripePromise = StripeJs.loadStripe(awsConfig.payment.publicKey);
const Page: FC<IPageProps> = ({ loading, authenticated }) => {
  const { t } = useTranslation();
  const { language } = getI18n();
  const appComponentsValues = useMemo<IAppComponentsContextProps>(() => {
    return {
      longMonthNames: t("misc.months.long", { returnObjects: true }) as string[],
      shortMonthNames: t("misc.months.short", { returnObjects: true }) as string[],
      locale: language as EShortLocales,
      assetsPath: awsConfig.assets.host,
      stripePromise,
    };
  }, [language, t]);

  return (
    <ErrorBoundary>
      <Router>
        <Theme>
          <ErrorBoundary>
            <AppComponents {...appComponentsValues}>
              {loading ? (
                <LoadingIndicator type={LoaderTypeEnums.PAGE} />
              ) : (
                <Dialog t={t}>{authenticated ? <Private /> : <Public />}</Dialog>
              )}
            </AppComponents>
          </ErrorBoundary>
        </Theme>
      </Router>
    </ErrorBoundary>
  );
};

const App: FC = () => {
  const { authenticating, authenticated, readyState } = useAuthState();

  const loading = authenticating || (authenticated && readyState !== CustomerState.READY);
  return <Page loading={loading} authenticated={authenticated} />;
};

export default App;
