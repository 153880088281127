import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
  EPrivatePathName,
  EPublicPathName,
  ILocalState,
  UserStateActionTypes,
} from "../../@types/index.d";
import { FC, Suspense, useEffect } from "react";
import { LoaderTypeEnums, LoadingIndicator, useDialogs } from "@canei/app-components";

import { lazy } from "@loadable/component";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ICurrentUserAction } from "../../_lib/store/reducers";
import { ErrorPage } from "./ErrorPage";

const Login = lazy(() => import("./Login"));
const Reset = lazy(() => import("./Reset"));
const Forgot = lazy(() => import("./Forgot"));
const Register = lazy(() => import("./Register"));
const ChangePassword = lazy(() => import("./ChangePassword"));

export const Public: FC = () => {
  const { currentUser } = useSelector((state: ILocalState) => state);

  const history = useHistory();
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  history.listen(() => {
    currentUser.errors.length > 0 &&
      dispatch({
        type: UserStateActionTypes.CURRENT_USER,
        payload: {
          errors: [],
        },
      });
  });

  const dialogs = useDialogs();
  useEffect(() => {
    if (!currentUser.authenticated) dialogs.closeAll();
  }, [currentUser.authenticated, dialogs]);

  return (
    <Switch>
      <Route exact path={EPublicPathName.HOME}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <>{currentUser.forceChange ? <ChangePassword /> : <Login />}</>
        </Suspense>
      </Route>
      <Route exact path={EPrivatePathName.LOGOUT}>
        <Redirect to={EPublicPathName.LOGIN} />
      </Route>
      <Route exact path={EPublicPathName.LOGIN}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <>{currentUser.forceChange ? <ChangePassword /> : <Login />}</>
        </Suspense>
      </Route>
      <Route exact path={EPublicPathName.RESET}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <>
            {currentUser.authenticated ? <Redirect to={EPrivatePathName.DASHBOARD} /> : <Reset />}
          </>
        </Suspense>
      </Route>
      <Route exact path={EPublicPathName.FORGOT}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Forgot />
        </Suspense>
      </Route>
      <Route exact path={[EPublicPathName.REGISTER, EPublicPathName.REGISTER_SUCCESS]}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Register />
        </Suspense>
      </Route>

      <Route exact path="*">
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <ErrorPage
            title="404 - NOT FOUND!"
            message="The page you are looking for is not found!"
          />
        </Suspense>
      </Route>
    </Switch>
  );
};
