import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ILocalState } from "../../../@types";
import CarouselComp from "../../../components/Carousel";
import { EFlexDirection, StyledAppStore } from "../styled.appStore-page";
import { EAppStoreMenuItem } from "../useAppStore";
import { useAppStoreHelper } from "../useAppStoreHelper";
import {
  ButtonBranded,
  ButtonBrandedVariantEnums,
  IDialogKeyEnums,
  useDevice,
  useDialogs,
} from "@canei/app-components";
import { useComparison } from "../Comparison/useComparison";

export const AppStoreOverview: FC = () => {
  // const { t } = useTranslation(["quick/app_store"]);
  const { content, selectedMenuItem } = useSelector((state: ILocalState) => state.appStore);
  const { getStatusInfo, handleLocation } = useAppStoreHelper();
  const { isLarge } = useDevice();
  const { t } = useTranslation(["quick/app_store"]);
  const { open: handleComparisonDialog } = useComparison();
  const dialogs = useDialogs();

  const toComparison = (): void => {
    handleComparisonDialog();
    dialogs.close(IDialogKeyEnums.APP_STORE);
  };

  if (!content) return null;

  const getOverviewListItem = (item: EAppStoreMenuItem): JSX.Element | null => {
    if (!content) return <></>;

    // don't render Overview, Payment or disabled items in the list
    if (!content[item as EAppStoreMenuItem]) return null;
    if (item === EAppStoreMenuItem.OVERVIEW) return null;
    if (item === EAppStoreMenuItem.PAYMENT) return null;
    if (content[item as EAppStoreMenuItem].disabled) return null;

    // dont render if it is a subFeature and no subscription
    if (content[item].sub_feature) return null;

    // if (content[item].sub_feature) {
    //   if (!getSubExists(item)) return null;
    // }

    return (
      <StyledAppStore.ContentLinkWrapper key={`links1-${item}-${Math.random()}`}>
        <StyledAppStore.ContentLink onClick={(): void => handleLocation(item)}>
          <Trans components={{ sup: <sup /> }}>{content[item as EAppStoreMenuItem].title} </Trans>
        </StyledAppStore.ContentLink>
        <StyledAppStore.ContentLink onClick={(): void => handleLocation(item)}>
          {getStatusInfo(item)}
        </StyledAppStore.ContentLink>
      </StyledAppStore.ContentLinkWrapper>
    );
  };

  return (
    <>
      <StyledAppStore.SectionWrapper height={13}>
        <StyledAppStore.SubTitle>
          {content[selectedMenuItem].section_one_title}
        </StyledAppStore.SubTitle>
        <StyledAppStore.SectionText>
          {content[selectedMenuItem].section_one_text}
        </StyledAppStore.SectionText>
      </StyledAppStore.SectionWrapper>
      <StyledAppStore.SectionWrapper width={100}>
        <StyledAppStore.SectionWrapper
          width={100}
          flex={isLarge ? EFlexDirection.ROW : EFlexDirection.COLUMN}
        >
          <StyledAppStore.SectionWrapper width={isLarge ? 40 : 100}>
            <StyledAppStore.SubTitle>
              {content[selectedMenuItem].extra_content.title}
            </StyledAppStore.SubTitle>
            <StyledAppStore.SectionList>
              {Object.keys(content).map((item) => {
                return getOverviewListItem(item as EAppStoreMenuItem);
              })}
            </StyledAppStore.SectionList>
          </StyledAppStore.SectionWrapper>

          <StyledAppStore.SectionWrapper width={isLarge ? 55 : 100} shadow={true} margin={4} aspect>
            <CarouselComp autoPlay={false} showArrows={true} showIndicators={true}>
              {content[selectedMenuItem].extra_content.images.map((image: string) => (
                <StyledAppStore.ContentImage
                  imageUrl={image}
                  key={`image-${selectedMenuItem}-${Math.random()}`}
                />
              ))}
            </CarouselComp>
          </StyledAppStore.SectionWrapper>
        </StyledAppStore.SectionWrapper>
      </StyledAppStore.SectionWrapper>
      <StyledAppStore.ContentFooter>
        <ButtonBranded
          inverted={false}
          type={"button"}
          inline={true}
          variant={ButtonBrandedVariantEnums.DEFAULT}
          onClick={(): void => toComparison()}
        >
          {t(`quick/app_store:buttons.comparison`)}
        </ButtonBranded>
      </StyledAppStore.ContentFooter>
    </>
  );
};
