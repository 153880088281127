import initialData from "../initialState";
import { IWarmUpState, IWarmUpAction, IWarmUpTopics } from "../../../@types/index.d";

const warmUp = (state: IWarmUpState = initialData.warmUp, action: IWarmUpAction): IWarmUpState => {
  switch (action.type) {
    case IWarmUpTopics.FILE_TOPIC:
    case IWarmUpTopics.SUSA_TOPIC:
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          [action.scope]: action.value,
        },
      };

    default:
      return state;
  }
};

export default warmUp;
