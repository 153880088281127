import {
  Button,
  ButtonVariantEnums,
  Icon,
  IconNameEnums,
  IconSizeEnums,
  IDialogKeyEnums,
  useDevice,
  useDialogs,
} from "@canei/app-components";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components/macro";
import { EProductEnums, ILocalState } from "../../../@types/index.d";
import config from "../../../config";
import { useAppActivity } from "../../../_lib/hooks/appActivity";
import { EButtonText } from "../appStoreTypes.d";
import { EAppStoreMenuItem, useAppStore } from "../useAppStore";
import { useAppStoreHelper } from "../useAppStoreHelper";
// import { EAppStoreMenuItem } from "../useAppStore";
// import { useAppStoreHelper } from "../useAppStoreHelper";
import { StyledComparison } from "./styled.comparison-table";
import Table from "./Table";

interface IComparisonTableProps {
  logo?: EProductEnums;
  productId?: string;
  width?: string;
}

export const ComparisonTable: FC<IComparisonTableProps> = ({ logo, productId, width }) => {
  const { comparisonData, pricings } = useSelector((state: ILocalState) => state.appStore);
  const { getSubExists, getPrice } = useAppStoreHelper();
  const { t } = useTranslation("quick/app_store");
  const comparisonItems = t(`quick/app_store:comparison_table`, { returnObjects: true });
  const theme = useTheme();
  const { open: handleAppStoreDialog } = useAppStore();
  const { userVisitPage } = useAppActivity();
  const dialogs = useDialogs();

  // get price using getPrice function in useAppStoreHelper
  let price;
  if (productId) price = getPrice(productId as EAppStoreMenuItem);

  // define if we are using XLarge screen
  const { isXLarge } = useDevice();

  // find the rules for the current product
  const rules = comparisonData?.find((item) => item.logo === logo);

  // determine icon size
  const iconSize = isXLarge ? IconSizeEnums.SMALL : IconSizeEnums.XSMALL;

  // determine if a subscription exists
  let subExists = false;
  if (productId) subExists = getSubExists(productId as EAppStoreMenuItem);

  // function to open the appstore on the page of the product and close comparison dialog
  const handleToAppStore = useCallback((): void => {
    userVisitPage(config.app.app_store);
    handleAppStoreDialog({
      location: productId,
      closable: true,
    });
    dialogs.close(IDialogKeyEnums.BASIC);
  }, [dialogs, handleAppStoreDialog, productId, userVisitPage]);

  const isDisabled = useMemo<boolean>(() => {
    if (!pricings) return false;
    return pricings.some((pricing) => pricing.feature_id === productId && pricing.disabled);
  }, [pricings, productId]);

  // returns button
  const getButton = (): JSX.Element | null => {
    if (!pricings) return null;
    const buttonText = t(
      `quick/app_store:buttons.${subExists ? EButtonText.REMOVE : EButtonText.ADD}`
    );

    return (
      <Button
        inverted={false}
        type={"button"}
        inline={true}
        variant={subExists ? ButtonVariantEnums.DEFAULT : ButtonVariantEnums.PRIMARY}
        disabled={isDisabled}
        onClick={(): void => {
          handleToAppStore();
        }}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <>
      {productId ? (
        <>
          {isDisabled && (
            <StyledComparison.Ribbon>
              <p>{t(`quick/app_store:buttons.coming_soon`)}</p>
            </StyledComparison.Ribbon>
          )}
          <Table width={width} fontSize={isXLarge ? 1 : 0.83}>
            <Table.Head>
              <Table.TR>
                <Table.TH height={isXLarge ? 12 : 10} alignH={"center"}>
                  <StyledComparison.Logo
                    src={theme.branded.logo[logo as keyof typeof theme.branded.logo].dark}
                  />
                  <StyledComparison.Price>
                    <sup>€ </sup>
                    {price !== undefined ? price : "399"}
                    <span>/mo</span>
                  </StyledComparison.Price>
                  {getButton()}
                </Table.TH>
              </Table.TR>
            </Table.Head>
            <Table.Body>
              {/* create items from coparisonData key in config */}
              {config.comparisonData.map((item, i) => {
                // determine if the item processed is a report
                const isReport = item === "reports";

                // determine is item processed is an external item
                const isExternal = item === "circyoular" || item === "canei_pro";

                // determine is item processed is user management
                const isUserMgmt = item === "user";

                // if it is a normal item render checkmark or cross
                if (!isReport && !isExternal && !isUserMgmt)
                  return (
                    <Table.TR key={Math.random()}>
                      <Table.TD height={isXLarge ? 3.3 : 2} alignH={"center"}>
                        {/* if render rules have the item as true render checkmark, else render cross */}
                        {rules?.dashboardComponents[
                          item as keyof typeof rules.dashboardComponents
                        ] ? (
                          <Icon name={IconNameEnums.CHECK} size={iconSize} />
                        ) : (
                          <Icon name={IconNameEnums.Q_CLOSE_CIRCLE} size={iconSize} />
                        )}
                      </Table.TD>
                    </Table.TR>
                  );

                // if it is one of the external items
                if (isExternal)
                  return (
                    <Table.TR key={Math.random()}>
                      <Table.TD height={isXLarge ? 3.3 : 2} alignH={"center"}>
                        {/* if render rules have the item as true render checkmark, else render cross */}
                        {rules?.external && rules.external[item as keyof typeof rules.external] ? (
                          <Icon name={IconNameEnums.CHECK} size={iconSize} />
                        ) : (
                          <Icon name={IconNameEnums.Q_CLOSE_CIRCLE} size={iconSize} />
                        )}
                      </Table.TD>
                    </Table.TR>
                  );

                // if it is user management
                if (isUserMgmt)
                  return (
                    <Table.TR key={Math.random()}>
                      <Table.TD height={isXLarge ? 3.3 : 2} alignH={"center"}>
                        {/* if render rules have the item as true render checkmark, else render cross */}
                        {rules?.userMenuItems &&
                        rules.userMenuItems[item as keyof typeof rules.userMenuItems] ? (
                          <Icon name={IconNameEnums.CHECK} size={iconSize} />
                        ) : (
                          <Icon name={IconNameEnums.Q_CLOSE_CIRCLE} size={iconSize} />
                        )}
                      </Table.TD>
                    </Table.TR>
                  );

                // if it is a report
                if (isReport) {
                  // get a list of reports by checking render rules which ones are included
                  const reports =
                    rules?.reports &&
                    Object.keys(rules.reports).filter(
                      (report) => rules.reports[report as keyof typeof rules.reports]
                    );

                  return (
                    <Table.TR key={Math.random()}>
                      <Table.TD height={isXLarge ? 3.3 : 2} alignH={"center"} alignText={"center"}>
                        {/* if it is not only or last item add comma */}
                        {reports &&
                          reports.map((report, i) => {
                            return (
                              `${logo === EProductEnums.PROTECT ? "Kurzer " : ""}` +
                              comparisonItems[report as keyof typeof comparisonItems] +
                              `${reports.length > 1 && i !== reports.length - 1 ? ", " : ""}`
                            );
                          })}
                      </Table.TD>
                    </Table.TR>
                  );
                } else return null;
              })}
            </Table.Body>
          </Table>
        </>
      ) : (
        <Table width={width} fontSize={isXLarge ? 1 : 0.83}>
          <Table.Head>
            <Table.TR>
              <Table.TH height={isXLarge ? 12 : 10} alignH={"center"}></Table.TH>
            </Table.TR>
          </Table.Head>
          <Table.Body>
            {/* create items from coparisonData key in config */}
            {config.comparisonData.map((item, i) => (
              <Table.TR key={i}>
                <Table.TD height={isXLarge ? 3.3 : 2}>
                  {comparisonItems[item as keyof typeof comparisonItems]}
                </Table.TD>
              </Table.TR>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
