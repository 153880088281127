import styled, { css } from "styled-components/macro";
import { EQuickCheckCode, IQuickCheckItemProps } from "./index";

const QuickCheckContainer = styled.div(() => {
  return css`
    display: flex;
    justify-content: space-between;
  `;
});

const QuickCheckItem = styled.div(({ theme }) => {
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    flex-basis: calc(100% / 3 - ${theme.rem(1)});
    margin-bottom: ${theme.rem(1)};
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: ${theme.rem(1)};
  `;
});

const QuickCheckItemCaption = styled.div(({ theme }) => {
  return css`
    flex-basis: 100%;
    text-align: center;
    font-weight: ${theme.values.fontWeight.bold};
    margin-bottom: ${theme.rem(1)};
  `;
});

const QuickCheckItemIcon = styled.div<Pick<IQuickCheckItemProps, "state">>(({ theme, state }) => {
  const { first: error, second: success } = theme.values.colors.global.validation;
  const { third: initial } = theme.values.colors.global.greys;
  const color = {
    [EQuickCheckCode.INITIAL]: initial,
    [EQuickCheckCode.VALID]: success,
    [EQuickCheckCode.INVALID]: error,
  };

  return css`
    display: flex;
    flex-basis: 100%;
    text-align: center;
    align-self: flex-start;
    justify-content: center;
    color: ${color[state]};
  `;
});
const QuickCheckWarningIcon = styled.div(({ theme }) => {
  const error = theme.values.colors.global.validation.error;
  return css`
    color: ${error};
    margin-right: ${theme.rem(1)};
  `;
});

const InvalidTextWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
  `;
});

const KeyFactsContainer = styled.div(({ theme }) => {
  return css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: ${theme.rem(1)};
  `;
});

const KeyFactsItem = styled.div(({ theme }) => {
  return css`
    display: flex;
    flex-basis: max(calc(50% - ${theme.rem(1)}), ${theme.rem(20)});
    margin-bottom: ${theme.rem(0.5)};
  `;
});
const KeyFactsItemName = styled.div(() => {
  return css`
    flex-basis: 50%;
  `;
});
const KeyFactsItemAbsVal = styled.div(() => {
  return css`
    flex-basis: 25%;
    text-align: right;
  `;
});
const KeyFactsItemPercentVal = styled.div(() => {
  return css`
    flex-basis: 25%;
    text-align: right;
  `;
});

const DialogFooter = styled.div(() => {
  return css`
    justify-content: center;
    text-align: center;
  `;
});

export const StyledUploadValidationSummary = {
  QuickChek: {
    Container: QuickCheckContainer,
    Item: QuickCheckItem,
    Caption: QuickCheckItemCaption,
    Icon: QuickCheckItemIcon,
    InvalidText: InvalidTextWrapper,
    WarningIcon: QuickCheckWarningIcon,
  },
  KeyFacts: {
    Container: KeyFactsContainer,
    Item: KeyFactsItem,
    ItemName: KeyFactsItemName,
    ItemAbs: KeyFactsItemAbsVal,
    ItemPercent: KeyFactsItemPercentVal,
  },
  DialogFooter,
};
