import { createRef, DragEvent, FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonVariantEnums,
  FormContext,
  FormDispatch,
  IconButton,
  IFileUploaderProps,
  Icon,
  IconNameEnums,
  IconSizeEnums,
  useFileUploader,
} from "@canei/app-components";

import { StyledMultiUpload } from "../DatevUpload/styled.multi-upload";

import { useIsMultipleUploadInProgress } from "./sharedHooks";
import { EUploadersActionTypes, UploadersContext, UploadersDispatch } from "../index";

export interface ISuSaUploadItem extends Pick<IFileUploaderProps, "name"> {
  month: string;
}

const SuSaUploadItem: FC<ISuSaUploadItem> = ({ month, name }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const inProgress = useIsMultipleUploadInProgress();
  const fileInputRef = createRef<HTMLInputElement>();
  const { handleDragOver, handleFileDrop, handleFileChange, getFileContent, resetFileContent } =
    useFileUploader({
      name,
    });
  const formContext = useContext(FormContext);
  const formDispatch = useContext(FormDispatch);

  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);

  const handleFileDragActive = (e: DragEvent<HTMLDivElement>): void => {
    setIsDraggingOver(true);
    handleDragOver(e);
  };
  const handleFileDragInActive = (): void => {
    setIsDraggingOver(false);
  };
  const handleFileDropped = (e: DragEvent<HTMLDivElement>): void => {
    setIsDraggingOver(false);
    handleFileDrop(e);
  };
  const handleUnSelectFile = (): void => {
    resetFileContent(name, formDispatch);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    uploadersDispatch({
      type: EUploadersActionTypes.REMOVE_FILE,
      payload: { name },
    });
  };
  const selectedFile = useMemo(() => {
    return getFileContent(name, formContext);
  }, [name, formContext, getFileContent]);
  const selectedFileName = useMemo(() => {
    return selectedFile.name;
  }, [selectedFile]);
  const isFileSelected = useMemo(() => {
    return selectedFile.size > 0;
  }, [selectedFile]);

  const uploadState = uploadersContext.sharedState.fileUploadState[name]?.state;

  // updateSharedContext({ client_id, fileName: name, fileType: selectedFileType });
  useEffect(() => {
    if (isNaN(selectedFile.size)) return;

    uploadersDispatch({
      type: EUploadersActionTypes.UPSERT_FILE,
      payload: {
        year: formContext.multi_year,
        name,
        file: selectedFile,
      },
    });
  }, [name, month, uploadersDispatch, selectedFile, formContext.multi_year]);

  return (
    <StyledMultiUpload.InnerWrapper>
      <StyledMultiUpload.ItemWrapper
        dragging={isDraggingOver}
        selected={isFileSelected}
        state={uploadState}
      >
        <StyledMultiUpload.FileInput
          type="file"
          onDragOver={handleFileDragActive}
          onDragEnd={handleFileDragInActive}
          onDragExit={handleFileDragInActive}
          onDrop={handleFileDropped}
          onDragLeave={handleFileDragInActive}
          onChange={handleFileChange}
          accept="application/pdf"
          ref={fileInputRef}
          disabled={inProgress}
        />
        {isFileSelected && !inProgress && (
          <StyledMultiUpload.DeleteWrapper>
            <IconButton
              icon={<Icon name={IconNameEnums.TRASH} size={IconSizeEnums.SMALL} />}
              inverted={false}
              variant={ButtonVariantEnums.WARNING}
              type={"button"}
              onClick={handleUnSelectFile}
            />
          </StyledMultiUpload.DeleteWrapper>
        )}
        <Icon name={IconNameEnums.PDF} size={IconSizeEnums.LARGE} />
        <StyledMultiUpload.MonthLabel>
          {month} {formContext.multi_year}
        </StyledMultiUpload.MonthLabel>
      </StyledMultiUpload.ItemWrapper>
      <StyledMultiUpload.FileName>{selectedFileName} </StyledMultiUpload.FileName>
    </StyledMultiUpload.InnerWrapper>
  );
};

export const MultiSuSaFiles: FC = () => {
  const { t } = useTranslation();
  const formContext = useContext(FormContext);

  const months = useMemo<ISuSaUploadItem[]>(() => {
    const year = parseInt(formContext.multi_year);
    const monthsList: string[] = t("misc.months.long", { returnObjects: true });
    if (isNaN(year)) return [];
    const currentDate = new Date();
    const list =
      year < currentDate.getFullYear()
        ? monthsList
        : monthsList.slice(0, currentDate.getMonth() + 1);

    return list.map((item, index) => {
      return {
        month: item,
        name: `0${index + 1}/${year}`.slice(-7),
      };
    });
  }, [t, formContext.multi_year]);

  return (
    <StyledMultiUpload.Wrapper>
      {months.map((props, index) => (
        <SuSaUploadItem key={index} {...props} />
      ))}
    </StyledMultiUpload.Wrapper>
  );
};
