import { FC, useContext } from "react";
import {
  AlignmentEnums,
  FormRow,
  IFileUploaderProps,
  IMonthPickerProps,
} from "@canei/app-components";

import { SingleSuSaDate, SingleSuSaFile } from "../UploadersSharedContext";
import { Trans, useTranslation } from "react-i18next";
import { P } from "../../../components/Typography";
import { Link } from "react-router-dom";
import { EUploadersActionTypes, UploadersContext, UploadersDispatch } from "..";

export interface ISimplePDFUpload {
  file: Pick<IFileUploaderProps, "disabled" | "valid">;
  date: Pick<IMonthPickerProps, "disabled" | "valid">;
}

export const SinglePDFUpload: FC<Pick<IFileUploaderProps, "disabled">> = ({ disabled = false }) => {
  const { t } = useTranslation();
  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const { isInitial } = uploadersContext;

  const handleSwitchToMultipleUpload = (): void => {
    uploadersDispatch({
      type: EUploadersActionTypes.SET_UPLOAD_TYPE,
      payload: { data: { multipleUpload: true } },
    });
  };
  return (
    <>
      <FormRow align={AlignmentEnums.STRETCH}>
        <P>
          <Trans
            i18nKey={`setup.upload_form_2.datev.${
              isInitial ? "select_file_initial" : "select_file_single"
            }`}
            values={{ step: 2 }}
          >
            <Link to="#" onClick={handleSwitchToMultipleUpload}>
              load more with Multi-Upload
            </Link>
          </Trans>
        </P>
      </FormRow>

      <FormRow align={AlignmentEnums.STRETCH}>
        <SingleSuSaFile
          name="pdfFile"
          label={t("setup.upload_form_2.datev.label.pdf_file")}
          placeholder={t("setup.upload_form_2.datev.placeholder.pdf_file")}
          required={true}
          invalidWarning={t("misc.required_field")}
          accept="application/pdf"
          valid={uploadersContext.sharedState.fileSelected !== false}
          disabled={disabled}
        />
      </FormRow>

      <FormRow align={AlignmentEnums.STRETCH}>
        <SingleSuSaDate
          data={[]}
          name={"deadline"}
          label={t("setup.upload_form_2.datev.label.deadline")}
          placeholder={t("setup.upload_form_2.datev.placeholder.deadline")}
          invalidWarning={t("misc.required_field")}
          valid={uploadersContext.sharedState.dateSelected !== false}
          disabled={disabled}
        />
      </FormRow>
    </>
  );
};
