import { FC, useContext, useEffect } from "react";
import { StyledClientRegister } from "./styled.setup-content";
import { ClientData } from "./ClientData";
import { RevenuePlan } from "./RevenuePlan";
import { VideoHelp } from "./VideoHelp";

import { ClientSetupContext, ClientSetupDispatch } from "./index";
import { DraftClientSetup } from "../../../@types/index.d";
import { Uploaders } from "../../../containers/Uploaders";

import { RevenueRatios } from "../../../components/RevenueRatios";
import { useRevenueRatios } from "../../../components/RevenueRatios/useRevenueRatios";

export const setupStepRegisterTestID = "SetupStepRegister";
export const setupStepUploadTestID = "SetupStepUpload";

const SetupStepRegister: FC = () => {
  const setupContext = useContext(ClientSetupContext);
  const setupDispatch = useContext(ClientSetupDispatch);
  const revenueContext = useRevenueRatios(setupContext.customization.plan_settings);

  useEffect(() => {
    if (revenueContext === undefined) return;

    if (!Object.is(revenueContext.context, setupContext.customization.plan_settings)) {
      setupDispatch({
        ...setupContext,
        customization: {
          ...setupContext.customization,
          plan_settings: revenueContext.context,
        },
      });
    }
  }, [revenueContext, setupContext, setupDispatch]);

  return (
    <>
      <StyledClientRegister>
        <ClientData />
        <RevenuePlan>
          <RevenueRatios {...revenueContext} />
        </RevenuePlan>

        <VideoHelp />
      </StyledClientRegister>
    </>
  );
};
const SetupStepUpload: FC = () => <Uploaders isInitial={true} />;

const SetupContent: FC = () => {
  const setupContext = useContext(ClientSetupContext);

  switch (setupContext.state) {
    case DraftClientSetup.DATA:
    case DraftClientSetup.DATA_PROGRESS:
      return <SetupStepRegister />;
    case DraftClientSetup.UPLOAD:
    case DraftClientSetup.UPLOAD_PROGRESS:
      return <SetupStepUpload />;
  }
};

export default SetupContent;
