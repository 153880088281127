import { DraftClient } from "./amplify";
import { EProductEnums, IBrandedValues, LayoutEnums } from "./styled";
import { FileUploadFormat } from "./uploads";
import { IUploadFileState } from "../_lib/store/reducers";
import { IUserInfo, ISystemMessage, AuthError } from "@canei/app-components";
import {
  ICoupon,
  ICustomerData,
  IFeaturesDataRetVal,
  IBillingDetailedAddress,
  ITreeData,
} from "../_lib/hooks/appStore/appStoreTypes";
import { EQMenuItem } from "./components";
import { EAppStoreMenuItem } from "../containers/QAppStore";

export interface ILocalState {
  //__typename: TypeNameEnums.INITIAL_DATA;
  currentUser: IUserInfo;
  appStore: IAppStoreInfo;
  errorState: {
    //__typename: TypeNameEnums.AUTH_ERROR;
    errors: AuthError[];
  };
  draftClient: DraftClient;
  themeOptions: IThemeOptions;
  dataSync: IDataSync;
  warmUp: IWarmUpState;
  downloads: TDownloadsState;
  uploads: IUploadFileState[];
  systemMessages: ISystemMessage[];
  renderRules: IRenderRules;
}

export interface IAppStoreInfo {
  subscriptions?: ISubscription[];
  paymentType?: string;
  features?: IFeatures[];
  pricings?: IPricing[];
  customerData?: ICustomerData;
  couponList?: ICoupon[];
  featuresData?: IFeaturesDataRetVal;
  promotionsData?: IGetPromotionsData[];
  billingAddress?: IBillingDetailedAddress;
  content?: Record<EAppStoreMenuItem, IFeatureContentItem>;
  selectedMenuItem: EAppStoreMenuItem;
  paymentWall?: boolean;
  menuSubFeatures: ITreeData | undefined;
  comparisonData?: IRenderRules[];
}

export interface ISubscription {
  subscription_id: string;
  customer_id: string;
  product_id: EProduct;
  pricing_id: EPricing;
  feature_id: string;
  links: RelationLink[];
  status: ESubscriptionStatus;
  start_date: number;
  trial_end: number;
  trial_start: number;
}

export interface IPricing {
  pricing_id: string;
  trial_days: number;
  price: number;
  default: boolean;
  disabled?: boolean;
  feature_id?: string;
  product_id?: string;
  subscription: boolean;
  page?: boolean;
  page_type?: string;
}

export interface IFeatures {
  feature_id: string;
  pricing_id: string;
  status?: string;
  tree?: IFeatureInfo[];
  start_date?: number;
  trial_end?: number;
  canceled_at?: number;
}

export interface IFeatureInfo {
  value: string;
  key: string;
  type: string;
}

export type IDataSync = Record<EDataSyncLevel, Record<EDataSyncScope, IDataChangedState>>;

export interface IDataSyncOptions {
  level: EDataSyncLevel;
  scope: EDataSyncScope;
  refreshRate?: EDataSyncRefreshRate;
  retryUntil?: number;
}
export enum EDataSyncLevel {
  // ACCOUNT = "ACCOUNT",
  CUSTOMER = "CUSTOMER",
  CLIENT = "CLIENT",
  // USER = "USER",
}
export interface IRenderRules {
  dashboardComponents: Record<EDashboardComponents, boolean>;
  dashboardGrid: EDashboardComponents[];
  reports: Record<EReportCodes, boolean>;
  menuItems: Record<EQMenuItem, boolean>;
  userMenuItems: Record<EUserProfileItems, boolean>;
  logo: EProductEnums;
  external?: Record<string, boolean>;
  runLoadingSpinner?: boolean;
}

export enum EDashboardComponents {
  OVERVIEW = "overview",
  OVERVIEW_CARDS = "overview_cards",
  KPIS = "kpis",
  SUCCESS = "success",
  ASSETS = "assets",
  // DEBTS = "debts",
  PROFITLOSS = "profitloss",
  LIQUIDITY = "liquidity",
  ABNORMALITIES = "abnormalities",
  BANKERS = "bankers",
  GRADE = "grade",
  COSTS = "costs",
  OFFER = "offer",
  UPGRADE = "upgrade",
}

export enum EDashboardComponentsMini {
  OVERVIEW = "overview_protect",
  OVERVIEW_CARDS = "overview_cards",
  // UPGRADE = "upgrade",
}

export enum EReportCodes {
  RP004 = "RP004", // Monatsbericht
  RP005 = "RP005", // Monatszeugnis
  RP008 = "RP008", // Potentialübersicht
  RP009 = "RP009", // Risikoanalyze
  RP010 = "RP010", // Bankersperspective
  RP011 = "RP011", // Liquiditätsbericht
  RP013 = "RP013", // ESG Bericht
  RP014 = "RP014", // Ertragsbericht
}

export enum EReportConfigDefs {
  RP011 = "LIQUIDITY", // Liquiditätsbericht
  RP013 = "ESG", // ESG Bericht
  RP014 = "PROFITLOSS", // Ertragsbericht
}

export enum EUserProfileItems {
  COMPANY = "company",
  FEATURE_STORE = "feature_store",
  SUSA_LIST = "susa_list",
  USER = "user",
  INVOICES = "invoices",
  LOGOUT = "logout",
}
export enum EDataSyncScope {
  CUSTOMER = "customer",
  SUBSCRIPTION = "subscription",
  WALLET = "wallet",
  CLIENT = "client",
  LOGO = "logo",
  FILE = "file",
  SUSA = "susa",
  KPI = "kpi",
  EVAL = "eval",
  ERROR = "error",
  PLAN = "plan",
  ALERT = "alert",
  PAGE = "page",
}

export enum EDataSyncTypes {
  ADD = "add",
  UPSERT = "upsert",
  UPLOAD = "upload",
  DELETE = "delete",
  WARMUP = "warmup",
  DOWNLOAD = "download",
  LOGIN = "login",
  VISIT = "visit",
  LOGOUT = "logout",
  REMOVE = "remove",
  FLUSH = "flush",
  SYNC = "sync",
  BUY = "buy",
  CANCEL = "cancel",
}

export interface IThemeOptions {
  //__typename: TypeNameEnums.THEME_OPTIONS;
  layout: LayoutEnums;
  branded: IBrandedValues;
  sideNavExpanded: boolean;
}

export interface IDataChangedState {
  timeStamp: number;
  pending: boolean;
  timeout: boolean;
  refreshRate: EDataSyncRefreshRate;
  retryUntil?: number;
}
export enum EDataSyncRefreshRate {
  IDLE = 0,
  FAST = 3000,
  MEDIUM = 10000,
  SLOW = 30000,
}
export interface IDataSyncAction {
  type: DataSyncStateActionTypes;
  payload: IDataSyncActionPayload;
}
export interface IDataSyncActionPayload extends IDataSyncOptions {
  state?: Partial<Pick<IDataChangedState, "timeStamp" | "timeout">>;
}
export enum DataSyncStateActionTypes {
  SET_TIMESTAMP = "SET_TIMESTAMP",
  SET_PENDING = "SET_PENDING",
  SET_READY = "SET_READY",
  SET_TIMEOUT = "SET_TIMEOUT",
  SET_REFRESH_RATE = "SET_REFRESH_RATE",
  RESET = "RESET",
  RESET_ALL = "RESET_ALL",
}
export interface IWarmUpAction {
  type: IWarmUpTopics;
  scope: IWarmUpScopes;
  value: number;
}
export enum IWarmUpTopics {
  SUSA_TOPIC = "SUSA_TOPIC",
  FILE_TOPIC = "FILE_TOPIC",
}
export enum IWarmUpScopes {
  CUSTOMER = "customer",
  CLIENT = "client",
  USER = "user",
  LOGO = "logo",
  FILE = "file",
  SUSA = "susa",
  KPI = "kpi",
  EVAL = "eval",
  ERROR = "error",
}

export type IWarmUpState = Record<IWarmUpTopics, IWarmUpScopeState>;
export type IWarmUpScopeState = Record<IWarmUpScopes, number>;
export interface IDownloadsAction {
  type: IDownloadsActionType;
  payload: IDownloadFile;
}

export enum IDownloadsActionType {
  ADD_DOWNLOAD = "ADD_DOWNLOAD",
  REMOVE_DOWNLOAD = "REMOVE_DOWNLOAD",
  EDIT_DOWNLOAD = "EDIT_DOWNLOAD",
  ADD_ERROR = "ADD_ERROR",
}
export interface IDownloadFile {
  identifier: string;
  name: string;
  url: string;
  format: FileUploadFormat;
  error?: string;
  isDownloading: boolean;
  downloadUrl?: string;
}
export type TDownloadsState = IDownloadFile[];
