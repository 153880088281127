import { DraftClient, DraftClientStateActionTypes } from "../../../@types/index.d";
import initialData from "../initialState";

export interface IDraftClientAction {
  type: DraftClientStateActionTypes;
  payload: Partial<DraftClient>;
}

const draftClient = (
  state: DraftClient = initialData.draftClient,
  action: IDraftClientAction
): DraftClient => {
  switch (action.type) {
    case DraftClientStateActionTypes.SET_DRAFT_CLIENT:
      return {
        ...state,
        ...action.payload,
      };
    case DraftClientStateActionTypes.CLEAR_STORE:
    case DraftClientStateActionTypes.RESET_DRAFT_CLIENT:
      return initialData.draftClient;

    default:
      return state;
  }
};

export default draftClient;
