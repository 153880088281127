import { useContext, useMemo } from "react";
import { EFileUploadState, UploadersContext } from "../../index";

export const useMultipleUploadSucceeded = (): boolean => {
  const uploadersContext = useContext(UploadersContext);
  const fileListInMultiUploadContext = Object.keys(uploadersContext.sharedState.files);
  return useMemo((): boolean => {
    if (fileListInMultiUploadContext.length === 0) return false;
    return (
      Object.values(uploadersContext.sharedState.fileUploadState).filter(
        ({ state }) => state === EFileUploadState.UPLOADED
      ).length === fileListInMultiUploadContext.length
    );
  }, [uploadersContext.sharedState.fileUploadState, fileListInMultiUploadContext]);
};
