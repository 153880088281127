import { createContext, Dispatch } from "react";
import {
  FileData,
  EClientFiscalYear,
  EventErrorsCodes,
  FileUploadFormat,
  IQProgressProps,
  ELegalForms,
  FileUploadVariant,
} from "@canei/app-components";
import { IQRegisterFormState } from "../../Public/Register";
import {
  EPreviewErrorCodes,
  ISusaUploadContext,
  IUploadMethods,
} from "../../../containers/Uploaders";

export enum EQuickSetupSteps {
  INITIAL = "INITIAL",
  COMPANY_DATA = "COMPANY_DATA",
  FINANCIAL_PLANNING = "FINANCIAL_PLANNING",
  NEXT_STEP = "NEXT_STEP",
  DATA_IMPORT = "DATA_IMPORT",
  DONE = "DONE",
}

export enum ETriState {
  INITIAL,
  TRUE,
  FALSE,
}

export interface IQuickCompanyData {
  susa_date?: string;
  file?: FileData;
  format?: FileUploadFormat.PDF_DATEV_PRO;
}

export interface IQSetupData {
  progress: IQProgressProps<EQuickSetupSteps>;
  company_name: string;
  founding_year: number | undefined;
  postal_code: string | undefined;
  trade_registration: string | undefined;
  fiscal_year: EClientFiscalYear | undefined;
  legal_form: ELegalForms | undefined;
  category: string;
  category_type: string | undefined;
  form_state: IQRegisterFormState;
  susa_date?: string;
  konto_kredit?: string | undefined;
  umsatz_linie?: string | undefined;
  show_next_step: boolean;
  isCompanyDataValid: Record<keyof IQuickCompanyData, ETriState>;
  file?: FileData;
  loading?: boolean;
  canUpload: boolean;
  transactionError?: EventErrorsCodes;
  errMsg?: string | undefined;
  format?: FileUploadFormat.PDF_DATEV_PRO;
  showAnimation: boolean;
  uploadMethod?: IUploadMethods;
  excelUpload: ISusaUploadContext;
}

export interface IQSetupAction {
  type: EQSetupActionTypes;
  payload: Partial<IQSetupData>;
}

export enum EQSetupActionTypes {
  UPDATE_DATA = "UPDATE_DATA",
  SET_FISCAL_YEAR = "SET_FISCAL_YEAR",
  SET_SUSA_DATE = "SET_SUSA_DATE",
  SET_UPLOAD_METHOD = "SET_UPLOAD_METHOD",
  PREV_STEP = "PREV_STEP",
  NEXT_STEP = "NEXT_STEP",
  UPLOAD_SUCCEEDED = "UPLOAD_SUCCEEDED",
  SET_SUSA_FILE = "SET_SUSA_FILE",
  VALIDATE_COMPANY_DATA = "VALIDATE_COMPANY_DATA",
  SET_TRANSACTION_STATE = "SET_TRANSACTION_STATE",
  SET_ERR_MSG = "SET_ERR_MSG",
  CANCEL_LOADING_SPINNER = "CANCEL_LOADING_SPINNER",
  UPDATE_SETUP_STATUS = "UPDATE_SETUP_STATUS",
}

export const excelUploadInitialData: ISusaUploadContext = {
  // key: Date.now(), // use to force rerender form element after successful upload
  client_id: "",
  canUpload: false,
  process: EventErrorsCodes.UNKNOWN,
  uploading: false,
  previewLoading: false,
  arrangementDone: false,
  isReady: false,
  savePreferences: false,
  fileSelected: null,
  dateSelected: null,
  previewErrorCode: EPreviewErrorCodes.OK,
  upload_format: undefined,
  settings: {
    format: null,
    variant_type: FileUploadVariant.UNKNOWN,
  },
  file: {
    client_id: "",
    name: "",
    lastModified: "",
    size: 0,
    type: "",
    links: [],
    date: null,
    content: "",
  },
  files: {},
  fileUploadState: {},
  uploadMethod: undefined,
};

export const initialSetupData: IQSetupData = {
  company_name: "",
  founding_year: undefined,
  trade_registration: undefined,
  postal_code: undefined,
  category_type: undefined,
  fiscal_year: undefined,
  category: "",
  legal_form: undefined,
  konto_kredit: undefined,
  umsatz_linie: undefined,
  show_next_step: false,
  errMsg: "",
  loading: false,
  canUpload: false,
  progress: {
    steps: [
      { label: "Anmeldung", stepId: EQuickSetupSteps.INITIAL },
      { label: "Unternehmensdaten", stepId: EQuickSetupSteps.COMPANY_DATA },
      { label: "Finanzplanungsdaten", stepId: EQuickSetupSteps.FINANCIAL_PLANNING },
      { label: "Datenimport", stepId: EQuickSetupSteps.DATA_IMPORT },
      { label: "Los geht's", stepId: EQuickSetupSteps.DONE },
    ],
    currentStep: EQuickSetupSteps.INITIAL,
  },
  isCompanyDataValid: {
    susa_date: ETriState.INITIAL,
    file: ETriState.INITIAL,
    format: ETriState.TRUE,
  },
  form_state: {
    initial: true,
    valid: false,
  },
  showAnimation: true,
  uploadMethod: IUploadMethods.DATEV,
  excelUpload: excelUploadInitialData,
};

export const QuickSetupContext = createContext<IQSetupData>(initialSetupData);

export const QuickSetupDispatch = createContext<Dispatch<IQSetupAction>>((state) => state);

export const newSetupFlowReducer = (state: IQSetupData, action: IQSetupAction): IQSetupData => {
  const validateCompanyData = (): IQSetupData => {
    const canUpload =
      Object.values(state.isCompanyDataValid).filter((v) => v !== ETriState.TRUE).length === 0;

    return {
      ...state,
      canUpload,
      isCompanyDataValid: {
        ...state.isCompanyDataValid,
        susa_date:
          state.isCompanyDataValid.susa_date === ETriState.TRUE ? ETriState.TRUE : ETriState.FALSE,
        file: state.isCompanyDataValid.file === ETriState.TRUE ? ETriState.TRUE : ETriState.FALSE,
        format: ETriState.TRUE,
      },
    };
  };

  switch (action.type) {
    case EQSetupActionTypes.UPDATE_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case EQSetupActionTypes.SET_FISCAL_YEAR: {
      const { fiscal_year } = action.payload || {};
      if (fiscal_year === undefined) return state;
      return {
        ...state,
        fiscal_year,
      };
    }
    case EQSetupActionTypes.SET_SUSA_DATE: {
      const { susa_date } = action.payload || {};
      if (susa_date === undefined) return state;
      return {
        ...state,
        susa_date,
        isCompanyDataValid: { ...state.isCompanyDataValid, susa_date: ETriState.TRUE },
      };
    }
    case EQSetupActionTypes.SET_UPLOAD_METHOD: {
      const { uploadMethod } = action.payload;
      if (!uploadMethod) return state;

      return {
        ...state,
        uploadMethod,
      };
    }
    case EQSetupActionTypes.SET_SUSA_FILE: {
      if (action.payload.file === undefined)
        return {
          ...state,
          file: undefined,
          canUpload: false,
          isCompanyDataValid: { ...state.isCompanyDataValid, file: ETriState.FALSE },
        };
      return {
        ...state,
        file: action.payload.file,
        isCompanyDataValid: { ...state.isCompanyDataValid, file: ETriState.TRUE },
      };
    }
    case EQSetupActionTypes.UPLOAD_SUCCEEDED: {
      return {
        ...state,
        progress: { ...state.progress, currentStep: EQuickSetupSteps.DONE },
        loading: false,
      };
    }
    case EQSetupActionTypes.UPDATE_SETUP_STATUS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case EQSetupActionTypes.VALIDATE_COMPANY_DATA: {
      return validateCompanyData();
    }
    case EQSetupActionTypes.SET_TRANSACTION_STATE:
      return { ...state, transactionError: action.payload.transactionError };
    case EQSetupActionTypes.CANCEL_LOADING_SPINNER:
      return { ...state, loading: false };
    case EQSetupActionTypes.SET_ERR_MSG:
      return { ...state, errMsg: action.payload.errMsg };
    default:
      return state;
  }
};
