import { FC, useContext, useEffect, useMemo } from "react";
import {
  Button,
  ButtonVariantEnums,
  CheckBox,
  ECheckboxState,
  FormDispatch,
  FormRow,
  AlignmentEnums,
  Alert,
  AlertTypeEnums,
  EventErrorsCodes,
  SlideIn,
  SlideInDirections,
  EAutoCloseTimeOut,
  resetFileContent,
} from "@canei/app-components";

import { Trans, useTranslation } from "react-i18next";

import { ILocalState } from "../../../../@types/index.d";
import { useUploadReviewedFile } from "../../../../_lib/hooks";

import {
  EFileUploadState,
  EUploadersActionTypes,
  UploadersContext,
  UploadersDispatch,
} from "../../index";
import { useSingleUploadSucceeded } from "../../UploadersSharedContext/sharedHooks";

import { useSelector } from "react-redux";
import { useSystemMessages } from "../../../../_lib/hooks/useSystemMessages";

export const UploadReviewedFile: FC = () => {
  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const { currentUser } = useSelector((state: ILocalState) => state);
  const singleUploadSucceeded = useSingleUploadSucceeded();
  const formDispatch = useContext(FormDispatch);
  const { addSystemMessage } = useSystemMessages();
  const [upload, { loading: waitUpload, data }, errorState] = useUploadReviewedFile();
  const { t } = useTranslation();
  const { client_id } = currentUser.selectedClient;
  const hasError = useMemo(() => {
    if (!data?.result || !errorState) return false;
    const currentErrorState = errorState[data?.result.covid];
    return (
      typeof currentErrorState === "string" &&
      currentErrorState !== EventErrorsCodes.UNKNOWN &&
      currentErrorState !== EventErrorsCodes.TRANSACTION_OK
    );
  }, [data?.result, errorState]);

  const previewedFileUploading = useMemo(() => {
    if (!data?.result || !errorState) return false;
    const currentErrorState = errorState[data?.result.covid];
    if (waitUpload || currentErrorState === EventErrorsCodes.UNKNOWN) return true;
    if (!uploadersContext.sharedState.file.date) return false;
    return (
      uploadersContext.sharedState.fileUploadState[uploadersContext.sharedState.file.date].state ===
      EFileUploadState.UPLOADING
    );
  }, [
    data?.result,
    errorState,
    waitUpload,
    uploadersContext.sharedState.file.date,
    uploadersContext.sharedState.fileUploadState,
  ]);

  const handleUpload = (): void => {
    if (!client_id) throw new Error("Client must be selected!");

    upload({
      ...uploadersContext.sharedState,
      client_id,
    });
  };

  useEffect(() => {
    if (singleUploadSucceeded) {
      resetFileContent("textFile", formDispatch);
      addSystemMessage({
        identifier: uploadersContext.sharedState.file.name,
        type: AlertTypeEnums.SUCCESS,
        code: "Upload succeed!",
        autoCloseTimeout: EAutoCloseTimeOut.MEDIUM,
        Message: <>Upload succeed!</>,
      });
      uploadersDispatch({ type: EUploadersActionTypes.RESET, payload: {} });
    }
  }, [
    singleUploadSucceeded,
    uploadersDispatch,
    formDispatch,
    uploadersContext.sharedState.file.name,
    addSystemMessage,
  ]);

  useEffect(() => {
    if (hasError && !previewedFileUploading) {
      addSystemMessage({
        type: AlertTypeEnums.ERROR,
        code: `errors:${errorState}`,
        autoCloseTimeout: EAutoCloseTimeOut.MEDIUM,
        identifier: `${uploadersContext.sharedState.file.name}-${errorState}`,
        Message: <Trans i18nKey={`errors:${errorState}`} />,
      });
    }
  }, [
    hasError,
    previewedFileUploading,
    addSystemMessage,
    uploadersContext.sharedState.file.name,
    errorState,
  ]);

  const handleSavePreferences = (savePreferences: ECheckboxState): void => {
    uploadersDispatch({
      type: EUploadersActionTypes.SET_STATE,
      payload: {
        data: { savePreferences: savePreferences === ECheckboxState.ON },
      },
    });
  };

  return (
    <>
      <FormRow align={AlignmentEnums.STRETCH}>
        <CheckBox
          variant={ButtonVariantEnums.DEFAULT}
          inverted={false}
          checked={
            uploadersContext.sharedState.savePreferences ? ECheckboxState.ON : ECheckboxState.OFF
          }
          onChange={handleSavePreferences}
        >
          {t("setup.upload_form_2.excel.save_settings")}
        </CheckBox>
      </FormRow>
      <FormRow align={AlignmentEnums.LEFT}>
        {previewedFileUploading && (
          <SlideIn direction={SlideInDirections.TO_LEFT}>
            <Alert type={AlertTypeEnums.INFO}>
              <Trans i18nKey="setup.upload_form_2.datev.messages.wait_for_upload" />
            </Alert>
          </SlideIn>
        )}
      </FormRow>
      <FormRow align={AlignmentEnums.LEFT}>
        <Button
          variant={ButtonVariantEnums.PRIMARY}
          inverted={false}
          type={"button"}
          onClick={handleUpload}
          inline={true}
          disabled={previewedFileUploading}
        >
          {t("setup.upload_form_2.excel.submit.upload")}
        </Button>
      </FormRow>
    </>
  );
};
