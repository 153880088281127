import { useSelectedClientData } from "../../_lib/hooks";
import { Reducer, useEffect, useReducer, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import config from "../../config";
import { ERevenueCurves, IRevenuePlan, IRevenueRatios } from "@canei/app-components";
import initialData from "../../_lib/store/initialState";
import { IRevenueRatiosProps } from "./index";

const GET_DEFAULT_RATIOS = gql`
  {
    getDefaultRatios {
      value
      revenue_ratios {
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
      }
    }
  }
`;
export interface IDefaultRevenueRatio {
  value: ERevenueCurves;
  revenue_ratios: IRevenueRatios;
}

interface IUseDefaultRevenueRatios {
  defaults: IDefaultRevenueRatio[] | undefined;
  initialRevenue: IRevenuePlan | undefined;
}
export const useDefaultRevenueRatios = (): IUseDefaultRevenueRatios | undefined => {
  const { data } = useQuery<{ getDefaultRatios: IDefaultRevenueRatio[] }>(GET_DEFAULT_RATIOS);

  if (data?.getDefaultRatios === undefined) return;

  const revenue_ratios = data.getDefaultRatios?.find(
    ({ value }) => value === config.webapp.revenue_curve
  )?.revenue_ratios;

  if (revenue_ratios === undefined) return;

  return {
    defaults: data?.getDefaultRatios,
    initialRevenue: {
      revenue_ratios,
      revenue_curve: config.webapp.revenue_curve as ERevenueCurves,
      revenue_growth: config.webapp.revenue_growth as number,
    },
  };
};

export const useRevenueRatios = (initial?: IRevenuePlan): IRevenueRatiosProps => {
  const selectedClientData = useSelectedClientData();
  const [clientId, setClientId] = useState<string | undefined>();
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [context, dispatch] = useReducer<Reducer<IRevenuePlan, Partial<IRevenuePlan>>>(
    (state: IRevenuePlan, action: Partial<IRevenuePlan>) => ({ ...state, ...action }),
    {
      ...initialData.draftClient.customization.plan_settings,
      ...initial,
    }
  );

  useEffect(() => {
    if (!shouldUpdate) return;
    if (selectedClientData === false) return;
    if (selectedClientData.customization.plan_settings === undefined) return;

    setShouldUpdate(false);
    dispatch(selectedClientData.customization.plan_settings);
  }, [shouldUpdate, selectedClientData]);
  useEffect(() => {
    if (selectedClientData === false) return;
    if (selectedClientData.client_id === clientId) return;
    setClientId(selectedClientData.client_id);
    setShouldUpdate(true);
  }, [clientId, selectedClientData]);
  return { context, dispatch };
};
