import { FC, useEffect } from "react";
import { IDialogKeyEnums, useDialogContext, useDialogs } from "@canei/app-components";
import { StyledAppStore } from "./styled.appStore-page";
import { IAppStoreContext, IAppStoreProps } from "./appStoreTypes";
import { useAppStoreHelper } from "./useAppStoreHelper";
import { ILocalState } from "../../@types";
import { AppStoreMenu } from "./Menu";
import { AppStoreContent } from "./Content";
import { useSelector } from "react-redux";

export enum EAppStoreMenuItem {
  OVERVIEW = "overview", // Übersicht
  PROTECT = "canei_quick", // CANEI.protect
  LITE = "canei_quick_lite", // CANEI.lite
  CONTROL = "canei_quick_plus", // CANEI.CONTROL
  PRO = "canei_pro", // CANEI.pro
  LIQUIDITY = "liquidity_planning", // Liquiditätplanung
  ERTRAG = "profitloss_planning", // Ertragsplanung
  REPORTS = "reporting", // Liquiditätplanung
  MULTI = "user_management", // Mehrbenutzer
  // CREDITREFORMSCORE = "creditreform_score", // Creditreform Score
  CIRCYOULAR = "circyoular_analysis", // CIRCYOULAR®
  ESG = "envoria_esg", // Envoria ESG
  PAYMENT = "payment", // Zahlungsdaten
}

export const Content: FC = () => {
  const { content } = useSelector((state: ILocalState) => state.appStore);
  const { handleLocation } = useAppStoreHelper();
  const dialogs = useDialogs();
  const dialogContext = useDialogContext<IAppStoreContext | undefined>(
    IDialogKeyEnums.APP_STORE,
    undefined
  );

  // set the initial location when the dialog is opened
  useEffect(() => {
    if (!content) return;
    if (!dialogContext) return;
    if (!dialogContext?.location) return;

    handleLocation(dialogContext.location);
    dialogs.setData(IDialogKeyEnums.APP_STORE, {
      ...dialogContext,
      location: undefined,
    });
  }, [content, dialogContext, dialogs, handleLocation]);

  return (
    <>
      {content && (
        <StyledAppStore.Wrapper>
          <AppStoreMenu />
          <AppStoreContent />
        </StyledAppStore.Wrapper>
      )}
    </>
  );
};

interface IUseAppStoreRetVal {
  open: (storeProps: IAppStoreProps) => void;
}

export const useAppStore = (): IUseAppStoreRetVal => {
  const dialogs = useDialogs();
  const { enrichAppStoreContent } = useAppStoreHelper();

  return {
    open: (storeProps): void => {
      enrichAppStoreContent();
      dialogs.open({
        dialogKey: IDialogKeyEnums.APP_STORE,
        closable: storeProps.closable,
        content: <Content />,
        flexible: false,
        data: { ...storeProps },
      });
    },
  };
};
