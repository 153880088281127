import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import { P } from "../../../../components/Typography";
import { Cell, Row, Table } from "../../../../components/Table";

import { convertToTableData } from "./converToTableData";
import { ArrangeValues } from "./ArrangeValues";
import { StyledPreviewTable, StyledTablePreviewCell } from "../styled.file-upload";
import { UploadersContext } from "../../index";

export interface IFields {
  text: string;
  value: string;
}
interface IPreviewTableProps {
  canSelect: boolean;
}
export const PreviewTable: FC<IPreviewTableProps> = ({ canSelect = false }) => {
  const { t } = useTranslation();
  const uploadersContext = useContext(UploadersContext);
  const { tableData } = uploadersContext.sharedState;

  if (!tableData) return null;
  const { rowTitles, table } = convertToTableData(Object.values(tableData));

  return (
    <>
      <P>
        {canSelect
          ? t("setup.upload_form_2.excel.order_columns", { step: 5 })
          : t("setup.upload_form_2.excel.confirm_preview", { step: 3 })}
      </P>
      <StyledPreviewTable>
        <Table width={"100%"}>
          <tbody>
            <Row key="title">
              <Cell key="empty-cell-title" highlight={true}>
                 
              </Cell>
              {rowTitles.map((val, cell) => (
                <Cell key={cell} highlight={true} level={"title"} align={"center"}>
                  {val}
                </Cell>
              ))}
            </Row>
            {canSelect && (
              <Row key="selector">
                <Cell key="empty-cell-selector" highlight={true}>
                   
                </Cell>
                {rowTitles.map((val, cell) => (
                  <Cell key={cell} highlight={cell % 2 === 1} level={"subTitle"}>
                    <ArrangeValues fieldName={val} />
                  </Cell>
                ))}
              </Row>
            )}

            {table?.map((rowData, row) => (
              <Row key={row}>
                <Cell highlight={true} key={`empty-cell-${row}`}>
                  {row + 1}
                </Cell>
                {Object.values(rowData).map((val, cell) => (
                  <Cell key={cell} highlight={cell % 2 === 1}>
                    <StyledTablePreviewCell>
                      <div>{val}</div>
                    </StyledTablePreviewCell>
                  </Cell>
                ))}
              </Row>
            ))}
          </tbody>
        </Table>
      </StyledPreviewTable>
    </>
  );
};
