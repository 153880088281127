import { FC, ReactNode } from "react";
import { StyledH1, StyledH2, StyledH3, StyledH4, StyledH5, StyledH6 } from "./styled.headlines";

export interface IHeadLine {
  children: ReactNode;
  /** Define Size of the headings*/
  size: 1 | 2 | 3 | 4 | 5 | 6;
}

interface IHeadings {
  children: ReactNode;
}

const Headline: FC<IHeadLine> = ({ children, ...props }) => {
  switch (props.size) {
    case 1:
      return <StyledH1 {...props}>{children}</StyledH1>;
    case 2:
      return <StyledH2 {...props}>{children}</StyledH2>;
    case 3:
      return <StyledH3 {...props}>{children}</StyledH3>;
    case 4:
      return <StyledH4 {...props}>{children}</StyledH4>;
    case 5:
      return <StyledH5 {...props}>{children}</StyledH5>;
    case 6:
      return <StyledH6 {...props}>{children}</StyledH6>;
    default:
      return <>{children}</>;
  }
};
/**
 * Headline Components.
 * Use it as standard HTML Headings, only difference **UpperCase**
 * ``<h1>``becomes ``<H1>``
 */
export const H1: FC<IHeadings> = ({ children, ...props }: IHeadings) => {
  return (
    <Headline size={1} {...props}>
      {children}
    </Headline>
  );
};
export const H2: FC<IHeadings> = ({ children, ...props }) => {
  return (
    <Headline size={2} {...props}>
      {children}
    </Headline>
  );
};
export const H3: FC<IHeadings> = ({ children, ...props }) => {
  return (
    <Headline size={3} {...props}>
      {children}
    </Headline>
  );
};
export const H4: FC<IHeadings> = ({ children, ...props }) => {
  return (
    <Headline size={4} {...props}>
      {children}
    </Headline>
  );
};
export const H5: FC<IHeadings> = ({ children, ...props }) => {
  return (
    <Headline size={5} {...props}>
      {children}
    </Headline>
  );
};
export const H6: FC<IHeadings> = ({ children, ...props }) => {
  return (
    <Headline size={6} {...props}>
      {children}
    </Headline>
  );
};
